import React, { useState } from "react";
import { connect } from 'react-redux';
import {
  CardBody,
  Card,
  FormGroup,
  Input,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  CardTitle,
  CardLink,
  Col,
  Row,
  Alert
} from "reactstrap";
import { ImFileEmpty } from "react-icons/im";
import { BsPlusSquare } from "react-icons/bs";
import { addPaymentMethod, removePayment } from "../redux/config";
import { GiReceiveMoney } from "react-icons/gi";
import NotAuth from 'components/NotAuth'

const ReceiveCommission = (props) => {
  const { auth: { affiliator, updateSuccessAuthentication, updateFailedAuthentication } } = props;
  const [modelOpen, setModel] = useState(false)
  const [value, setValue] = useState("")
  const [method, setMethod] = useState("")
  const [success, setSuccess] = useState(true)
  const [danger, setDanger] = useState(true)
  return (
    <>
      <div className="content">
      {(() => {
        if (affiliator === null) {
          return <NotAuth />;
        } else {
          return (
            <>
              <Card className="w-100">
                {(() => {
                    if (updateSuccessAuthentication !== null) {
                      return (
                        <Alert
                          color="info"
                          isOpen={success}
                          toggle={() => setSuccess(false)}>
                          <strong>{updateSuccessAuthentication}!</strong>.
                        </Alert>
                      );
                    }
                  })()}
                  {(() => {
                    if (updateFailedAuthentication !== null) {
                      return (
                        <Alert
                          color="danger"
                          isOpen={danger}
                          toggle={() => setDanger(false)}>
                          <strong>{updateFailedAuthentication}!</strong>.
                        </Alert>
                      );
                    }
                  })()}
                <CardBody>
                  <CardTitle style={{fontSize:"1.5em"}}><GiReceiveMoney size="20px" /> طرق استلام العمولة المناسبة لك.</CardTitle>
                  <CardLink className="mb-3" onClick={(e) => { e.preventDefault(); setModel(true) }} href="/#/"><BsPlusSquare className="ml-1" size="15px" />اضف طريقة دفع</CardLink>
                  <Row>
                    {(() => {
                      if (affiliator?.payMentMethods !== undefined && affiliator?.payMentMethods?.length !== 0) {
                        return affiliator?.payMentMethods?.map(
                          (e, index) => {
                            return (
                              <Col sm="6" key={index}>
                                <div className="font-weight-bold mt-2">
                                  {e.method}
                                </div>
                                <div className="opacity-6">
                                  {e.value}
                                </div>

                                <Button
                                  color="danger" size="sm"
                                  
                                onClick={(a) => {
                                  props.removePayment(e._id);
                                }}
                                >
                                  <span className="font-weight-bold">حذف</span>
                                </Button>
                              </Col>
                            );
                          }
                        );
                      } else {
                        return (
                          <Col sm="12">
                            <CardBody className="px-0"><ImFileEmpty size="15px" /> لم يضاف طريقة دفع بعد.</CardBody>
                          </Col>
                        );
                      }
                    })()}
                  </Row>
                </CardBody>
              </Card>
              <Modal isOpen={modelOpen} toggle={() => setModel(!modelOpen)}>
                <ModalBody>
                  <FormGroup>
                    <Label for="methodHTML">طريقة الدفع</Label>
                    <Input type="text" id="methodHTML" className="text-default" onChange={(e) => { setMethod(e.target.value); }} value={method} placeholder="مثل المحفظة الكترونية" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="valueHTML">الرقم</Label>
                    <Input type="text" id="valueHTML" className="text-default" onChange={(e) => { setValue(e.target.value); }} value={value} placeholder="01" />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="link"
                    className="btn-link-dark"
                    onClick={() => { setModel(!modelOpen) }}
                  >اغلاق</Button>
                  <Button
                    color="success"
                    className="ml-auto"
                    onClick={() => {
                      props.addPaymentMethod({
                        method,
                        value,
                      });
                    }}
                  >حفظ</Button>
                </ModalFooter>
              </Modal>
            </>
          );
        }
      })()}
       
      </div>
    </>
  );
};

export default connect(state => state, { addPaymentMethod, removePayment })(ReceiveCommission);
