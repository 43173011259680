import {
  apiCheckDomainStores,
  apiGetAllStores,
  apiGetAllStoresWithSectionChecker,
  apiGetAllStoresWithBrandChecker,
  apiDeleteAllStores,
  apiGetStoresByID,
  apiGetStoresSectionsByID,
  apiSaveStores,
  apiUpdateStoresByID,
  apiRemoveStoreDomain,
  apiDeleteStoresByID,
  apiGetAllServicesByName,
  apiCreateInvoiceByMethod,
  apiGetStoreInvoicesByStoreId,
  apiPayInvoice,
  apiAddNewStoreBrand_Affiliator
} from '../api/'

import { HandelMyError } from '../store/Redux.Hander.js'

const STORE_SERVICES = 'STORE_SERVICES'

const BRANDWIZARD = 'BRANDWIZARD'

const INVOICE = 'INVOICE'

const STORES_RESETALL = 'GETALLSUCCESSSTORES_RESETALL'

const SAVERESTARTPSTORE = 'SAVELOADINGPSTORE'
const SAVELOADINGPSTORE = 'SAVELOADINGPSTORE'
const SAVESUCCESSSTORE = 'SAVESUCCESSSTORE'
const SAVEFAILEDSTORE = 'SAVEFAILEDSTORE'


const SAVELOADINGSTOREBRAND = "SAVELOADINGSTOREBRAND"
const SAVESUCCESSSTOREBRAND = "SAVESUCCESSSTOREBRAND"
const SAVEFAILEDSTOREBRAND = "SAVEFAILEDSTOREBRAND"


const RESET_CHECKDOMAINLOADINGPSTORE = 'RESET_CHECKDOMAINLOADINGPSTORE'
const CHECKDOMAINLOADINGPSTORE = 'CHECKDOMAINLOADINGPSTORE'
const CHECKDOMAINSUCCESSSTORE = 'CHECKDOMAINSUCCESSSTORE'
const CHECKDOMAINFAILEDSTORE = 'CHECKDOMAINFAILEDSTORE'

const GETALLRESETSTORES = 'GETALLRESETSTORES'
const GETALLLOADINGSTORES = 'GETALLLOADINGSTORES'
const GETALLSUCCESSSTORES = 'GETALLSUCCESSSTORES'
const GETALLFAILEDSTORES = 'GETALLFAILEDSTORES'

const DELETEALLLOADINGSTORES = 'DELETEALLLOADINGSTORES'
const DELETEALLSUCCESSSTORES = 'DELETEALLSUCCESSSTORES'
const DELETEALLFAILEDSTORES = 'DELETEALLFAILEDSTORES'

const RESETSTOREBYID = 'RESETSTOREBYID'
const GETLOADINGSTORES = 'GETLOADINGSTORES'
const GETSUCCESSSTORES = 'GETSUCCESSSTORES'
const GETFAILEDSTORES = 'GETFAILEDSTORES'

const SAVELOADINGSTORES = 'SAVELOADINGSTORES'
const SAVESUCCESSSTORES = 'SAVESUCCESSSTORES'
const SAVEFAILEDSTORES = 'SAVEFAILEDSTORES'

const UPDATELOADINGSTORES = 'UPDATELOADINGSTORES'
const UPDATESUCCESSSTORES = 'UPDATESUCCESSSTORES'
const UPDATEFAILEDSTORES = 'UPDATEFAILEDSTORES'

const DELETELOADINGSTORES = 'DELETELOADINGSTORES'
const DELETESUCCESSSTORES = 'DELETESUCCESSSTORES'
const DELETEFAILEDSTORES = 'DELETEFAILEDSTORES'

export const resetAllStores = () => {
  return dispatch => {
    dispatch({ type: STORES_RESETALL })
  }
}
export const resetStores = () => {
  return dispatch => {
    dispatch({ type: SAVERESTARTPSTORE })
  }
}

export const getAllStores = () => {
  return async dispatch => {
    try {
      dispatch({ type: GETALLLOADINGSTORES })
      const {
        data: { stores }
      } = await apiGetAllStores()
      dispatch({ type: GETALLSUCCESSSTORES, payload: { stores } })
    } catch (e) {
      dispatch({ type: GETALLFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const getAllStoresWithSectionChecker = q => {
  return async dispatch => {
    try {
      dispatch({ type: GETALLRESETSTORES })
      dispatch({ type: GETALLLOADINGSTORES })
      const {
        data: { stores }
      } = await apiGetAllStoresWithSectionChecker(q)
      dispatch({ type: GETALLSUCCESSSTORES, payload: { stores } })
    } catch (e) {
      dispatch({ type: GETALLFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const getAllStoresWithBrandChecker = q => {
  return async dispatch => {
    try {
      dispatch({ type: GETALLRESETSTORES })
      dispatch({ type: GETALLLOADINGSTORES })
      const {
        data: { stores }
      } = await apiGetAllStoresWithBrandChecker(q)
      dispatch({ type: GETALLSUCCESSSTORES, payload: { stores } })
    } catch (e) {
      dispatch({ type: GETALLFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}
export const deleteAllStores = id => {
  return async dispatch => {
    try {
      dispatch({ type: STORES_RESETALL })
      dispatch({ type: DELETEALLLOADINGSTORES })
      const {
        data: { success }
      } = await apiDeleteAllStores()
      dispatch({ type: DELETEALLSUCCESSSTORES, payload: success })
    } catch (e) {
      dispatch({ type: DELETEALLFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const getStoresByID = id => {
  return async dispatch => {
    try {
      dispatch({ type: GETLOADINGSTORES })
      const {
        data: { store }
      } = await apiGetStoresByID(id)
      dispatch({ type: GETSUCCESSSTORES, payload: { store } })
    } catch (e) {
      dispatch({ type: GETFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const resetStoreById = () => {
  return async dispatch => {
    dispatch({ type: RESETSTOREBYID })
  }
}

export const getStoresSectionsByID = id => {
  return async dispatch => {
    try {
      dispatch({ type: RESETSTOREBYID })
      dispatch({ type: GETLOADINGSTORES })
      const {
        data: { store }
      } = await apiGetStoresSectionsByID(id)
      dispatch({ type: GETSUCCESSSTORES, payload: store })
    } catch (e) {
      dispatch({ type: GETFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const resetStoresDomainChecker = () => {
  return async dispatch => {
    dispatch({ type: RESET_CHECKDOMAINLOADINGPSTORE })
  }
}

export const reset_checkDomainStore = sendData => {
  return async dispatch => {
    try {
      const { data } = await apiCheckDomainStores(sendData)
      dispatch({ type: CHECKDOMAINSUCCESSSTORE, payload: data })
    } catch (e) {
      dispatch({ type: CHECKDOMAINFAILEDSTORE, payload: HandelMyError(e) })
    }
  }
}

export const checkDomainStore = sendData => {
  return async dispatch => {
    try {
      dispatch({ type: CHECKDOMAINLOADINGPSTORE })
      const { data } = await apiCheckDomainStores(sendData)
      dispatch({ type: CHECKDOMAINSUCCESSSTORE, payload: data })
    } catch (e) {
      dispatch({ type: CHECKDOMAINFAILEDSTORE, payload: HandelMyError(e) })
    }
  }
}

export const addNewStore = stores => {
  return async dispatch => {
    try {
      dispatch({ type: SAVELOADINGPSTORE })
      const {
        data: { success, url }
      } = await apiSaveStores(stores)
      dispatch({ type: SAVESUCCESSSTORE, payload: { success, url } })
    } catch (e) {
      dispatch({ type: SAVEFAILEDSTORE, payload: HandelMyError(e) })
    }
  }
}

export const addNewStoreBrand_Affiliator = data => {
  return async dispatch => {
    try {
      dispatch({ type: BRANDWIZARD, payload: { model: false } })
      dispatch({ type: SAVELOADINGSTOREBRAND });
      const { data: { success } } = await apiAddNewStoreBrand_Affiliator(data);
      dispatch({ type: SAVESUCCESSSTOREBRAND, payload: success });
    } catch (e) {
      dispatch({ type: SAVEFAILEDSTOREBRAND, payload: HandelMyError(e) });
    }
  };
};

export const saveStores = stores => {
  return async dispatch => {
    try {
      dispatch({ type: SAVELOADINGSTORES })
      const {
        data: { success }
      } = await apiSaveStores(stores)
      dispatch({ type: SAVESUCCESSSTORES, payload: success })
    } catch (e) {
      dispatch({ type: SAVEFAILEDSTORES, payload: HandelMyError(e) })
    }
    try {
      dispatch({ type: GETLOADINGSTORES })
      const {
        data: { stores }
      } = await apiGetStoresByID()
      dispatch({ type: GETSUCCESSSTORES, payload: { stores } })
    } catch (e) {
      dispatch({ type: GETFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const updateStoresByID = (id, data) => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATELOADINGSTORES })
      const {
        data: { success }
      } = await apiUpdateStoresByID(id, data)
      dispatch(getStoresByID(id))
      dispatch({ type: UPDATESUCCESSSTORES, payload: success })
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const removeStoreDomain = id => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATELOADINGSTORES })
      const {
        data: { success }
      } = await apiRemoveStoreDomain(id)
      dispatch(getStoresByID(id))
      dispatch({ type: UPDATESUCCESSSTORES, payload: success })
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const deleteStoresByID = id => {
  return async dispatch => {
    try {
      dispatch({ type: STORES_RESETALL })
      dispatch({ type: DELETELOADINGSTORES })
      const {
        data: { success }
      } = await apiDeleteStoresByID(id)
      dispatch({ type: DELETESUCCESSSTORES, payload: success })
    } catch (e) {
      dispatch({ type: DELETEFAILEDSTORES, payload: HandelMyError(e) })
    }
    try {
      dispatch({ type: GETLOADINGSTORES })
      const {
        data: { stores }
      } = await apiGetStoresByID(id)
      dispatch({ type: GETSUCCESSSTORES, payload: { stores } })
    } catch (e) {
      dispatch({ type: GETFAILEDSTORES, payload: HandelMyError(e) })
    }
  }
}

export const getAllServicesByName = filter => {
  return async dispatch => {
    try {
      dispatch({ type: STORE_SERVICES, payload: { loading: true } })
      const { data } = await apiGetAllServicesByName(filter)
      dispatch({ type: STORE_SERVICES, payload: { loading: false, ...data } })
    } catch (e) {
      dispatch({
        type: STORE_SERVICES,
        payload: { loading: false, failed: HandelMyError(e) }
      })
    }
  }
}

export const createInvoiceByMethod = (filter, query) => {
  return async dispatch => {
    try {
      dispatch({ type: INVOICE, payload: { loading: true, success: null } })
      const { data } = await apiCreateInvoiceByMethod(filter)
      dispatch(getStoreInvoicesByStoreId(query))
      dispatch({ type: INVOICE, payload: { loading: false, ...data } })
    } catch (e) {
      dispatch({
        type: INVOICE,
        payload: { loading: false, failed: HandelMyError(e) }
      })
    }
  }
}

export const getStoreInvoicesByStoreId = filter => {
  return async dispatch => {
    try {
      dispatch({ type: INVOICE, payload: { loading: true } })
      const { data } = await apiGetStoreInvoicesByStoreId(filter)
      dispatch({ type: INVOICE, payload: { loading: false, ...data } })
    } catch (e) {
      dispatch({
        type: INVOICE,
        payload: { loading: false, failed: HandelMyError(e) }
      })
    }
  }
}

export const payInvoice = filter => {
  return async dispatch => {
    try {
      dispatch({
        type: INVOICE,
        payload: { modelIsOpen: true, modelLoading: true }
      })
      const { data } = await apiPayInvoice(filter)
      dispatch({ type: INVOICE, payload: { modelLoading: false, ...data } })
    } catch (e) {
      dispatch({
        type: INVOICE,
        payload: { modelLoading: false, failed: HandelMyError(e) }
      })
    }
  }
}

export const closeInvoiceModel = filter => {
  return async dispatch => {
    dispatch({ type: INVOICE, payload: { modelIsOpen: false } })
  }
}

const INITIAL_STATE = {
  saveLoadingStore: false,
  saveSuccessStore: null,
  saveFailedStore: null,

  saveLoadingStoreBrand: false,
  saveSuccessStoreBrand: null,
  saveFailedStoreBrand: null,

  checkDomainLoadingStore: false,
  checkDomainSuccessStore: null,
  checkDomainFailedStore: null,

  getAllLoadingStores: false,
  getAllSuccessStores: null,
  getAllFailedStores: null,

  deleteAllLoadingStores: false,
  deleteAllSuccessStores: null,
  deleteAllFailedStores: null,

  getLoadingStores: false,
  getSuccessStores: null,
  getFailedStores: null,

  saveLoadingStores: false,
  saveSuccessStores: null,
  saveFailedStores: null,

  updateLoadingStores: false,
  updateSuccessStores: null,
  updateFailedStores: null,

  deleteLoadingStores: false,
  deleteSuccessStores: null,
  deleteFailedStores: null,

  storeServices: {},
  invoices: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE:
      return {
        ...state,
        ...(action.payload !== null && {
          invoices: { ...state?.invoices, ...action?.payload }
        }),
        ...(action.payload === null && { invoices: {} })
      }

    case STORE_SERVICES:
      return {
        ...state,
        ...(action.payload !== null && {
          storeServices: { ...state?.storeServices, ...action?.payload }
        }),
        ...(action.payload === null && { storeServices: {} })
      }

    case STORES_RESETALL:
      return {
        ...state,

        saveLoadingStore: false,
        saveSuccessStore: null,
        saveFailedStore: null,

        checkDomainLoadingStore: false,
        checkDomainSuccessStore: null,
        checkDomainFailedStore: null,

        getAllLoadingStores: false,
        getAllSuccessStores: null,
        getAllFailedStores: null,

        deleteAllLoadingStores: false,
        deleteAllSuccessStores: null,
        deleteAllFailedStores: null,

        getLoadingStores: false,
        getSuccessStores: null,
        getFailedStores: null,

        saveLoadingStores: false,
        saveSuccessStores: null,
        saveFailedStores: null,

        updateLoadingStores: false,
        updateSuccessStores: null,
        updateFailedStores: null,

        deleteLoadingStores: false,
        deleteSuccessStores: null,
        deleteFailedStores: null
      }


    case SAVELOADINGSTOREBRAND: return { ...state, saveLoadingStoreBrand: true, saveSuccessStoreBrand: null, saveFailedStoreBrand: null };
    case SAVESUCCESSSTOREBRAND: return { ...state, saveLoadingStoreBrand: false, saveSuccessStoreBrand: action.payload || "", saveFailedStoreBrand: null };
    case SAVEFAILEDSTOREBRAND: return { ...state, saveLoadingStoreBrand: false, saveSuccessStoreBrand: null, saveFailedStoreBrand: action.payload || "" };

    // Save Store
    case SAVERESTARTPSTORE:
      return {
        ...state,
        saveLoadingStore: true,
        saveSuccessStore: null,
        saveFailedStore: null
      }
    case SAVELOADINGPSTORE:
      return {
        ...state,
        saveLoadingStore: true,
        saveSuccessStore: null,
        saveFailedStore: null
      }
    case SAVESUCCESSSTORE:
      return {
        ...state,
        saveLoadingStore: false,
        saveSuccessStore: action.payload || '',
        saveFailedStore: null
      }
    case SAVEFAILEDSTORE:
      return {
        ...state,
        saveLoadingStore: false,
        saveSuccessStore: null,
        saveFailedStore: action.payload || ''
      }

    // Check Subdomain
    case RESET_CHECKDOMAINLOADINGPSTORE:
      return {
        ...state,
        checkDomainLoadingStore: false,
        checkDomainSuccessStore: null,
        checkDomainFailedStore: null
      }
    case CHECKDOMAINLOADINGPSTORE:
      return {
        ...state,
        checkDomainLoadingStore: true,
        checkDomainSuccessStore: null,
        checkDomainFailedStore: null
      }
    case CHECKDOMAINSUCCESSSTORE:
      return {
        ...state,
        checkDomainLoadingStore: false,
        checkDomainSuccessStore: action.payload || '',
        checkDomainFailedStore: null
      }
    case CHECKDOMAINFAILEDSTORE:
      return {
        ...state,
        checkDomainLoadingStore: false,
        checkDomainSuccessStore: null,
        checkDomainFailedStore: action.payload || ''
      }

    case GETALLRESETSTORES:
      return {
        ...state,
        getAllLoadingStores: false,
        getAllSuccessStores: null,
        getAllFailedStores: null
      }
    case GETALLLOADINGSTORES:
      return {
        ...state,
        getAllLoadingStores: true,
        getAllSuccessStores: null,
        getAllFailedStores: null
      }
    case GETALLSUCCESSSTORES:
      return {
        ...state,
        getAllLoadingStores: false,
        getAllSuccessStores: action.payload || {},
        getAllFailedStores: null
      }
    case GETALLFAILEDSTORES:
      return {
        ...state,
        getAllLoadingStores: false,
        getAllSuccessStores: null,
        getAllFailedStores: action.payload || ''
      }

    case DELETEALLLOADINGSTORES:
      return {
        ...state,
        deleteAllLoadingStores: true,
        deleteAllSuccessStores: null,
        deleteAllFailedStores: null
      }
    case DELETEALLSUCCESSSTORES:
      return {
        ...state,
        deleteAllLoadingStores: false,
        deleteAllSuccessStores: action.payload || '',
        deleteAllFailedStores: null
      }
    case DELETEALLFAILEDSTORES:
      return {
        ...state,
        deleteAllLoadingStores: false,
        deleteAllSuccessStores: null,
        deleteAllFailedStores: action.payload || ''
      }

    case RESETSTOREBYID:
      return {
        ...state,
        getLoadingStores: false,
        getSuccessStores: null,
        getFailedStores: null
      }
    case GETLOADINGSTORES:
      return {
        ...state,
        getLoadingStores: true,
        getSuccessStores: null,
        getFailedStores: null
      }
    case GETSUCCESSSTORES:
      return {
        ...state,
        getLoadingStores: false,
        getSuccessStores: action.payload || {},
        getFailedStores: null
      }
    case GETFAILEDSTORES:
      return {
        ...state,
        getLoadingStores: false,
        getSuccessStores: null,
        getFailedStores: action.payload || ''
      }

    case SAVELOADINGSTORES:
      return {
        ...state,
        saveLoadingStores: true,
        saveSuccessStores: null,
        saveFailedStores: null
      }
    case SAVESUCCESSSTORES:
      return {
        ...state,
        saveLoadingStores: false,
        saveSuccessStores: action.payload || '',
        saveFailedStores: null
      }
    case SAVEFAILEDSTORES:
      return {
        ...state,
        saveLoadingStores: false,
        saveSuccessStores: null,
        saveFailedStores: action.payload || ''
      }

    case UPDATELOADINGSTORES:
      return {
        ...state,
        updateLoadingStores: true,
        updateSuccessStores: null,
        updateFailedStores: null
      }
    case UPDATESUCCESSSTORES:
      return {
        ...state,
        updateLoadingStores: false,
        updateSuccessStores: action.payload || '',
        updateFailedStores: null
      }
    case UPDATEFAILEDSTORES:
      return {
        ...state,
        updateLoadingStores: false,
        updateSuccessStores: null,
        updateFailedStores: action.payload || ''
      }

    case DELETELOADINGSTORES:
      return {
        ...state,
        deleteLoadingStores: true,
        deleteSuccessStores: null,
        deleteFailedStores: null
      }
    case DELETESUCCESSSTORES:
      return {
        ...state,
        deleteLoadingStores: false,
        deleteSuccessStores: action.payload || '',
        deleteFailedStores: null
      }
    case DELETEFAILEDSTORES:
      return {
        ...state,
        deleteLoadingStores: false,
        deleteSuccessStores: null,
        deleteFailedStores: action.payload || ''
      }

    default:
      return state
  }
}
