import axios from 'axios'
import { URLIAFF, URL } from './api.conf'

export const apiCheckDomainStores = data => {
  return axios.post(URL + '/stores/domain/checker', data)
}
export const apiSaveStores = stores => {
  return axios.post(URL + '/system/stores/add', stores)
}

export const apiGetAllStores = () => {
  return axios.get(URL + '/system/stores')
}

export const apiGetAllStoresWithSectionChecker = ({ sectionId }) => {
  return axios.get(URL + '/system/stores?sectionId=' + sectionId)
}

export const apiGetAllStoresWithBrandChecker = ({ brandId }) => {
  return axios.get(URL + '/system/stores/brand/' + brandId)
}
export const apiDeleteAllStores = () => {
  return axios.delete(URLIAFF + '/platform/stores/')
}

export const apiGetStoresByID = id => {
  return axios.get(URL + '/system/store/' + id)
}

export const apiAddNewStoreBrand_Affiliator = data => {
  return axios.post(URL + '/add/store/brand', data)
}

export const apiGetStoresSectionsByID = id => {
  return axios.get(URL + '/system/store/sections/' + id)
}

export const apiUpdateStoresByID = (id, stores) => {
  return axios.put(URL + '/system/store/' + id, stores)
}

export const apiRemoveStoreDomain = id => {
  return axios.delete(URL + '/system/store/domain/' + id)
}

export const apiDeleteStoresByID = id => {
  return axios.delete(URLIAFF + '/platform/stores/' + id)
}

export const apiGetAllServicesByName = ({ id, serviceName }) => {
  return axios.get(URL + '/service/' + serviceName + '/' + id)
}
export const apiCreateInvoiceByMethod = ({ id, packageId, redirecturl }) => {
  return axios.get(URL + '/create/invoice/' + id + '/' + packageId, {
    headers: {
      redirecturl
    }
  })
}
export const apiGetStoreInvoicesByStoreId = ({ id, method, packageId }) => {
  return axios.get(URL + '/get/invoices/store/' + id)
}

export const apiPayInvoice = ({ _id, orderId, method }) => {
  return axios.get(URL + '/pay/invoice/' + _id + '/' + orderId + '/' + method)
}
