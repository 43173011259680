
import axios from "axios";
import { URL } from "./api.conf";

export const apiGetAllSystemAvailable = ({ page, type }) => {
  return axios.get(URL + "/system/available" + `?page=${page}&type=${type}`)
};

export const apiGetAllSystemQuery = ({ page, type }) => {
  return axios.get(URL + "/system/query" + `?page=${page}&type=${type}`)
};

export const apiRegisterInSystem = (systemId, url) => {
  return axios.get(URL + "/system/register?systemId=" + systemId + "&url=" + url);
};

export const apiGenerateToken = (systemId, url) => {
  return axios.get(URL + "/system/generate/token?systemId=" + systemId + "&url=" + url);
};
export const apiDeleteAllSystem = () => {
  return axios.delete(URL + "/system/");
};

export const apiGetSystemByID = (id) => {
  return axios.get(URL + "/system/" + id);
};

export const apiUpdateSystemByID = (id, system) => {
  return axios.put(URL + "/system/" + id, system);
};

export const apiSaveSystem = (system) => {
  return axios.post(URL + "/system", system);
};

export const apiDeleteSystemByID = (id) => {
  return axios.delete(URL + "/system/" + id);
};

