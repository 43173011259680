import React from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  CardBody,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardSubtitle,
  Badge
} from 'reactstrap'
import { FaStore, FaUser } from 'react-icons/fa'
import { getAllStores } from '../../redux/config'
import GetFailedComponent from '../../components/GetFailed'
import StoreOptionsList from '../../components/Store/StoreOptionsList'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { FiSettings, FiCheckCircle } from 'react-icons/fi'
import { CgMenuGridR } from 'react-icons/cg'
import { TiThList } from 'react-icons/ti'
import { HiUserGroup } from 'react-icons/hi'
import { GiRibbonMedal } from 'react-icons/gi'
import { RiUserAddLine } from 'react-icons/ri'
import { FaStoreAlt } from 'react-icons/fa'



import { GiMoneyStack } from 'react-icons/gi'
import { BiHide } from 'react-icons/bi'
import { AiOutlineLink } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import { HiDuplicate } from 'react-icons/hi'
import { FcShop, FcAdvertising } from 'react-icons/fc'
import { RiCloudLine } from 'react-icons/ri'
import { MdWbIncandescent } from 'react-icons/md'
import { BsFilePlus, BsCheckAll } from 'react-icons/bs'
import storeBanner from 'assets/images/storeBanner.svg'
import ContentLoader from 'react-content-loader'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Navbar
} from 'reactstrap'
import { URLIMG, STORE_MAIN_URL } from '../../redux/api/api.conf'
import { Img } from 'react-image'
import NotAuth from 'components/NotAuth'

import { Link } from 'react-router-dom'
import logo from 'assets/images/ator.gif'

const systemLink = store => {
  return `https://${
    store?.hostName !== '' && store?.hostName !== null
      ? store?.hostName
      : store?.subDomain + '.' + STORE_MAIN_URL
  }`
}

class SectionsComponent extends React.Component {
  userType = type => {
    switch (type) {
      case 'owner':
        return 'مالك'
      case 'aff':
        return 'مسوق'
      case 'vendor':
        return 'تاجر'
      default:
        return 'غير معروف'
    }
  }
  
  componentDidMount () {
    if (this.props.affiliator !== null) {
      this.props.getAllStores()
    }
  }
  render () {
    const {
      getAllLoadingStores,
      getAllSuccessStores,
      getAllFailedStores,
      affiliator
    } = this.props

    return (
      <div className='content'>
      {(() => {
        if (affiliator === null) {
          return <NotAuth />;
        } else {
          return (
            <>
              <Card className='bg-dark text-white'>
                <Img
                  className='img-banner-custom'
                  style={{ maxHeight: '300px', objectFit: 'cover' }}
                  alt={`...`}
                  src={[storeBanner, logo]}
                  container={children => {
                    return children
                  }}
                  loader={
                    <SkeletonTheme
                      width='100%'
                      height='100%'
                      color='#344675'
                      highlightColor='#fff'
                    >
                      <Skeleton duration={0.5} width='100%' height={256} />
                    </SkeletonTheme>
                  }
                />

                <CardImgOverlay>
                  <div className='w-100 h-100 position-relative'>
                    <h2 className=' text-default'>
                      <FaStoreAlt size='30px' /> متاجري
                    </h2>
                    <div className="d-flex flex-row position-absolute bottom-0 w-100 justify-content-between justify-content-md-start">
                      <Button
                        tag={Link}
                        to='/stores/add'
                        disabled={affiliator === null}
                        className='animation-on-hover'
                        color='success'
                      >
                        <BsFilePlus size='25px' /> <span className="text-nowrap">أضافة متجر جديد</span>
                      </Button>
                      {(() => {
                        if (affiliator === null) {
                          return (
                            <Button
                              tag='a'
                              href='https://affilih.com/affiliator'
                              target='_blanck'
                              className='animation-on-hover btn-sm-block mr-1'
                              color='info'
                            >
                              <RiUserAddLine size='25px' /> <span className="text-nowrap">انضم كمسوق</span>
                            </Button>
                          );
                        }
                      })()}
                      
                    </div>
                  </div>
                </CardImgOverlay>
              </Card>{' '}
              <div className='divider' />
              {(() => {
                if (getAllLoadingStores) {
                  return (
                    <SkeletonTheme color='#344675' highlightColor='#fff'>
                      <p>
                        <Skeleton height={70} duration={1} />
                      </p>
                      <p>
                        <Skeleton height={70} duration={2} />
                      </p>
                      <p className='mb-0'>
                        <Skeleton height={70} duration={4} />
                      </p>
                    </SkeletonTheme>
                  )
                } else if (getAllFailedStores) {
                  return <GetFailedComponent failed={getAllFailedStores} />
                } else if (getAllSuccessStores !== null) {
                  if (getAllSuccessStores?.stores?.length === 0) {
                    return (
                      <Card tag={ListGroupItem} className=' bg-default'>
                        <CardTitle className='text-white m-0'>
                          <RiCloudLine size='20px' /> لا يوجد متاجر مسجلة
                        </CardTitle>
                      </Card>
                    )
                  } else {
                    return getAllSuccessStores?.stores?.map(store => (
                      <ListGroupItem
                        action
                        className='d-flex bg-white justify-content-between align-items-center py-1 rounded mb-4 mt-1'
                        key={store?._id}
                      >
                        <div
                          className={`text-${store?.storeColor} d-flex align-items-center`}
                        >
                          <div className='d-flex py-2 align-items-center position-relative'>
                            <div className='d-flex flex-column'>
                              <Link
                                tag='h2'
                                className='text-info m-0 d-flex flex-row'
                                to={`stores/${store?._id}`}
                              >
                                {(() => {
                                  if (
                                    store?.storeImg !== '' &&
                                    store?.storeImg !== undefined
                                  ) {
                                    return (
                                      <>
                                        <Img
                                          className='avatar-icon-wrapper avatar-icon-xs ml-2 mr-0'
                                          width='20'
                                          height='20'
                                          style={{ objectFit: 'contain' }}
                                          alt={`${store?.storeName}`}
                                          src={[URLIMG + store?.storeImg]}
                                          container={children => {
                                            return (
                                              <div className='avatar-icon'>
                                                {children}
                                              </div>
                                            )
                                          }}
                                          loader={
                                            <SkeletonTheme
                                              width='100%'
                                              height='100%'
                                              color='#344675'
                                              highlightColor='#fff'
                                            >
                                              <Skeleton
                                                duration={0.5}
                                                width={20}
                                                height={20}
                                              />
                                            </SkeletonTheme>
                                          }
                                        />
                                      </>
                                    )
                                  } else {
                                    return (
                                      <FcShop
                                        className='text-info'
                                        size='20px'
                                        className='ml-1'
                                      />
                                    )
                                  }
                                })()}

                                {store?.storeName}
                              </Link>
                              <CardSubtitle
                                style={{ maxWidth: '180px' }}
                                className='m-0 py-0 text-muted ellipsis-text'
                              >
                                <MdWbIncandescent size='20px' className='ml-1' />
                                {store?.storeDesc}
                              </CardSubtitle>
                            </div>
                          </div>
                        </div>
                        <div>
                          <StoreOptionsList store={store} />
                        </div>
                      </ListGroupItem>
                    ))
                  }
                }
              })()}
              <div className='divider' />
              <div color='dark' className='navbar-dark'>
                <ul className='px-0'>
                  <li className='navbar-text'>
                    نعــتــذر - لأن سرعة إنشاء المتجر الإلكتروني مع Affilih في ثوانٍ
                    قد تقلل من نظرتك لأهميته ، لكن امتلاك متجر بالاسم الذي تختاره
                    والعمل على تكبيره هو مشروع متكامل لك ، دون أن تقلق عقلك من
                    المخاطرة برأس المال في شراء المنتجات وتحمل مثل هذه الأعباء.{' '}
                  </li>
                  <li className='navbar-text'>
                    الأرشفة - بسبب استخدام تقنية ال Server-side Rendering في برمجة
                    المتجر بمجرد الإنشاء وإضافة التجار، يتم أرشفة جميع منتجات التجار
                    بأسم متجرك في محركات البحث لزيادة جذب العملاء وبالتباعية زيادة
                    المبيعات وبالتباعية زيادة عمولاتك دون اي جهد منك.{' '}
                  </li>
                  <li className='navbar-text'>
                    تـطـبيق - من خلال تقنية PWA يمكن للعميل تحويل المتجر إلى تطبيق
                    للموبيل أو سطح المكتب ببساطة بمجرد فتح موقعك، كما سيكون من الممكن
                    إنشاء تطبيق إلكتروني منفصل بأسم المتجر ورفعه على منصات التطبيقات
                    في ثوانٍ قريبًا إن شاء الله.{' '}
                  </li>
                  <li className='navbar-text'>
                    لا تقـلـق - يتم تطبيق الخصم والاوفـربرايس على جميع المنتجات بمرونة
                    كاملة ، ولكن عندما يتجاوز الخصم العمولة ، يصبح الحد الأقصى للخصم
                    معادلاً للعمولة ، وكذالك الحد الاقصي للاوفـربريس يعادل الحد الأقصى
                    الذي حدده التاجر على المنتج.
                  </li>
                </ul>
              </div>

            </>
          );
        }
      })()}
      </div>
    )
  }
}

const mapStateToProps = ({ stores, auth }) => {
  return {
    getAllLoadingStores: stores.getAllLoadingStores,
    getAllSuccessStores: stores.getAllSuccessStores,
    getAllFailedStores: stores.getAllFailedStores,
    affiliator: auth.affiliator
  }
}

const Stores = connect(mapStateToProps, { getAllStores })(SectionsComponent)
export default Stores
