import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getStoresByID, checkDomainStore, updateStoresByID, removeStoreDomain, resetStoresDomainChecker } from "../../redux/config";
import { ClimbingBoxLoader } from "react-spinners";
import GetFailedComponent from "../../components/GetFailed";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import StoreImg from "./StoreImg";
import { Formik } from "formik";
import {
  Col,
  Row,
  Badge,
  Card,
  CardHeader,
  Button,
  CardBody,
  Input,
  Container,
  Table,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  Alert,
  Label,
  CardTitle,
  FormFeedback,
  CardSubtitle,
} from "reactstrap";
import * as Yup from "yup";
import { IoMdDoneAll } from "react-icons/io";
import { GiCheckedShield } from "react-icons/gi";
import StoreBreadcrumb from "../../components/Store/StoreBreadcrumb";
import { BarLoader } from "react-spinners";
import welcome from "assets/images/welcome.svg";
import { FiRefreshCcw } from "react-icons/fi";
import { CgArrowRightR } from "react-icons/cg";
import { FcBrokenLink } from "react-icons/fc";
import { MdDescription, MdInvertColors } from "react-icons/md";
import { FaStoreAlt } from "react-icons/fa";
import ContentLoader from "react-content-loader";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

let domainSchema = Yup.object().shape({
  domain: Yup.string()
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/, "يتم قبول الأحرف الصغيرة فقط ، تأكد من عدم وجود مسافات صغيرة أو أرقام")
    .min(6, "الحد الادني 6 حروف")
    .max(40, "الحد الاقصي 40 حرف")
    .required("اسم المتجر باللغة الإنجليزية ضروري في عملية التسجيل"),
});

const MainStoreDomainComponent = (props) => {
  const {
    stores: {
      getLoadingStores,
      getSuccessStores,
      getFailedStores,
      updateLoadingStores,
      updateSuccessStores,
      updateFailedStores,
      checkDomainLoadingStore,
      checkDomainSuccessStore,
      checkDomainFailedStore,
    },
    getStoresByID,
    checkDomainStore,
    updateStoresByID,
    removeStoreDomain,
    resetStoresDomainChecker,
  } = props;

  const [domain, setDomain] = useState("");
  const [success, setSuccess] = useState("");
  const [danger, setDanger] = useState("");

  useEffect(() => {
    resetStoresDomainChecker();
    (async function () {
      const delayDebounceFn = setTimeout(async () => {
        let data = await domainSchema.validate({ domain });
        if (data?.domain) {
          checkDomainStore({ domain, domainType: "main" });
        }
      }, 800);
      return () => clearTimeout(delayDebounceFn);
    })();
  }, [domain]);

  useEffect(() => {
    getStoresByID(props.match.params.id);
  }, []);

  const preUpdateStoresByID = (id, internaldata) => {
    if (checkDomainSuccessStore !== null) {
      updateStoresByID(id, {
        domain,
        domainType: "main",
      });
    }
  };

  return (
    <div className="content">
      {(() => {
        if (getLoadingStores) {
          return (
            <>
              <div className="d-none d-md-flex w-100">
                <ContentLoader speed={2} width="100%" viewBox="0 0 600 124" backgroundColor="#f3f3f3" foregroundColor="#1E8BF8">
                  <rect x="3" y="0" rx="3" ry="3" width="49%" height="20" />
                  <rect x="300" y="0" rx="3" ry="3" width="49%" height="20" />
                </ContentLoader>
              </div>
              <div className="d-flex d-md-none w-100">
                <ContentLoader speed={2} width="100%" viewBox="0 0 600 124" backgroundColor="#f3f3f3" foregroundColor="#1E8BF8">
                  <rect y="0" rx="5" ry="5" width="100%" height="40px" />
                  <rect y="80" rx="5" ry="5" width="100%" height="40px" />
                </ContentLoader>
              </div>
            </>
          );
        } else if (getFailedStores !== null) {
          return (
            <Container className="text-warning">
              <GetFailedComponent failed={getFailedStores} />
            </Container>
          );
        } else if (getSuccessStores !== null) {
          return (
            <>
              <StoreBreadcrumb store={getSuccessStores?.store} page="inside" />
              <Formik
                enableReinitialize={true}
                initialValues={{
                  domain: domain,
                }}
                validationSchema={domainSchema}
                onSubmit={() => {
                  resetStoresDomainChecker();
                  preUpdateStoresByID(getSuccessStores?.store?._id, {
                    ...(getSuccessStores?.store?.subDomain !== domain && {
                      domain,
                    }),
                    domainType: "main",
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    {(() => {
                      if (updateFailedStores !== null) {
                        return (
                          <Container className="text-warning">
                            <GetFailedComponent failed={updateFailedStores} />
                          </Container>
                        );
                      }
                      if (updateSuccessStores !== null) {
                        return (
                          <Card className="card-box-alt card-border-top border-success mb-3 px-3 py-2 d-flex card">
                            <h5 className="font-size-lg font-weight-bold m-0 text-success">{updateSuccessStores}</h5>
                          </Card>
                        );
                      }
                    })()}
                    <Card>
                      <CardBody>
                        <CardTitle>للتحقق من النطاق الاساسي قم بالدخول الي ال "Manage DNS" في نطاقك ستجد قسم ال "Records"</CardTitle>
                        <CardSubtitle className="mb-2 text-muted">عليك أدخال هذه القيم وأنتظار دقائق، تختلف المدة بأختلاف مزود النطاقات الخاص بك</CardSubtitle>
                        <Table responsive className="table-alternate-spaced text-nowrap mb-0 mt-2 rtl">
                          <thead className="text-lowercase">
                            <tr>
                              <th className="text-right">Type</th>
                              <th className="text-right">Name</th>
                              <th className="text-right">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-right">CNAME</td>
                              <td className="text-right">@</td>
                              <td className="text-right">dns.affilih.com</td>
                            </tr>
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                    {(() => {
                      if (getSuccessStores?.store?.hostName !== "") {
                        return (
                          <Card>
                            <CardBody>
                              <Table responsive className="table-alternate-spaced text-nowrap mb-0 mt-2 rtl">
                                <thead className="text-lowercase">
                                  <tr>
                                    <th className="text-right">النطاق الحالي</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-right">{getSuccessStores?.store?.hostName}</td>
                                    <td className="text-right">
                                      <Button
                                        size="sm"
                                        color="danger"
                                        onClick={() => removeStoreDomain(getSuccessStores?.store?._id)}
                                        className="animation-on-hover"
                                      >
                                        حذف
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        );
                      }
                    })()}

                    <Row className="no-gutters">
                      <Col className="form-group" sm="12">
                        <Label className={`text-info font-weight-bolder text-right`} style={{ direction: "rtl", fontSize: "1.5em" }}>
                          <FcBrokenLink size="20px" /> تعديل نطاق المتجر المدفوع{" "}
                        </Label>
                        <Input
                          onBlur={handleBlur}
                          className="border-info mb-1"
                          style={{ direction: "rtl" }}
                          valid={checkDomainSuccessStore !== null && !(errors.domain && touched.domain)}
                          invalid={(errors.domain && touched.domain) || checkDomainFailedStore !== null}
                          placeholder="مثل elmotaheda"
                          autoComplete="off"
                          type="text"
                          name="domain"
                          onChange={(e) => {
                            setDomain(String(e.target.value).replace(/\s+/g, "").toLowerCase());
                          }}
                          value={values.domain}
                        />
                        {(() => {
                          if (checkDomainLoadingStore) {
                            return <Label className="text-white-50 small">جاري التحقق ...</Label>;
                          }
                        })()}
                        {(() => {
                          if (checkDomainSuccessStore !== null) {
                            return <FormFeedback valid>{checkDomainSuccessStore?.success}</FormFeedback>;
                          }
                        })()}
                        {errors.domain && touched.domain ? (
                          <Badge className="text-warning" color="neutral-warning">
                            {errors.domain}
                          </Badge>
                        ) : null}
                        {checkDomainFailedStore !== null ? (
                          <Badge className="text-warning" color="neutral-warning">
                            {checkDomainFailedStore}
                          </Badge>
                        ) : null}{" "}
                      </Col>
                      <Col className="form-group" sm="12">
                        <Button color="success" className="btn-pill my-1" type="submit" disabled={!(checkDomainSuccessStore !== null)}>
                          <span className="btn-wrapper--icon font-weight-bolder text-nowrap d-flex flex-row align-items-center">
                            <IoMdDoneAll size="15px" />
                            <span className="btn-wrapper--label mr-1">تعديل</span>
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </>
          );
        }
      })()}
    </div>
  );
};

const MainStoreDomain = connect((state) => state, {
  getStoresByID,
  checkDomainStore,
  updateStoresByID,
  removeStoreDomain,
  resetStoresDomainChecker,
})(MainStoreDomainComponent);
export default MainStoreDomain;
