import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getStoresSectionsByID,
  getAllServicesByName,
  createInvoiceByMethod,
  getStoreInvoicesByStoreId,
  payInvoice,
  closeInvoiceModel
} from '../../redux/config'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  CardSubtitle,
  CardText,
  CardLink
} from 'reactstrap'
import Iframe from 'react-iframe'
import { Link } from 'react-router-dom'

import GetFailedComponent from '../../components/GetFailed'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { URL, URLIMG } from '../../redux/api/api.conf'
import logo from 'assets/images/ator.gif'
import StoreBreadcrumb from '../../components/Store/StoreBreadcrumb'

import {
  CardHeader,
  Alert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'
import { Img } from 'react-image'
import { BiBadgeCheck } from 'react-icons/bi'
import { GiPayMoney } from 'react-icons/gi'
import { SiVodafone } from 'react-icons/si'
import { FaFileInvoice } from 'react-icons/fa'
import { GoCreditCard } from 'react-icons/go'
import { BsCheckAll } from 'react-icons/bs'
import valU from 'assets/images/valU.png'
import Moment from 'react-moment'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/ar'
moment.locale('ar')

const StoreSubscriptions = props => {
  const {
    stores: {
      getLoadingStores,
      getSuccessStores,
      getFailedStores,

      storeServices,
      invoices
    },
    getAllServicesByName,
    getStoresSectionsByID,
    createInvoiceByMethod,
    getStoreInvoicesByStoreId,
    payInvoice,
    closeInvoiceModel
  } = props

  useEffect(() => {
    getAllServicesByName({
      id: props.match.params.id,
      serviceName: props.match.params.serviceName
    })
    getStoresSectionsByID(props.match.params.id)
    getStoreInvoicesByStoreId({ id: props.match.params.id })
  }, [props?.match?.params?.serviceName])
  useEffect(() => {
    document.body.classList.toggle('pricing-page')
    return function cleanup () {
      document.body.classList.toggle('pricing-page')
    }
  })
  const [successInvoices, setSuccessInvoices] = useState(false)
  const [failedInvoices, setFailedInvoices] = useState(false)

  useEffect(() => {
    setSuccessInvoices(true)
    setFailedInvoices(false)
  }, [invoices?.success])

  useEffect(() => {
    setFailedInvoices(true)
    setSuccessInvoices(false)
  }, [invoices?.failed])

  const getCardImages = cardName => {
    switch (cardName) {
      case 'primary':
        return require(`assets/img/card-primary.png`).default

      case 'success':
        return require(`assets/img/card-success.png`).default

      case 'warning':
        return require(`assets/img/card-warning.png`).default

      case 'info':
        return require(`assets/img/card-info.png`).default

      case 'danger':
        return require(`assets/img/card-danger.png`).default

      default:
        return require(`assets/img/card-primary.png`).default
    }
  }
  const getCardBg = cardName => {
    switch (cardName) {
      case 'success':
        return 'card-white'
      case 'warning':
        return 'card-white'
      case 'info':
        return 'card-white'
      case 'danger':
        return 'card-white'
      default:
        return ''
    }
  }
  return (
    <>
      <div className='content ltr'>
        {(() => {
          if (getLoadingStores) {
            return (
              <CardBody className='d-flex hover-scale-rounded px-0 w-100 flex-column w-100'>
                <SkeletonTheme
                  color='#344675'
                  highlightColor='#fff'
                  className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                >
                  <div className='d-flex align-items-center flex-row flex-wrap w-100 '>
                    <p className='col-12 col-md-6 pr-0'>
                      <Skeleton height={70} className='w-100' duration={1} />
                    </p>
                    <p className='col-12 col-md-6 pl-0'>
                      <Skeleton height={70} className='w-100' duration={1} />
                    </p>
                  </div>
                </SkeletonTheme>
              </CardBody>
            )
          } else if (
            getFailedStores !== null ||
            storeServices?.failed !== undefined
          ) {
            return (
              <Container className='text-warning'>
                {(() => {
                  if (getFailedStores !== null) {
                    return <GetFailedComponent failed={getFailedStores} />
                  }
                })()}
                {(() => {
                  if (storeServices?.failed !== null) {
                    return <GetFailedComponent failed={storeServices?.failed} />
                  }
                })()}
              </Container>
            )
          } else if (
            getSuccessStores !== null &&
            storeServices?.packages !== null
          ) {
            return (
              <>
                <StoreBreadcrumb store={getSuccessStores} page='inside' />

                <Row className='mb-5'>
                  <Col className='ml-auto mr-auto text-center' md='6'>
                    <h1
                      className='title d-flex align-items-center font-weight-light text-center justify-content-center flex-row flex-wrap'
                      dir='rtl'
                      style={{ fontSize: '1.85em' }}
                    >
                      <span> اختر أفضل خطة لمتجر</span>
                      <span className='text-primary d-flex align-items-center mt-2 mr-md-1 mt-md-0'>
                        {(() => {
                          if (
                            getSuccessStores?.storeImg !== '' &&
                            getSuccessStores?.storeImg !== undefined
                          ) {
                            return (
                              <>
                                <Img
                                  className='ml-2 mr-0'
                                  width='20px'
                                  height='20px'
                                  style={{ objectFit: 'contain' }}
                                  alt={`${getSuccessStores?.storeName}`}
                                  src={[
                                    URLIMG +
                                      getSuccessStores?.storeImg ,
                                    logo
                                  ]}
                                  container={children => children}
                                  loader={
                                    <SkeletonTheme
                                      width='100%'
                                      height='100%'
                                      color='#344675'
                                      highlightColor='#fff'
                                      className='d-flex align-items-center flex-row'
                                    >
                                      <Skeleton
                                        duration={0.5}
                                        width={20}
                                        height={20}
                                      />
                                    </SkeletonTheme>
                                  }
                                />
                              </>
                            )
                          }
                        })()}
                        {getSuccessStores?.storeName}
                      </span>
                    </h1>
                    {/* <h4 className='description'></h4> */}
                  </Col>
                </Row>
              </>
            )
          }
        })()}
        <Row>
          {(() => {
            if (storeServices?.loading === true) {
              return (
                <>
                  <Col lg='3' md='6' sm='12'>
                    <Card className='card-pricing'>
                      <CardBody>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={360}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardBody>
                      <CardFooter className='text-center mb-3 mt-3 rtl'>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={60}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg='3' md='6' sm='12'>
                    <Card className='card-pricing card-raised'>
                      <CardBody>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={460}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardBody>
                      <CardFooter className='text-center mb-3 mt-3 rtl'>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={60}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg='3' md='6' sm='12'>
                    <Card className='card-pricing card-raised'>
                      <CardBody>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={460}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardBody>
                      <CardFooter className='text-center mb-3 mt-3 rtl'>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={60}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg='3' md='6' sm='12'>
                    <Card className='card-pricing'>
                      <CardBody>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={360}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardBody>
                      <CardFooter className='text-center mb-3 mt-3 rtl'>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                        >
                          <Skeleton
                            height={60}
                            className='w-100'
                            duration={1}
                          />
                        </SkeletonTheme>
                      </CardFooter>
                    </Card>
                  </Col>
                </>
              )
            } else {
              return storeServices?.packages?.map(p => (
                <Col lg='3' md='6' sm='12' key={p?._id}>
                  <Card
                    className={`card-pricing card-${p?.cardName} ${
                      p?.isStar ? 'card-raised' : ''
                    } ${getCardBg(p?.cardName)} `}
                  >
                    <CardBody>
                      <CardTitle
                        tag='h1'
                        style={{ fontSize: '4em' }}
                        className='d-flex align-items-center'
                      >
                        {/* <span className="text-default font-weight-light opacity-7" style={{ fontSize: '2em' }}>-</span> */}
                        {p?.topTitle}
                      </CardTitle>
                      <CardImg alt='...' src={getCardImages(p?.cardName)} />
                      <ListGroup>
                        {p?.services?.map(e => (
                          <ListGroupItem
                            key={e?.key}
                            dir='rtl'
                            className='text-success text-right d-flex align-items-center justify-content-between px-2 font-weight-light'
                          >
                            <span>
                              <BiBadgeCheck size='25px' /> {e?.name}
                            </span>
                            {/* <span>{moment.e?.duration}</span> const start = moment().add(-4, 'm') */}
                            <span>
                              <Moment fromNow ago locale='ar'>
                                {moment(new Date()).add(e?.duration, 'months')}
                              </Moment>
                            </span>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                      <div
                        className='card-prices'
                        dir='rtl'
                        title={`${new Intl.NumberFormat('ar-EG').format(
                          p?.price
                        )} جنية مصري `}
                      >
                        <h3 className='text-on-front' dir='rtl'>
                          {new Intl.NumberFormat('ar-EG').format(p?.price)}
                          <span>ج.م</span>
                        </h3>
                        <h5
                          className='text-on-back text-nowrap'
                          style={{
                            fontSize: '8em',
                            lineHeight: '1.5em'
                          }}
                        >
                          {new Intl.NumberFormat('ar-EG').format(p?.price)}
                        </h5>
                        {/* <p className='plan' dir='rtl'>
                              {p?.duration} شهر
                            </p> */}
                      </div>
                    </CardBody>
                    <CardFooter className='text-center mb-3 mt-3 rtl' dir='rtl'>
                      <Button
                        className='animation-on-hover btn-round btn-just-icon'
                        color={p?.cardName}
                        onClick={() => {
                          createInvoiceByMethod(
                            {
                              id: props.match.params.id,
                              packageId: p?._id,
                              redirecturl: window.location.href
                            },
                            {
                              id: props.match.params.id
                            }
                          )
                        }}
                      >
                        الاشتراك
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))
            }
          })()}
        </Row>
        {(() => {
          if (invoices?.success && invoices?.success !== null) {
            return (
              <Alert
                className='rtl'
                color='success'
                isOpen={successInvoices}
                toggle={() => setSuccessInvoices(false)}
              >
                <strong>{invoices?.success}!</strong>.
              </Alert>
            )
          }
        })()}
        {(() => {
          if (invoices?.failed && invoices?.failed !== null) {
            return (
              <Alert
                className='rtl'
                color='danger'
                isOpen={failedInvoices}
                toggle={() => setFailedInvoices(false)}
              >
                <strong>{invoices?.failed}!</strong>.
              </Alert>
            )
          }
        })()}

        <Card className='rtl'>
          <CardHeader>
            <CardTitle
              tag='h4'
              className='d-flex align-items-center font-weight-light text-right'
            >
              <span>
                <FaFileInvoice size='20px' /> فواتير
              </span>
              <span className='text-primary d-flex align-items-center mr-1'>
                {(() => {
                  if (
                    getSuccessStores?.storeImg !== '' &&
                    getSuccessStores?.storeImg !== undefined
                  ) {
                    return (
                      <>
                        <Img
                          className='ml-2 mr-0'
                          width='20px'
                          height='20px'
                          style={{ objectFit: 'contain' }}
                          alt={`${getSuccessStores?.storeName}`}
                          src={[
                            URLIMG + getSuccessStores?.storeImg ,
                            logo
                          ]}
                          container={children => children}
                          loader={
                            <SkeletonTheme
                              width='100%'
                              height='100%'
                              color='#344675'
                              highlightColor='#fff'
                              className='d-flex align-items-center flex-row'
                            >
                              <Skeleton duration={0.5} width={20} height={20} />
                            </SkeletonTheme>
                          }
                        />
                      </>
                    )
                  }
                })()}
                {getSuccessStores?.storeName}.
              </span>
            </CardTitle>
          </CardHeader>
          {(() => {
            if (invoices?.loading === true) {
              return (
                <SkeletonTheme color='#344675' highlightColor='#fff'>
                  <p>
                    <Skeleton height={70} duration={1} />
                  </p>
                  <p>
                    <Skeleton height={70} duration={2} />
                  </p>
                  <p className='mb-0'>
                    <Skeleton height={70} duration={4} />
                  </p>
                </SkeletonTheme>
              )
            } else if (invoices?.invoices?.length === 0) {
              return (
                <CardBody className='d-flex text-white hover-scale-rounded justify-content-center align-items-center p-3 w-100 border-top border-default'>
                  <div className='font-weight-bold text-center'>
                    لايوجد فواتير للمتجر حتي الأن.
                  </div>
                </CardBody>
              )
            } else {
              return (
                <CardBody>
                  <Table>
                    <tbody>
                      {invoices?.invoices?.map(invoice => (
                        <tr key={invoice?._id} className='px-0'>
                          <td className='px-0'>
                            <Card
                              className={`w-100 rtl px-0 pt-3 ${invoices?.selectedPackage === invoice?.packageId || props.match.params?.selectedUrl === invoice?._id
                                  ? 'border-gradient'
                                  : ''
                              }`}
                            >
                              <CardBody>
                                <CardSubtitle className='mb-2 d-flex justify-content-between align-items-center px-1'>
                                  <div className='text-muted'>
                                    <span> -#-</span>
                                    <span> {String(invoice?.orderId)}</span>
                                  </div>
                                  <div>
                                    {(() => {
                                      if (!invoice?.paided) {
                                        return (
                                          <UncontrolledDropdown direction='right'>
                                            <DropdownToggle
                                              // tag={CardLink}
                                              // size='sm'
                                              data-toggle='dropdown'
                                              // style={{ fontSize: '1em' }}
                                              color='success'
                                              // className='btn-simple border-0 animation-on-hover px-0'

                                              className='animation-on-hover'
                                            >
                                              تسديد المبلغ
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              <DropdownItem
                                                onClick={() =>
                                                  payInvoice({
                                                    _id: invoice?._id,
                                                    orderId: invoice?.orderId,
                                                    method: 'cc'
                                                  })
                                                }
                                              >
                                                <GoCreditCard
                                                  size='20px'
                                                  className='ml-1'
                                                />{' '}
                                                بطاقات الائتمان
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  payInvoice({
                                                    _id: invoice?._id,
                                                    orderId: invoice?.orderId,
                                                    method: 'vu'
                                                  })
                                                }
                                              >
                                                <img
                                                  alt='...'
                                                  style={{
                                                    width: '20px'
                                                  }}
                                                  className='ml-2'
                                                  src={
                                                    'https://lh3.googleusercontent.com/-VcTtki8niLE/YZMC0-kC8II/AAAAAAAAB4A/JYGJbWZgzPg7NtglW8S5SynHOtmWQhR0gCLcBGAsYHQ/s16000/valU.png'
                                                  }
                                                />
                                                ValU - للتقسيط
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  payInvoice({
                                                    _id: invoice?._id,
                                                    orderId: invoice?.orderId,
                                                    method: 'ew'
                                                  })
                                                }
                                              >
                                                <SiVodafone
                                                  size='20px'
                                                  className='ml-1'
                                                />{' '}
                                                المحفظة الإلكترونية
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        )
                                      }
                                    })()}{' '}
                                  </div>
                                </CardSubtitle>

                                <CardTitle className='d-flex flex-column'>
                                  {invoice?.services?.map((ser, i) => (
                                    <Badge
                                      key={i}
                                      color='default'
                                      className='ml-1 bg-transparent font-weight-light mr-0 border-default border p-3 opacity-8 rounded-0 d-flex align-items-center flex-row'
                                      style={{ fontSize: '1em' }}
                                    >
                                      {ser.name} <span className='mx-1'>|</span>
                                      <Moment fromNow ago locale='ar'>
                                        {moment(new Date()).add(
                                          ser?.duration,
                                          'months'
                                        )}
                                      </Moment>
                                      {(() => {
                                        if (invoice?.paided) {
                                          return (
                                            <span className='mr-2 border-right border-success pr-2 text-success'>
                                              حتي{' '}
                                              <Moment
                                                locale='ar'
                                                format='MMM YYYY'
                                                ago
                                                add={{
                                                  months: Number(ser?.duration)
                                                }}
                                              >
                                                {new Date(invoice?.paidedDate)}
                                              </Moment>
                                            </span>
                                          )
                                        }
                                      })()}
                                    </Badge>
                                  ))}
                                </CardTitle>
                                <CardSubtitle className='mb-2 text-muted'>
                                  {invoice?.price} ج.م
                                </CardSubtitle>
                                <CardText>
                                  {(() => {
                                    if (invoice?.paided) {
                                      return (
                                        <span className='text-success'>
                                          <BsCheckAll
                                            size='15px'
                                            className='ml-1'
                                          />
                                          تم التسديد{' '}
                                          <Moment
                                            locale='ar'
                                            // format='D MMM YYYY'
                                            fromNow
                                          >
                                            {invoice?.paidedDate}
                                          </Moment>
                                        </span>
                                      )
                                    }
                                  })()}
                                </CardText>
                                {/* <div className='d-flex flex-row align-items-center flex-wrap'>
                                    
                                  </div> */}
                                {/* <CardLink href='/#/'>Card link</CardLink>

                                <CardLink href='/#/'>Another link</CardLink> */}
                              </CardBody>
                            </Card>
                          </td>
                        </tr>

                        // <tr key={invoice?._id}>
                        //   <td
                        //     className='ellipsis-text'
                        //     // style={{ maxWidth: '50px' }}
                        //     title={String(
                        //       invoice?.services?.map((ser, i) => ser.name)
                        //     )}
                        //   >
                        //     {invoice?.services?.map((ser, i) => (
                        //       <Badge
                        //         key={i}
                        //         color='default'
                        //         className='ml-1 bg-transparent font-weight-light mr-0 border-right rounded-0 d-flex align-items-center'
                        //         style={{ fontSize: '1em' }}
                        //       >
                        //         {ser.name} <span className='mx-1'>|</span>
                        //         <Moment fromNow ago locale='ar'>
                        //           {moment(new Date()).add(
                        //             ser?.duration,
                        //             'months'
                        //           )}
                        //         </Moment>
                        //       </Badge>
                        //     ))}
                        //   </td>
                        //   <td>{invoice?.price} ج.م</td>
                        //   <td className='text-right'>
                        //     {(() => {
                        //       if (invoice?.paided) {
                        //         return (
                        //           <Moment locale='ar' format="D MMM YYYY">
                        //             {invoice?.paidedDate}
                        //           </Moment>
                        //         )
                        //       } else {
                        //         return (
                        //           <UncontrolledDropdown direction='down'>
                        //             <DropdownToggle
                        //               size='sm'
                        //               data-toggle='dropdown'
                        //               // style={{ fontSize: '1em' }}
                        //               color='success'
                        //               className='animation-on-hover btn-round btn-just-icon d-flex align-items-center flex-row flex-nowrap'
                        //             >
                        //               تسديد
                        //             </DropdownToggle>
                        //             <DropdownMenu>
                        //               <DropdownItem
                        //                 onClick={() =>
                        //                   payInvoice({
                        //                     _id: invoice?._id,
                        //                     orderId: invoice?.orderId,
                        //                     method: 'cc'
                        //                   })
                        //                 }
                        //               >
                        //                 <GoCreditCard
                        //                   size='20px'
                        //                   className='ml-1'
                        //                 />{' '}
                        //                 بطاقة الائتمان
                        //               </DropdownItem>
                        //               <DropdownItem
                        //                 onClick={() =>
                        //                   payInvoice({
                        //                     _id: invoice?._id,
                        //                     orderId: invoice?.orderId,
                        //                     method: 'vu'
                        //                   })
                        //                 }
                        //               >
                        //                 <Img
                        //                   className='ml-1'
                        //                   style={{
                        //                     maxWidth: '20px'
                        //                     // objectFit: 'cover'
                        //                   }}
                        //                   alt={`valU`}
                        //                   src={[valU]}
                        //                   container={children => {
                        //                     return children
                        //                   }}
                        //                   loader={
                        //                     <SkeletonTheme
                        //                       width='100%'
                        //                       height='100%'
                        //                       color='#344675'
                        //                       highlightColor='#fff'
                        //                     >
                        //                       <Skeleton
                        //                         duration={0.5}
                        //                         width={15}
                        //                         height={15}
                        //                       />
                        //                     </SkeletonTheme>
                        //                   }
                        //                 />
                        //                 ValU - للتقسيط
                        //               </DropdownItem>
                        //               <DropdownItem
                        //                 onClick={() =>
                        //                   payInvoice({
                        //                     _id: invoice?._id,
                        //                     orderId: invoice?.orderId,
                        //                     method: 'ew'
                        //                   })
                        //                 }
                        //               >
                        //                 <SiVodafone
                        //                   size='20px'
                        //                   className='ml-1'
                        //                 />{' '}
                        //                 E-Wallet - مصر فقط
                        //               </DropdownItem>
                        //             </DropdownMenu>
                        //           </UncontrolledDropdown>
                        //         )
                        //       }
                        //     })()}

                        //     {/* <Button
                        //       color='success'
                        //     >
                        //       السداد...
                        //     </Button> */}
                        //   </td>
                        // </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              )
            }
          })()}
        </Card>
        {/* <div className='my-5 border-top border-default pt-4 opacity-7 w-100'>
          <p
            className='title'
            className='d-flex align-items-center font-weight-light text-justify'
            dir='rtl'
          >
            - تهدف منصة Affilih إلى الانتشار وليس الربح ، لذا فإن جميع خدمات
            المنصة متاحة مجانًا دون الحد من عدد المنتجات والطلبات وما إلى ذلك
            بحيث لا يكون هناك عائق أمام المسوقين لإكمال عملهم حتى يصبح المسوق
            قادرًا ماليًا ، وبالتالي ، يعيد الجميل لمنصة Affilih في دعمها للوقوف
            أمام التزاماتها المالية العديدة.{' '}
          </p>

          <p
            className='title'
            className='d-flex align-items-center font-weight-light text-justify'
            dir='rtl'
          >
            - يتم تضمين أكثر من عامل في معادلة التسعير مثل (تكاليف الخوادم
            السحابية ، والتسعير أقل من المنافسين ، والتوظيف ، والتسويق ،
            والضرائب ، والتطوير المستمر).{' '}
          </p>
        </div> */}
      </div>

      <Modal
        isOpen={invoices?.modelIsOpen}
        toggle={closeInvoiceModel}
        modalClassName='modal-long pb-5'
      >
        {(() => {
          if (invoices?.modelLoading) {
            return (
              <ModalBody>
                <SkeletonTheme
                  color='#344675'
                  highlightColor='#fff'
                  className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                >
                  <Skeleton
                    count={3}
                    height={180}
                    className='w-100'
                    duration={1}
                  />
                </SkeletonTheme>
              </ModalBody>
            )
          } else {
            return (
              <ModalBody className='p-0'>
                <Iframe
                  style={{ width: '100%', height: '950px', border: '0' }}
                  url={invoices?.iframeURL}
                  width='100%'
                  height='950px'
                  allowfullscreen
                  frameBorder='0'
                />
              </ModalBody>
            )
          }
        })()}
        <ModalFooter>
          <Button color='danger' onClick={closeInvoiceModel}>
            اغلاق
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default connect(state => state, {
  getStoresSectionsByID,
  getAllServicesByName,
  createInvoiceByMethod,
  getStoreInvoicesByStoreId,
  payInvoice,
  closeInvoiceModel
})(StoreSubscriptions)
