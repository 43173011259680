import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown
} from 'reactstrap'
import { FiSettings, FiCheckCircle } from 'react-icons/fi'
import { CgMenuGridR } from 'react-icons/cg'
import { TiThList } from 'react-icons/ti'
import { HiUserGroup } from 'react-icons/hi'
import { GiMoneyStack } from 'react-icons/gi'
import { BiHide } from 'react-icons/bi'
import { AiOutlineLink } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import { HiDuplicate } from 'react-icons/hi'
import { FcShop, FcAdvertising } from 'react-icons/fc'
import { RiCloudLine } from 'react-icons/ri'
import { MdWbIncandescent } from 'react-icons/md'
import { BsFilePlus, BsCheckAll } from 'react-icons/bs'
import { STORE_MAIN_URL, URLIMG } from '../../redux/api/api.conf'
import { useLocation, Link } from 'react-router-dom'
import { Img } from 'react-image'
import logo from 'assets/images/ator.gif'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const systemLink = store => {
  return `https://${
    store?.hostName !== '' && store?.hostName !== null
      ? store?.hostName
      : store?.subDomain + '.' + STORE_MAIN_URL
  }`
}
const isHere = path => {
  const history = useLocation()
  return history?.pathname === path ? true : false
}
const StoreOptionsList = ({ store, page }) => {
  return (
    <UncontrolledDropdown group direction='right' className='ltr'>
      {(() => {
        if (page === 'inside') {
          return (
            <DropdownToggle
              data-toggle='dropdown'
              color='info'
              className='btn-link m-0 p-0 d-inline-flex'
              color='primary'
            >
              الخيارات <CgMenuGridR size='20px' />
            </DropdownToggle>
          )
        } else {
          return (
            <DropdownToggle
              caret
              data-toggle='dropdown'
              color='info'
              className='m-0'
            >
              <CgMenuGridR size='25px' />
            </DropdownToggle>
          )
        }
      })()}

      <DropdownMenu style={{ left: '0 !important', zIndex: 9999 }}>
        <DropdownItem tag='a' target='_blank' href={systemLink(store)}>
          {/* فتح المتجر <BiLinkExternal size='20px' className='ml-1' /> */}
          <span className='text-primary d-flex align-items-center rtl'>
            {(() => {
              if (store?.storeImg !== '' && store?.storeImg !== undefined) {
                return (
                  <>
                    <Img
                      className='ml-1 mr-0'
                      width='20px'
                      height='20px'
                      style={{ objectFit: 'contain' }}
                      alt={`${store?.storeName}`}
                      src={[URLIMG + store?.storeImg, logo]}
                      container={children => children}
                      loader={
                        <SkeletonTheme
                          width='100%'
                          height='100%'
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row'
                        >
                          <Skeleton duration={0.5} width={20} height={20} />
                        </SkeletonTheme>
                      }
                    />
                  </>
                )
              }
            })()}
            {store?.storeName}
          </span>
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to={`/stores/${store?._id}`}
          disabled={isHere(`/stores/${store?._id}`)}
        >
          نظرة عامة <TiThList size='20px' className='ml-1' />
        </DropdownItem>
        <div className='border-top my-2' />
        <DropdownItem
          tag={Link}
          to={`/stores/update/${store?._id}`}
          disabled={isHere(`/stores/update/${store?._id}`)}
          className='position-relative'
        >
          تعديل البيانات <FiSettings size='20px' className='ml-1' />{' '}
        </DropdownItem>
        <div className='border-top my-2' />
        <DropdownItem header className='font-weight-light text-lowercase rtl'>
          النظاق الخارجي "domain.com"
        </DropdownItem>

        <DropdownItem
          tag={Link}
          to={`/stores/update/domain/${store?._id}`}
          disabled={isHere(`/stores/update/domain/${store?._id}`)}
          className='position-relative'
        >
          تعديل النطاق <AiOutlineLink size='20px' className='ml-1' />{' '}
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to={`/stores/subscriptions/domain/${store?._id}`}
          disabled={isHere(`/stores/subscriptions/domain/${store?._id}`)}
          className='position-relative'
        >
          تفعيل النطاق <BsCheckAll size='20px' className='ml-1' />{' '}
        </DropdownItem>
        <div className='border-top my-2' />
        <DropdownItem header className='font-weight-light text-lowercase rtl'>
          هوية Affilih
        </DropdownItem>

        <DropdownItem
          tag={Link}
          to={`/stores/subscriptions/footer/${store?._id}`}
          disabled={isHere(`/stores/subscriptions/footer/${store?._id}`)}
          className='position-relative'
        >
          اخفاء الهوية <BiHide size='20px' className='ml-1' />{' '}
        </DropdownItem>
        {/* <DropdownItem tag={Link} to={`/stores/update/domain/${store?._id}`}>الخدمات المدفوعة <FcAdvertising size="20px" className="ml-1" /></DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
export default StoreOptionsList
