import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getAllSystemAvailable,
  getAllSystemQuery,
  registerInSystem,
  generateToken,
  resetSystem,
  openBrandWizard,
  runAlert
} from '../redux/config'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Table,
  Row,
  Col,
  Label,
  Badge,
  FormGroup,
  Input,
  Popover,
  PopoverBody,
  UncontrolledPopover,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardImgOverlay,
  CardImg
} from 'reactstrap'
import SystemList from 'assets/images/SystemList.svg'
import doneEasyHand from 'assets/images/doneEasyHand.svg'
import { Link } from 'react-router-dom'
import { TiPlusOutline } from 'react-icons/ti'
import { RiUserAddLine } from 'react-icons/ri'
import { URLIMG, MAIN_URL } from '../redux/api/api.conf'
import Avatar from 'react-avatar'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/ar'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { FcCurrencyExchange } from 'react-icons/fc'
import { GiSandsOfTime } from 'react-icons/gi'
import { CgMenuGridO, CgTimer } from 'react-icons/cg'
import { FiMapPin, FiUsers } from 'react-icons/fi'
import { AiOutlineStar } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import { BsCheckAll, BsBag } from 'react-icons/bs'
import { GiTreeBranch } from 'react-icons/gi'
import { BiBadgeCheck } from 'react-icons/bi'
import { RiPercentLine } from 'react-icons/ri'
import { FaUserSecret } from 'react-icons/fa'
import { Img } from 'react-image'
import NotAuth from 'components/NotAuth'
import queryString from 'query-string'
import { MeteorRainLoading } from 'react-loadingg'
import aniLogo from 'assets/images/ani-logo.svg'
import { BoxesLoader, BookLoader } from 'react-awesome-loaders'
import { GiExitDoor, GiFlyingFlag, GiRibbonMedal } from 'react-icons/gi'
import logo from 'assets/images/ator.gif'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const AvailableSystems = props => {
  const {
    systems: {
      getAllLoadingSystem,
      getAllSuccessSystem,
      getLoading,
      getSuccess,
      getFailed
    },

    stores: {
      saveLoadingStoreBrand,
      saveSuccessStoreBrand,
      saveFailedStoreBrand
    },
    auth,
    openBrandWizard,
    runAlert
  } = props
  let query = queryString.parse(props.location.search)
  let page = query.page !== undefined ? Number(query.page) : 1
  let type = 'available'
  if (auth?.affiliator !== null) {
    type = query.type !== undefined ? String(query.type) : 'joined'
  }
  useEffect(() => {
    const { runAlert } = props

    if (saveLoadingStoreBrand) {
      runAlert({ type: 'loading' })
    }
    if (saveSuccessStoreBrand !== null) {
      runAlert({ type: 'success' })
    }
    if (saveFailedStoreBrand !== null) {
      runAlert({ type: 'failed', msg: saveFailedStoreBrand })
    }
  }, [saveLoadingStoreBrand, saveSuccessStoreBrand, saveFailedStoreBrand])

  const routerToPage = (page, type) => {
    props.history.push({ search: `?page=${page}&type=${type}` })
  }

  useEffect(() => {
    if (auth?.affiliator === null) {
      props.getAllSystemAvailable({ page, type })
    }else{
      props.getAllSystemQuery({ page, type })
    }
  }, [page, type])

  const openInNewTab = url => {
    // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    // if (newWindow) newWindow.opener = null
    window.location.href = url
  }

  useEffect(() => {
    if (getSuccess?.gToken !== undefined && getSuccess?.gToken !== null) {
      props.resetSystem()
    }
    if (getSuccess?.register !== undefined && getSuccess?.register !== null) {
      runAlert({ type: 'success', msg: getSuccess?.register?.success })
      props.resetSystem()
      routerToPage(1, 'joined')
    }
    if (
      getSuccess?.register?.url !== undefined &&
      getSuccess?.register?.url !== null
    ) {
      openInNewTab(getSuccess?.register?.url)
    }
  }, [getSuccess])

  const treeMessage = store => {
    return `ليك ${
      store?.tsPercent === false
        ? store?.tsValue + ' ' + store?.settingsStoreCurrency
        : store?.tsValue + '%'
    } علي عمولة المسوقين المنضمين من خلالك بحد أقصي ${store?.tsOrdersCount} طلب`
  }

  const systemLink = store => {
    return `https://${
      store?.hostName !== '' && store?.hostName !== null
        ? store?.hostName
        : store?.subDomain + '.' + MAIN_URL
    }`
  }
  return (
    <div className='content'>
      <NotAuth affiliator={auth?.affiliator} />
      {(() => {
        if (
          getAllLoadingSystem === true ||
          getLoading?.gToken === true ||
          getLoading?.register === true
        ) {
          return (
            <div className='content d-flex justify-content-center align-items-center flex-column position-relative'>
              <div>
                <BookLoader
                  background={'linear-gradient(135deg, #6066FA, #4645F6)'}
                  desktopSize={'100px'}
                  mobileSize={'80px'}
                  textColor={'#4645F6'}
                  text={null}
                />
              </div>
              {/* <div className=" position-absolute bottom-0 right-0 left-0 d-flex justify-content-center" style={{ marginBottom: "12.5em" }}>
                <img className="mr-md-5 pr-md-5" src={aniLogo} style={{ maxWidth: "180px" }} />
              </div> */}
            </div>
          )
        } else if (getAllSuccessSystem !== null) {
          return (
            <Row>
              {(() => {
                if (auth?.affiliator !== null) {
                  return (
                    <Col sm='12'>
                      <Nav
                        pills
                        className='nav-pills-primary nav-pills-icons mb-4 d-flex justify-content-center justify-content-md-start row'
                      >
                        <NavItem className='col-6 col-md-3 mx-0'>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`d-flex flex-column justify-content-center align-items-center mx-0 ${
                              type === 'joined' ? 'active' : ''
                            }`}
                            onClick={() => routerToPage(1, 'joined')}
                          >
                            <GiFlyingFlag size='20px' />
                            منضم
                          </NavLink>
                        </NavItem>
                        <NavItem className='col-6 col-md-3 mx-0'>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`d-flex flex-column justify-content-center align-items-center mx-0 ${
                              type === 'available' ? 'active' : ''
                            }`}
                            onClick={() => routerToPage(1, 'available')}
                          >
                            <GiExitDoor size='20px' />
                            متاح
                          </NavLink>
                        </NavItem>
                        <NavItem className='col-6 col-md-3 mx-0'>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`d-flex flex-column justify-content-center align-items-center mx-0 ${
                              type === 'affSystem' ? 'active' : ''
                            }`}
                            onClick={() => routerToPage(1, 'affSystem')}
                          >
                            <GiTreeBranch size='20px' />
                            يطبق الاحالة
                          </NavLink>
                        </NavItem>
                        <NavItem className='col-6 col-md-3 mx-0'>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            disabled
                            className={`d-flex flex-column justify-content-center align-items-center mx-0 position-relative ${
                              type === 'cheater' ? 'active' : ''
                            }`}
                            onClick={() => routerToPage(1, 'cheater')}
                          >
                            <FaUserSecret size='20px' />
                            مخادع
                            <Badge
                              className='badge-circle badge-floating border-white position-absolute left-0 top-0 font-weight-light'
                              color='info'
                              size='md'
                            >
                              قريباً
                            </Badge>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>                    
                  );
                }
              })()}
              {(() => {
                if (getAllSuccessSystem?.data?.length === 0) {
                  return (
                    <Card className='card-testimonial card-primary w-100'>
                      <CardBody className='py-3'>
                        <div className='font-weight-bold text-center'>
                          لايوجد تجار في هذا التحديد حتي الأن.
                        </div>
                      </CardBody>
                    </Card>
                  )
                }else if(getAllSuccessSystem?.data?.length !== 0){
                  return getAllSuccessSystem?.data?.map(system => (
                    <Col md='6' key={system._id} className=''>
                      <Card className='card-pricing card-testimonial card-primary'>
                        <CardHeader className='card-header-avatar px-0'>
                          <a target='_blank' href={systemLink(system)}>
                            {(() => {
                              if (system?.settingsStoreImg !== undefined) {
                                return (
                                  <Img
                                    className='img-banner-custom'
                                    style={{ width: '96px', height: '96px' }}
                                    alt={system?.settingsStoreName}
                                    src={[URLIMG + system?.settingsStoreImg]}
                                    container={children => {
                                      return children
                                    }}
                                    unloader={
                                      <Avatar
                                        textSizeRatio={3}
                                        size='100'
                                        name={system?.settingsStoreName}
                                        round={true}
                                      />
                                    }
                                    loader={
                                      <SkeletonTheme
                                        width='100%'
                                        height='100%'
                                        color='#344675'
                                        highlightColor='#fff'
                                      >
                                        <Skeleton
                                          duration={0.5}
                                          height={96}
                                          width={96}
                                          circle={true}
                                        />
                                      </SkeletonTheme>
                                    }
                                  />
                                )
                              } else {
                                return (
                                  <Avatar
                                    textSizeRatio={3}
                                    size='100'
                                    name={system?.settingsStoreName}
                                    round={true}
                                  />
                                )
                              }
                            })()}
                          </a>
                          {(() => {
                            if (system?.isJoined) {
                              return (
                                <Button
                                  tag='a'
                                  // target='_blank'
                                  href={systemLink(system)}
                                  // onClick={() => {
                                  //   props.generateToken(
                                  //     system._id,
                                  //     systemLink(system)
                                  //   )
                                  // }}
                                  className='btn-round btn-just-icon animation-on-hover font-weight-normal'
                                  style={{ marginTop: '-1em' }}
                                  size='sm'
                                  color='success'
                                >
                                  <BiLinkExternal /> فتح
                                </Button>
                              )
                            } else if (system?.isHiddenOnAffPlatform) {
                              return (
                                <Button
                                  className='btn-round btn-just-icon animation-on-hover font-weight-normal'
                                  style={{ marginTop: '-1em' }}
                                  size='sm'
                                  color='gray'
                                  disabled
                                >مُكْتَفى
                                </Button>
                              )

                            } else if (system?.isPending) {
                              return (
                                <Button
                                  className='btn-round btn-just-icon animation-on-hover font-weight-normal'
                                  style={{ marginTop: '-1em' }}
                                  size='sm'
                                  color='warning'
                                >
                                  <CgTimer />
                                  ننتظر الموافة
                                </Button>
                              )
                            } else if (auth?.affiliator !== null) {
                              return (
                                <Button
                                  className='btn-round btn-just-icon animation-on-hover text-nowrap mx-auto font-weight-normal'
                                  style={{ marginTop: '-1em' }}
                                  size='sm'
                                  color='success'
                                  onClick={() => {
                                    props.registerInSystem(
                                      system._id,
                                      {
                                        page,
                                        type
                                      },
                                      systemLink(system)
                                    )
                                  }}
                                >
                                  <GiExitDoor /> كن مسوق معنا
                                </Button>
                              )
                            } else {
                              return (
                                <Button
                                  className='btn-round btn-just-icon animation-on-hover text-nowrap mx-auto font-weight-normal'
                                  style={{ marginTop: '-1em' }}
                                  size='sm'
                                  color='info'
                                  tag='a'
                                  href='https://affilih.com/affiliator'
                                  target='_blanck'
                                >
                                  <RiUserAddLine /> انضم كمسوق
                                </Button>
                              )
                            }
                          })()}
                        </CardHeader>
                        <CardBody className='pt-0 px-3'>
                          <CardTitle
                            style={{ fontSize: '2em' }}
                            className='text-white'
                          >
                            {system?.settingsStoreName}
                          </CardTitle>
                          <UncontrolledPopover
                            trigger='hover'
                            placement='top'
                            target={'ID' + system._id}
                          >
                            <PopoverBody>
                              {system?.settingsStoreDescription}
                            </PopoverBody>
                          </UncontrolledPopover>
                          <p
                            id={'ID' + system._id}
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                            className='text-default'
                          >
                            {system?.settingsStoreDescription}
                          </p>
                          <div
                            className='d-flex justify-content-evenly flex-wrap flex-row'
                            style={{ justifyContent: 'space-evenly' }}
                          >
                            <Col
                              sm='12'
                              className='card-prices pt-0 mb-3 text-success'
                            >
                              <h5
                                className='text-on-back opacity-4 mt-0 text-success'
                                style={{ fontSize: '6.5em' }}
                              >
                                {system?.usersCount}
                              </h5>
                              <p className='plan text-on-front text-success font-weight-normal'>
                                <FiUsers size='15px' /> مسوق نشط
                              </p>
                            </Col>
                          </div>
                        </CardBody>
                        <CardFooter className='d-flex flex-wrap flex-row align-items-center pb-0 overflow-hidden w-100 my-2 flex-column text-right'>
                          {/* <Col className="w-100 py-2 border-top border-default d-flex px-0">
                            <Col sm="12" className=" d-flex w-100 px-0 text-success">
                              <Col className="plan text-on-front"><FiUsers size="15px" /> مسوق نشط</Col>
                              <Col className="plan text-on-front">{system?.usersCount}</Col>
                            </Col>
                          </Col> */}
                          <Col className='w-100 py-2 border-top border-default d-flex px-0 flex-column'>
                            <Col sm='12' className=' d-flex w-100 px-0 flex-row'>
                              <Col className='plan text-on-front text-white'>
                                <BiBadgeCheck size='15px' /> قسم مؤهل
                              </Col>
                              <Col className='plan text-on-front text-white d-flex justify-content-between'>
                                <span>{system?.sectionsCount}</span>
                                <span>
                                  <Button
                                    className='btn-link m-0 p-0'
                                    size='sm'
                                    color='primary'
                                    tag={Link}
                                    to={{
                                      pathname: `/system/sections/${system._id}`
                                    }}
                                    target='_blank'
                                  >
                                    تصفح
                                  </Button>
                                </span>
                              </Col>
                            </Col>
                          </Col>
                          <Col
                            sm='12'
                            className=' d-flex w-100 px-0 pb-2 flex-row mt-2 opacity-4'
                          >
                            <Col className='plan text-on-front text-white'>
                              <CgMenuGridO size='15px' /> كل الأقسام
                            </Col>
                            <Col className='plan text-on-front text-white d-flex justify-content-between'>
                              <span>{system?.allSectionsCount}</span>
                            </Col>
                          </Col>
                          <Col className='w-100 py-2 border-top border-default d-flex px-0'>
                            <Col sm='12' className=' d-flex w-100 px-0'>
                              <Col className='plan text-on-front text-white'>
                                <BsBag size='15px' /> منتج
                              </Col>
                              <Col className='plan text-on-front text-white'>
                                {system?.productsCount}
                              </Col>
                            </Col>
                          </Col>
                          <Col className='w-100 py-2 border-top border-default d-flex px-0'>
                            <Col sm='12' className=' d-flex w-100 px-0'>
                              <Col className='plan text-on-front text-white'>
                                <FiMapPin size='15px' /> يدعم مدينة شحن
                              </Col>
                              <Col className='plan text-on-front text-white'>
                                {system?.cityCount}
                              </Col>
                            </Col>
                          </Col>
                          {/* <Col className="w-100 py-2 border-top border-default d-flex px-0">
                            <Col sm="12" className=" d-flex w-100 px-0">
                              <Col className="plan text-on-front text-white"><AiOutlineStar size="15px" /> التقيم</Col>
                              <Col className="plan text-on-front text-white">{system?.cityCount}</Col>
                            </Col>
                          </Col> */}
                          <Col className='w-100 py-2 border-top border-default d-flex px-0'>
                            <Col sm='12' className=' d-flex w-100 px-0'>
                              <Col className='plan text-on-front text-white'>
                                <FcCurrencyExchange size='15px' /> العملة
                              </Col>
                              <Col className='plan text-on-front text-white'>
                                {system?.settingsStoreCurrency}
                              </Col>
                            </Col>
                          </Col>

                          {(() => {
                            if (system?.treeSystem) {
                              return (
                                <>
                                  <Col className='w-100 py-2 border-top border-default d-flex px-0'>
                                    <Col sm='12' className=' d-flex w-100  px-0'>
                                      <Col className='plan text-on-front text-white'>
                                        <GiTreeBranch
                                          className='text-success'
                                          size='15px'
                                        />{' '}
                                        نظام الاحالة مفعل
                                      </Col>
                                      <Col className='plan text-on-front text-white'>
                                        {treeMessage(system)}
                                      </Col>
                                    </Col>
                                  </Col>
                                </>
                              )
                            }
                          })()}
                          {/* <Col className='w-100 py-2 border-top border-default d-flex px-0 flex-column opacity-4'>
                            <Col sm='12' className=' d-flex w-100 px-0 flex-row'>
                              <Col className='plan text-on-front text-white font-size-tiny'>
                                <RiPercentLine size='15px' /> نسبة تسليم المنتجات
                              </Col>
                              <Col className='plan text-on-front text-white d-flex justify-content-between'>
                                <Badge
                                  className='badge-circle badge-floating border-white font-weight-light'
                                  color='info'
                                  size='md'
                                >
                                  قريباً
                                </Badge>
                              </Col>
                            </Col>
                            <Col
                              sm='12'
                              className=' d-flex w-100 px-0 flex-row mt-2'
                            >
                              <Col className='plan text-on-front text-white font-size-tiny'>
                                <GiSandsOfTime size='15px' /> مدة تأخير العمولة
                                المستحقة
                              </Col>
                              <Col className='plan text-on-front text-white d-flex justify-content-between'>
                                <span>
                                  <Badge
                                    className='badge-circle badge-floating border-white font-weight-light'
                                    color='info'
                                    size='md'
                                  >
                                    قريباً
                                  </Badge>
                                </span>
                              </Col>
                            </Col>
                          </Col> */}
                        </CardFooter>
                        {(() => {
                          if (system?.isJoined) {
                            return (
                              <Button
                                color='primary'
                                className='animation-on-hover w-100 mb-0 rounded-top-0 border-top-0 d-flex flex-row align-items-center justify-content-center px-2'
                                size='sm'
                                onClick={() =>
                                  openBrandWizard({
                                    brandId: system?._id,
                                    currentStep: 'selectStore'
                                  })
                                }
                              >
                                <span style={{ fontSize: '1.3em' }}>افلتة</span>
                                <small
                                  style={{ fontSize: '1em' }}
                                  className='font-weight-light mr-1'
                                >
                                  ~ ادمج منتجات التاجر بدوسة
                                </small>
                                <img
                                  src={doneEasyHand}
                                  style={{ maxHeight: '28px' }}
                                />
                              </Button>
                            )
                          }
                        })()}
                      </Card>
                    </Col>
                  ))
                }
              })()}
              {(() => {
                if (getAllSuccessSystem?.count !== 0) {
                  return (
                    <Col sm='12'>
                      <nav
                        aria-label='Page navigation example'
                        className='rtl my-2'
                      >
                        <Pagination className='d-flex align-items-center'>
                          {(() => {
                            if (getAllSuccessSystem?.currentPage !== 1) {
                              return (
                                <PaginationItem className='ml-2'>
                                  <PaginationLink
                                    className='d-flex align-items-center py-1 text-default'
                                    onClick={() =>
                                      routerToPage(
                                        getAllSuccessSystem?.currentPage - 1,
                                        type
                                      )
                                    }
                                  >
                                    السابق{' '}
                                  </PaginationLink>
                                </PaginationItem>
                              )
                            }
                          })()}

                          <FormGroup className='ltr d-flex align-items-center my-0 py-0'>
                            <Input
                              type='select'
                              onChange={e => {
                                routerToPage(Number(e.target.value), type)
                              }}
                              defaultValue={getAllSuccessSystem?.currentPage}
                              name='select'
                              id='exampleSelect1'
                              className='py-0 px-3'
                            >
                              {[...Array(getAllSuccessSystem?.totalPages)].map(
                                (x, i) => (
                                  <option key={i} className='text-default'>
                                    {i + 1}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                          {(() => {
                            if (
                              getAllSuccessSystem?.currentPage !==
                              getAllSuccessSystem?.totalPages
                            ) {
                              return (
                                <PaginationItem className='mr-2'>
                                  <PaginationLink
                                    className='d-flex align-items-center py-1 text-default'
                                    onClick={() =>
                                      routerToPage(
                                        getAllSuccessSystem?.currentPage + 1,
                                        type
                                      )
                                    }
                                  >
                                    التالي
                                  </PaginationLink>
                                </PaginationItem>
                              )
                            }
                          })()}
                        </Pagination>
                      </nav>
                    </Col>
                  )
                }
              })()}
            </Row>
          )
        }
      })()}
    </div>
  )
}

export default connect(state => state, {
  getAllSystemAvailable,
  getAllSystemQuery,
  registerInSystem,
  generateToken,
  resetSystem,

  openBrandWizard,
  runAlert
})(AvailableSystems)
