// Api
// Main Url
export const MAIN_URL = 'affilih.com';
export const STORE_MAIN_URL = 'store.affilih.com';
export const FULL_MAIN_URL = 'https://affilih.com';
export const DOMAIN = window.location.origin;
// export const URL = "http://localhost:4500/v1/api/affiliator";
export const URL = !( window.location.origin.includes("localhost") || window.location.origin.includes("ngrok.io") ) ? window.location.origin + "/v1/api/affiliator" : "http://localhost:4500/v1/api/affiliator";
// export const URLIMG = !(window.location.origin.includes("localhost") || window.location.origin.includes("local.host")) ? window.location.origin + "/v1/api/image/" : "http://localhost:4500/v1/api/image/";
export const URLIMG = "https://storage.googleapis.com/affilih_storage/images/";
export const URLIAFF = !(window.location.origin.includes("localhost") || window.location.origin.includes("ngrok.io")) ? window.location.origin + "/v1/api" : "http://localhost:4500/v1/api";
export const URLPLATFORM = URL + '/internal/platfrom'
// export const ENDPOINT = window.location.origin !== "http://localhost:3000" ? window.location.origin + "/socket" : "http://localhost:4500/socket";
