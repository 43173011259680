import React, { useState, useEffect } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
import { AiOutlineCheck } from 'react-icons/ai'
import notification from 'assets/images/notification.svg'

// reactstrap components
import {
  Button,
  Input,
  NavbarBrand,
  Navbar,
  Container,
  Modal,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { connect } from 'react-redux'
import { Img } from 'react-image'
import { URLIMG, MAIN_URL } from '../redux/api/api.conf'
import { FcShop } from 'react-icons/fc'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { FiBellOff } from 'react-icons/fi'

const systemLink = store => {
  return `https://${
    store?.hostName !== '' && store?.hostName !== null
      ? store?.hostName
      : store?.subDomain + '.' + MAIN_URL
  }`
}

const RTLNavbar = props => {
  const [modalSearch, setModalSearch] = React.useState(false)
  const [notificationModelStatus, setNotificationModelStatus] = React.useState(
    false
  )
  const [notificationStatus, setNotificationStatus] = React.useState(false)
  const [color, setColor] = React.useState('navbar-transparent')
  React.useEffect(() => {
    window.addEventListener('resize', updateColor)
    return function cleanup () {
      window.removeEventListener('resize', updateColor)
    }
  })
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    setColor('navbar-transparent')
  }
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch)
  }
  navigator.permissions
    .query({ name: 'notifications' })
    .then(function (notificationPerm) {
      if (notificationPerm.state !== 'granted') {
        setNotificationStatus(true)
      }
    })
  const {
    auth: { affiliator }
  } = props


  return (
    <>
      <Navbar
        className={classNames('navbar-absolute', {
          [color]: !document.body?.classList.contains('full-screen-map')
        })}
        expand='lg'
        style={{ top: 0 }}
      >
        <Container fluid className="px-0">
          <div className='navbar-wrapper'>
            <div className='navbar-minimize d-inline'>
              <Button
                className='minimize-sidebar btn-just-icon'
                color='link'
                id='tooltip209599'
                onClick={props.handleMiniClick}
              >
                <i className='tim-icons icon-align-center visible-on-sidebar-regular' />
                <i className='tim-icons icon-bullet-list-67 visible-on-sidebar-mini' />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target='tooltip209599'
                placement='right'
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames('navbar-toggle d-inline', {
                toggled: props.sidebarOpened
              })}
            >
              <button
                className='navbar-toggler pr-0 '
                type='button'
                onClick={props.toggleSidebar}
              >
                <span className='navbar-toggler-bar bar1' />
                <span className='navbar-toggler-bar bar2' />
                <span className='navbar-toggler-bar bar3' />
              </button>
            </div>
            <NavbarBrand href='#pablo' onClick={e => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <div className=' d-flex align-items-center'>
            {(() => {
              if (notificationStatus) {
                return (
                  // On Off
                  <Button
                    size='sm'
                    color='link'
                    className='btn-pill btn-icon rounded-sm btn-transition-none d-30 p-0 badge-wrapper'
                    onClick={e => {
                      setNotificationModelStatus(true)
                    }}
                  >
                    <Badge
                      color='secondary'
                      className='badge-position badge-position--top-left rounded-circle h-100 right-0 left-0 bottom-0 top-0 w-100'
                    ></Badge>
                    <span className='btn-wrapper--icon d-flex align-content-center text-danger'>
                      <FiBellOff size='20' />
                    </span>
                  </Button>
                )
              }
            })()}
            <span className='photo'>
              <img alt={affiliator?.name} src={affiliator?.picture} />
            </span>
          </div>
        </Container>
      </Navbar>
      <Modal
        modalClassName='modal-search'
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <div className='modal-header'>
          <Input id='inlineFormInputGroup' placeholder='SEARCH' type='text' />
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={toggleModalSearch}
          >
            <i className='tim-icons icon-simple-remove' />
          </button>
        </div>
      </Modal>

      <Modal
        zIndex={2000}
        centered
        isOpen={notificationModelStatus}
        toggle={() => {
          setNotificationModelStatus(!notificationModelStatus)
        }}
        modalClassName=' modal-danger'
      >
        <ModalHeader
          tag='h3'
          className=' font-weight-bold'
          style={{ fontSize: '1.5em' }}
        >
          ليه تفعيل اشعارات المتصفح مهم!
        </ModalHeader>
        <ModalBody>
          <img
            alt='affilih'
            className='d-flex mx-1'
            style={{ width: '100%' }}
            src={notification}
          />
          <div className='py-4 d-flex flex-column align-items-start'>
            <div className='text-white d-flex align-items-center mb-3 justify-content-center justify-content-lg-start'>
              <div className='d-30 rounded-pill btn-icon bg-white-10 ml-2'>
                <AiOutlineCheck size='20px' />
              </div>
              <span className='pt-1 text-white'>
                إشعارات فورية بطلبات الشراء
              </span>
            </div>
            <div className='text-white d-flex align-items-center mb-3 justify-content-center justify-content-lg-start'>
              <div className='d-30 rounded-pill btn-icon bg-white-10 ml-2'>
                <AiOutlineCheck size='20px' />
              </div>
              <span className='pt-1 text-white'>
                إشعارات فورية بالتعليفات علي طلبات الشراء
              </span>
            </div>
            <div className='text-white d-flex align-items-center mb-3 justify-content-center justify-content-lg-start'>
              <div className='d-30 rounded-pill btn-icon bg-white-10 ml-2'>
                <AiOutlineCheck size='20px' />
              </div>
              <span className='pt-1 text-white'>
                إشعارات فورية بطلبات التسديد
              </span>
            </div>
            <div className='text-white d-flex align-items-center mb-3 justify-content-center justify-content-lg-start'>
              <div className='d-30 rounded-pill btn-icon bg-white-10 ml-2'>
                <AiOutlineCheck size='20px' />
              </div>
              <span className='pt-1 text-white'>
                إشعارات فورية بالتعليفات علي طلبات التسديد
              </span>
            </div>
            <div className='text-white d-flex align-items-center mb-3 justify-content-center justify-content-lg-start'>
              <div className='d-30 rounded-pill btn-icon bg-white-10 ml-2'>
                <AiOutlineCheck size='20px' />
              </div>
              <span className='pt-1 text-white'>إشعارات فورية علي حسابك</span>
            </div>
          </div>{' '}
        </ModalBody>
        <ModalFooter className='ltr'>
          <Button
            color='link'
            className='text-white'
            onClick={() => {
              setNotificationModelStatus(!notificationModelStatus)
            }}
          >
            فهمت!
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default connect(state => state, {})(RTLNavbar)
