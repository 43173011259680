import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from 'perfect-scrollbar'
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import CreateSectionWizard from "components/CreateSectionWizard.js";
import CreateBrandWizard from "components/CreateBrandWizard.js";

// core components
import RTLNavbar from "components/RTLNavbar.js";
import Footer from "components/Footer.js";
import Sidebar from "components/Sidebar.js";
import FixedPlugin from "components/FixedPlugin.js";
import Profile from "pages/Profile";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import Dashboard from "pages/Dashboard.js";

import routes from "routes.js";

import logo from "assets/images/logo_white.svg";
import aniLogo from "assets/images/ani-logo.svg";
import { BoxesLoader, BookLoader } from "react-awesome-loaders";
import HeaderAlert from "components/HeaderAlert";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { updateProfile } from "redux/config";

var ps;
const getActiveRoute = (routes) => {
  let activeRoute = "منصة افليه للمسوقين";
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRoute(routes[i].views);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else {
      if (window.location.pathname === routes[i].path) {
        return routes[i].rtlName;
      }
    }
  }
  return activeRoute;
};
const firebaseConfig = {
  apiKey: "AIzaSyA4u1IoZgWABbeehbO8NDaB4KgrmXhNnaU",
  authDomain: "affilih.firebaseapp.com",
  projectId: "affilih",
  storageBucket: "affilih.appspot.com",
  messagingSenderId: "31861581124",
  appId: "1:31861581124:web:de0b0d739790fc9709a311",
  measurementId: "G-X7XQ0B2DBM",
};

const Index = (props) => {
  const {
    updateProfile,
    auth: { affiliator },
  } = props;

  const [activeColor, setActiveColor] = React.useState("blue");
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return (
        <Route
          exact
          // render={(props) => prop.component(props)}
          path={prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   if (mainPanelRef.current) {
  //     mainPanelRef.current.scrollTop = 0;
  //   }
  // }, [location]);
  // React.useEffect(() => {
  //   let innerMainPanelRef = mainPanelRef
  //   if (navigator.platform.indexOf('Win') > -1) {
  //     document?.documentElement?.classList?.add('perfect-scrollbar-on')
  //     document?.documentElement?.classList?.remove('perfect-scrollbar-off')
  //     // ps = new PerfectScrollbar(mainPanelRef.current)
  //     // mainPanelRef.current &&
  //     //   mainPanelRef.current.addEventListener('ps-scroll-y', showNavbarButton)
  //     // let tables = document.querySelectorAll('.table-responsive')
  //     // for (let i = 0; i < tables.length; i++) {
  //     //   ps = new PerfectScrollbar(tables[i])
  //     // }
  //   }
  //   window.addEventListener('scroll', showNavbarButton)
  //   // on this page, we need on the body tag the classes .rtl and .menu-on-right
  //   // an on the html tag the classe .rtl-active
  //   document?.body?.classList?.add('rtl', 'menu-on-right')
  //   document?.documentElement?.classList?.add('rtl-active')
  //   return function cleanup () {
  //     if (navigator.platform.indexOf('Win') > -1) {
  //       ps.destroy()
  //       document?.documentElement?.classList?.add('perfect-scrollbar-off')
  //       document?.documentElement?.classList?.remove('perfect-scrollbar-on')
  //       innerMainPanelRef.current &&
  //         innerMainPanelRef.current.removeEventListener(
  //           'ps-scroll-y',
  //           showNavbarButton
  //         )
  //     }
  //     window.removeEventListener('scroll', showNavbarButton)
  //     // when we exit this page, we need to delete the classes .rtl and .menu-on-right
  //     // from the body tag and the class rtl-active from the html tag
  //     document?.body?.classList?.remove('rtl', 'menu-on-right')
  //     document?.documentElement?.classList?.remove('rtl-active')
  //   }
  // })
  // const showNavbarButton = () => {
  //   if (document.documentElement.scrollTop > 50 || document.scrollingElement.scrollTop > 50 || (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)) {
  //     setOpacity(1);
  //   } else if (
  //     document.documentElement.scrollTop <= 50 ||
  //     document.scrollingElement.scrollTop <= 50 ||
  //     (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
  //   ) {
  //     setOpacity(0);
  //   }
  // };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleMiniClick = () => {
    let notifyMessage = "تصغبر الشريط الجانبي ";
    if (document?.body?.classList?.contains("sidebar-mini")) {
      setSidebarMini(false);
      notifyMessage += "غير مفعل";
    } else {
      setSidebarMini(true);
      notifyMessage += "مفعل";
    }
    let options = {};
    options = {
      place: "tc",
      message: notifyMessage,
      type: "primary",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    document?.body?.classList?.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document?.documentElement?.classList?.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document?.documentElement?.classList?.remove("nav-open");
  };

  isSupported().then((status) => {
    if (status) {
      initializeApp(firebaseConfig);

      onMessage(getMessaging(), (payload) => {});
    }
  });

  useEffect(() => {
    let windowQuery = queryString.parse(window.location.search);
    if ("internalToken" in windowQuery) {
      windowQuery.internalToken = undefined;
      delete windowQuery.internalToken;
      props.history.push({ query: windowQuery }, undefined, { shallow: true });
    }
  }, [props]);

  React.useEffect(() => {
    isSupported().then((status) => {
      if (affiliator !== null && status) {
        getToken(getMessaging(), {
          vapidKey: "BLTgJIqRJTDgBOyJMzhot1vVfNkWmrVtd6EU8XurYc3NLlGX7R0m2TmKCdxt_SYy6EYrUe-CuPKTjeJhYfEw9_c",
        })
          .then((currentToken) => {
            if (currentToken) {
              if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                // Mobile
                if (affiliator?.firebaseToken_Mobile !== currentToken) {
                  updateProfile({ firebaseToken_Mobile: currentToken });
                }
              } else {
                // Desctop
                if (affiliator?.firebaseToken !== currentToken) {
                  updateProfile({ firebaseToken: currentToken });
                }
              }
            } else {
              // Show permission request UI
              console.log("No registration token available. Request permission to generate one.");
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      }
    });
  }, [affiliator]);
  return (
    <div className="wrapper">
      <HeaderAlert />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button className="minimize-sidebar btn btn-link btn-just-icon" onClick={handleMiniClick}>
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor={activeColor}
        logo={{
          outterLink: "https://affilih.com/",
          text: "منصة Affilih",
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" data={activeColor}>
        <RTLNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
        {(() => {
          if (props?.auth?.getLoading) {
            return (
              <div className="content d-flex justify-content-center align-items-center flex-column position-relative">
                <div>
                  <BoxesLoader boxColor={"#6366F1"} style={{ marginBottom: "20px" }} desktopSize={"128px"} mobileSize={"120px"} />
                  {/* <BookLoader
                    background={"linear-gradient(135deg, #6066FA, #4645F6)"}
                    desktopSize={"100px"}
                    mobileSize={"80px"}
                    textColor={"#4645F6"}
                    text={null}
                  /> */}
                </div>
                <div className=" position-absolute bottom-0 right-0 left-0 d-flex justify-content-center" style={{ marginBottom: "16.5em" }}>
                  <img className="mr-md-5 pr-md-5" src={aniLogo} style={{ maxWidth: "180px" }} />
                </div>
              </div>
            );
          } else {
            return <Switch>{getRoutes(routes)}</Switch>;
          }
        })()}
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : <Footer fluid />
        }
      </div>
      {(() => {
        if (props?.auth?.affiliator !== null) {
          return <FixedPlugin activeColor={activeColor} sidebarMini={sidebarMini} handleActiveClick={handleActiveClick} handleMiniClick={handleMiniClick} />;
        }
      })()}
      <CreateSectionWizard />
      <CreateBrandWizard />
    </div>
  );
};

export default connect((state) => state, { updateProfile })(withRouter(Index));
