import Dashboard from "pages/Dashboard";
import BestCommissions from "pages/BestCommissions";
import Stores from "pages/Stores/index";
import AddStores from "pages/Stores/AddStores";
import SystemSections from "pages/StoresSections/SystemSections";
import AddStoreSection from "pages/StoresSections/AddStoreSection";
import GetStoreById from "pages/Stores/GetStoreById";
import UpdateStores from "pages/Stores/UpdateStores";
import MainStoreDomain from "pages/Stores/MainStoreDomain";
import StoreSubscriptions from "pages/Stores/StoreSubscriptions";

import Systems from "pages/Systems";
import AvailableSystems from "pages/AvailableSystems";
import Profile from "pages/Profile";
import ReceiveCommission from "pages/ReceiveCommission";

import { CgProfile, CgMenuGridO, CgMenuGridR } from "react-icons/cg";
import { GiReceiveMoney } from "react-icons/gi";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { VscGitMerge } from "react-icons/vsc";
import { GiExitDoor, GiDoor } from "react-icons/gi";
import { FiSettings } from "react-icons/fi";
import { FaStoreAlt } from "react-icons/fa";
import { RiBarChartLine } from "react-icons/ri";
import { MdShowChart } from "react-icons/md";
import { ImNewspaper } from "react-icons/im";

const routes = [
  {
    path: "/",
    name: "Stores",
    rtlName: "أنظمة العمولة",
    icon: <CgMenuGridR size="20px" />,
    component: AvailableSystems,
  },
  // {
  //   path: "/popular/products",
  //   name: "Stores",
  //   rtlName: "المنتجات الشائعة",
  //   icon: <RiBarChartLine size="20px" />,
  //   component: BestCommissions,
  //   layout: "/",
  // },
  {
    path: "/stores",
    name: "Stores",
    rtlName: "متاجري",
    icon: <FaStoreAlt size="20px" />,
    component: Stores,
  },
  // {
  //   collapse: true,
  //   name: 'Pages',
  //   rtlName: 'متاجري',
  //   icon: <FaStoreAlt size='20px' />,
  //   state: 'pagesCollapse',
  //   views: [
  //     {
  //       path: '/stores',
  //       name: 'Pricing',
  //       rtlName: 'عرض المتاجر',
  //       icon: <CgMenuGridO size='20px' />,
  //       component: Stores
  //     },
  //     {
  //       path: '/stores/add',
  //       name: 'Pricing',
  //       rtlName: 'اضافة متجر',
  //       icon: <HiOutlineViewGridAdd size='20px' />,
  //       component: AddStores
  //     }
  //   ]
  // },
  {
    path: "/stores/add",
    name: "Pricing",
    rtlName: "اضافة متجر",
    icon: <HiOutlineViewGridAdd size="20px" />,
    component: AddStores,
    isHidden: true,
  },

  {
    path: "/system/sections/:brandId",
    name: "Pricing",
    rtlName: "اقسام التاجر",
    icon: <VscGitMerge size="20px" />,
    component: SystemSections,
    isHidden: true,
  },
  {
    path: "/stores/:id",
    name: "Pricing",
    rtlName: "المتجر",
    icon: <HiOutlineViewGridAdd size="20px" />,
    component: GetStoreById,
    isHidden: true,
  },
  {
    path: "/stores/update/:id",
    name: "Pricing",
    rtlName: "تعديل المتجر",
    icon: <HiOutlineViewGridAdd size="20px" />,
    component: UpdateStores,
    isHidden: true,
  },
  {
    path: "/stores/update/domain/:id",
    name: "Pricing",
    rtlName: "خدمات المتجر المدفوعة",
    icon: <HiOutlineViewGridAdd size="20px" />,
    component: MainStoreDomain,
    isHidden: true,
  },
  {
    path: "/stores/subscriptions/:serviceName/:id/:selectedUrl?",
    name: "Pricing",
    rtlName: "خدمات المتجر المدفوعة",
    icon: <HiOutlineViewGridAdd size="20px" />,
    component: StoreSubscriptions,
    isHidden: true,
  },
  // {
  //   path: '/subscribtions',
  //   name: 'Settings',
  //   rtlName: 'اشتركاتي',
  //   icon: <ImNewspaper size='20px' />,
  //   component: Dashboard
  // },
  {
    path: "/profile",
    name: "Settings",
    rtlName: "حسابي",
    icon: <CgProfile size="20px" />,
    component: Profile,
  },
  {
    path: "/receive/commission",
    name: "Settings",
    rtlName: "استلام العمولة",
    icon: <GiReceiveMoney size="20px" />,
    component: ReceiveCommission,
  },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   rtlName: 'الاعدادات',
  //   icon: <FiSettings size='20px' />,
  //   component: Dashboard
  // }
];

export default routes;
