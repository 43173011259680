import { apiGetAffiliator_Oauth, apiGetAffiliator, apiUpdateAffiliatorProfile, apiAddPaymentMethod, apiRemovePayment } from "../api/";

import setAuthHeader from "../api/setAuthHeader";
import queryString from "query-string";

import { HandelMyError } from "../store/Redux.Hander.js";
const AFFILIATOR_TOKEN_NAME = "AFFILIATOR_TOKEN_NAME";

const GETLOADING = "GETLOADING";
const GETLOADING_STOP = "GETLOADING_STOP";
const GETSUCCESS = "GETSUCCESS";
const GETFAILED = "GETFAILED";

const HEADER_ALERT = "HEADER_ALERT";
const INTER_ACTIVE = "INTER_ACTIVE";

const UPDATELOADING = "UPDATELOADING";
const UPDATESUCCESS = "UPDATESUCCESS";
const UPDATEFAILED = "UPDATEFAILED";

const AUTHENTICATION_RESET = "GETALLLOADINGAUTHENTICATION_RESET";
const AUTHENTICATION_RESETALL = "GETALLSUCCESSAUTHENTICATION_RESETALL";

const UPDATELOADINGAUTHENTICATION = "UPDATELOADINGAUTHENTICATION";
const UPDATESUCCESSAUTHENTICATION = "UPDATESUCCESSAUTHENTICATION";
const UPDATEFAILEDAUTHENTICATION = "UPDATEFAILEDAUTHENTICATION";

export const resetAuthentication = () => {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATION_RESET });
  };
};

export const resetAllAuthentication = () => {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATION_RESETALL });
  };
};

export const onLoadingSignIn = () => {
  return async (dispatch) => {
    try {
      // If token in URL
      let query = queryString.parse(window.location.search);
      const localToken = localStorage.getItem(AFFILIATOR_TOKEN_NAME);
      dispatch({ type: GETLOADING });

      if ("token" in query) {
        const {
          data: { affiliator, token },
        } = await apiGetAffiliator_Oauth(query.token);
        localStorage.setItem(AFFILIATOR_TOKEN_NAME, token);
        setAuthHeader(token);
        dispatch({ type: GETSUCCESS, payload: affiliator });
      }

      if ("internalToken" in query) {
        localStorage.setItem(AFFILIATOR_TOKEN_NAME, query.internalToken);
        setAuthHeader(query.internalToken);
        // delete query.internalToken
        // dispatch(
        //   push({
        //     search: queryString.stringify(query)
        //   })
        // )
        const {
          data: { affiliator },
        } = await apiGetAffiliator();
        dispatch({ type: GETSUCCESS, payload: affiliator });
      }

      if (localToken !== null && localToken !== "undefined") {
        setAuthHeader(localToken);

        const {
          data: { affiliator },
        } = await apiGetAffiliator();
        dispatch({ type: GETSUCCESS, payload: affiliator });
      }
      dispatch({ type: GETLOADING_STOP });
      // if (
      //   (localToken === null || localToken === 'undefined') &&
      //   !('token' in query) &&
      //   !('internalToken' in query)
      // ) {
      //   // No token
      //   window.location.href = 'https://affilih.com/affiliator'
      // }
    } catch (e) {
      if (e?.response?.data?.removeToken) {
        localStorage.removeItem(AFFILIATOR_TOKEN_NAME);
      }
      window.location.href = "https://affilih.com/affiliator";
      dispatch({ type: GETFAILED, payload: HandelMyError(e) });
    }
  };
};

export const updateProfile = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADING, payload: { profile: true } });
      const {
        data: { success },
      } = await apiUpdateAffiliatorProfile(data);
      dispatch({ type: UPDATESUCCESS, payload: { profile: success } });
      dispatch(onLoadingSignIn());
    } catch (e) {
      dispatch({ type: UPDATEFAILED, payload: { profile: HandelMyError(e) } });
    }
  };
};

export const addPaymentMethod = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGAUTHENTICATION });
      const {
        data: { success },
      } = await apiAddPaymentMethod(data);
      dispatch(onLoadingSignIn());
      dispatch({ type: UPDATESUCCESSAUTHENTICATION, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};

export const removePayment = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGAUTHENTICATION });
      const {
        data: { success },
      } = await apiRemovePayment(id);
      dispatch(onLoadingSignIn());
      dispatch({ type: UPDATESUCCESSAUTHENTICATION, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};

export const runAlert = (data) => {
  return async (dispatch) => {
    dispatch({ type: HEADER_ALERT, payload: { ...data } });
  };
};

export const clearAlert = (data) => {
  return async (dispatch) => {
    dispatch({ type: HEADER_ALERT, payload: null });
  };
};

export const runInterActive = (data) => {
  return async (dispatch) => {
    dispatch({ type: INTER_ACTIVE, payload: { ...data } });
  };
};

export const clearInterActive = (data) => {
  return async (dispatch) => {
    dispatch({ type: INTER_ACTIVE, payload: null });
  };
};

const INITIAL_STATE = {
  getLoading: false,
  affiliator: null,
  getFailed: null,

  updateLoading: {},
  updateSuccess: {},
  updateFailed: {},

  updateLoadingAuthentication: false,
  updateSuccessAuthentication: null,
  updateFailedAuthentication: null,

  headerAlert: {},

  interActive: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HEADER_ALERT:
      return {
        ...state,
        ...(action.payload !== null && {
          headerAlert: { ...state?.headerAlert, ...action?.payload },
        }),
        ...(action.payload === null && { headerAlert: {} }),
      };

    case INTER_ACTIVE:
      return {
        ...state,
        ...(action.payload !== null && {
          interActive: { ...state?.interActive, ...action?.payload },
        }),
        ...(action.payload === null && { interActive: {} }),
      };

    // Fetch Affiliator
    case GETLOADING:
      return {
        ...state,
        getLoading: true,
        affiliator: null,
        getFailed: null,
      };
    case GETLOADING_STOP:
      return {
        ...state,
        getLoading: false,
      };
    case GETSUCCESS:
      return {
        ...state,
        getLoading: false,
        affiliator: action.payload || {},
        getFailedProfile: null,
      };
    case GETFAILED:
      return {
        ...state,
        getLoading: false,
        affiliator: null,
        getFailed: action.payload || {},
      };

    case UPDATELOADINGAUTHENTICATION:
      return {
        ...state,
        updateLoadingAuthentication: true,
        updateSuccessAuthentication: null,
        updateFailedAuthentication: null,
      };
    case UPDATESUCCESSAUTHENTICATION:
      return {
        ...state,
        updateLoadingAuthentication: false,
        updateSuccessAuthentication: action.payload || "",
        updateFailedAuthentication: null,
      };
    case UPDATEFAILEDAUTHENTICATION:
      return {
        ...state,
        updateLoadingAuthentication: false,
        updateSuccessAuthentication: null,
        updateFailedAuthentication: action.payload || "",
      };
    // Update Affiliator
    case UPDATELOADING:
      return {
        ...state,
        updateLoading: { ...INITIAL_STATE.updateLoading, ...action.payload },
        updateSuccess: {
          ...Object.assign(
            {},
            INITIAL_STATE.updateSuccess,
            ...Object.keys(action.payload).flatMap((e) => {
              return { [e]: null };
            })
          ),
        },
        updateFailed: {
          ...Object.assign(
            {},
            INITIAL_STATE.updateFailed,
            ...Object.keys(action.payload).flatMap((e) => {
              return { [e]: null };
            })
          ),
        },
      };
    case UPDATESUCCESS:
      return {
        ...state,
        updateLoading: {
          ...Object.assign(
            {},
            INITIAL_STATE.updateLoading,
            ...Object.keys(action.payload).flatMap((e) => {
              return { [e]: null };
            })
          ),
        },
        updateSuccess: { ...INITIAL_STATE.updateSuccess, ...action.payload },
        updateFailed: {
          ...Object.assign(
            {},
            INITIAL_STATE.updateFailed,
            ...Object.keys(action.payload).flatMap((e) => {
              return { [e]: null };
            })
          ),
        },
      };
    case UPDATEFAILED:
      return {
        ...state,
        updateSuccess: {
          ...Object.assign(
            {},
            INITIAL_STATE.updateSuccess,
            ...Object.keys(action.payload).flatMap((e) => {
              return { [e]: null };
            })
          ),
        },
        updateLoading: {
          ...Object.assign(
            {},
            INITIAL_STATE.updateLoading,
            ...Object.keys(action.payload).flatMap((e) => {
              return { [e]: null };
            })
          ),
        },
        updateFailed: { ...INITIAL_STATE.updateFailed, ...action.payload },
      };

    default:
      return state;
  }
};
