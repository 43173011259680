import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  getStoresSectionsByID,
  setSectionActiveInStoreSection,
  getAllStoreSectionByStoreId,
  deleteStoreByInBrandId,
  deleteStoreSectionByInStoreId,
  deleteSection_StoreSectionByInStoreId,
  runAlert,
  runInterActive,
  reset_registerInSystem_FN
} from '../../redux/config'
import GetFailedComponent from '../../components/GetFailed'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { BiMerge } from 'react-icons/bi'
import { HiUserGroup } from 'react-icons/hi'

import {
  Card,
  CardHeader,
  Button,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  CardFooter,
  CardText,
  Navbar
} from 'reactstrap'
import createStore from 'assets/images/createStore.svg'
import { bounceInDown } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import StoreBreadcrumb from '../../components/Store/StoreBreadcrumb'

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown
} from 'reactstrap'
import {
  ListGroup,
  ListGroupItem,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardSubtitle,
  Label,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Table,
  Badge,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'
import { FiSettings, FiCheckCircle } from 'react-icons/fi'
import { VscNewFile } from 'react-icons/vsc'
import { CgMenuGridR } from 'react-icons/cg'
import { TiThList } from 'react-icons/ti'
import { AiOutlineLink } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { FcShop, FcAdvertising } from 'react-icons/fc'
import { RiCloudLine } from 'react-icons/ri'
import { ImHeartBroken } from 'react-icons/im'
import { MdWbIncandescent } from 'react-icons/md'
import { BsFilePlus, BsTrash, BsCheckAll } from 'react-icons/bs'
import { GiStarsStack } from 'react-icons/gi'
import logo from 'assets/images/ator.gif'
import WavingHnd from 'assets/images/waving-hand.png'
import InterActive from 'components/InterActive'

import { Img } from 'react-image'
import ToastComponent from '../../components/Toast'
// import { FaStore } from "react-icons/fa";

import LOGO from 'assets/images/logo.png'
import { URL, URLIMG } from '../../redux/api/api.conf'
import { CgArrowRightR } from 'react-icons/cg'
import { IoMdDoneAll } from 'react-icons/io'

const GetStoreSystemsByIdComponent = props => {
  const {
    storeSection,
    storeSection: {
      deleteLoadingStoreSection,
      deleteSuccessStoreSection,
      deleteFailedStoreSection,

      updateLoadingStoreSection,
      updateSuccessStoreSection,
      updateFailedStoreSection
    },
    stores: { getLoadingStores, getSuccessStores, getFailedStores },
    auth: { store, interActive, affiliator },

    getStoresSectionsByID,
    setSectionActiveInStoreSection,
    getAllStoreSectionByStoreId,
    deleteStoreByInBrandId,
    deleteStoreSectionByInStoreId,
    deleteSection_StoreSectionByInStoreId,
    runAlert,
    runInterActive,
    reset_registerInSystem_FN
  } = props
  const [success, setSuccess] = useState(true)
  const [danger, setDanger] = useState(true)

  useEffect(() => {
    if (affiliator !== null) {
      getStoresSectionsByID(props.match.params.id)
    }
  }, [affiliator])

  useEffect(() => {
    if (updateLoadingStoreSection === true) {
      runInterActive({ type: 'loading' })
      // runAlert({type:"loading"});
    }
    if (updateSuccessStoreSection !== null) {
      runInterActive({ type: 'success' })
      // runAlert({type:"success", msg: systems?.registerInSystem?.success});
    }
    if (updateFailedStoreSection !== null) {
      runInterActive({ type: 'failed' })
      runAlert({ type: 'failed', msg: updateFailedStoreSection })
    }
  }, [storeSection])

  return (
    <div className='content'>
      {(() => {
        if (getSuccessStores !== null) {
          return (
            <>
              <StoreBreadcrumb store={getSuccessStores} page='inside' />
              <Card className='card-user'>
                <CardBody>
                  {/* <CardText /> */}
                  <div className='author'>
                    <div className='block block-one' />
                    <div className='block block-two' />
                    <div className='block block-three' />
                    <div className='block block-four' />
                    <a href='#pablo' onClick={e => e.preventDefault()}>
                      {(() => {
                        if (
                          getSuccessStores?.storeImg !== '' &&
                          getSuccessStores?.storeImg !== undefined
                        ) {
                          return (
                            <>
                              <Img
                                className='avatar'
                                width='124px'
                                height='124px'
                                style={{ objectFit: 'contain' }}
                                alt={`${getSuccessStores?.storeName}`}
                                src={[
                                  URLIMG + getSuccessStores?.storeImg,
                                  logo
                                ]}
                                container={children => children}
                                loader={
                                  <SkeletonTheme
                                    width='100%'
                                    height='100%'
                                    color='#344675'
                                    highlightColor='#fff'
                                    className='d-flex align-items-center flex-row'
                                  >
                                    <Skeleton
                                      duration={0.5}
                                      circle='true'
                                      width={124}
                                      height={124}
                                    />
                                  </SkeletonTheme>
                                }
                              />
                            </>
                          )
                        }
                      })()}
                      <h5
                        className='title font-weight-light'
                        style={{ fontSize: '2em' }}
                      >
                        {getSuccessStores?.storeName}
                      </h5>
                    </a>
                    <p className='description'>{getSuccessStores?.storeDesc}</p>
                    <Row className='mt-4'>
                      <Col>
                        <CardTitle>الحد الادني للعمولة</CardTitle>
                        <CardSubtitle className='mb-2 text-muted'>
                          {getSuccessStores?.minimumCommission}{' '}
                          {getSuccessStores?.isPercent === true
                            ? '%'
                            : getSuccessStores?.storeCurrency}
                        </CardSubtitle>
                      </Col>
                      <Col>
                        <CardTitle>الزيادة فالسعر</CardTitle>
                        <CardSubtitle className='mb-2 text-muted'>
                          {getSuccessStores?.overprice}{' '}
                          {getSuccessStores?.isPercent === true
                            ? '%'
                            : getSuccessStores?.storeCurrency}
                        </CardSubtitle>
                      </Col>
                      <Col>
                        <CardTitle>الخصم</CardTitle>
                        <CardSubtitle className='mb-2 text-muted'>
                          {getSuccessStores?.discount}{' '}
                          {getSuccessStores?.isPercent === true
                            ? '%'
                            : getSuccessStores?.storeCurrency}
                        </CardSubtitle>
                      </Col>
                    </Row>

                    <CardImgOverlay>
                      <div className='w-100 h-100 position-relative'></div>
                    </CardImgOverlay>
                  </div>
                </CardBody>
              </Card>
            </>
          )
        }
      })()}
      <div className='divider' />
      {(() => {
        if (deleteSuccessStoreSection !== null) {
          return (
            <Alert
              color='info'
              className='text-lowercase'
              isOpen={success}
              toggle={() => setSuccess(false)}
            >
              <strong>
                <IoMdDoneAll size='15px' />
                {deleteSuccessStoreSection}!
              </strong>
              .
            </Alert>
          )
        }
      })()}
      {(() => {
        if (deleteFailedStoreSection !== null) {
          return (
            <Alert
              color='danger'
              className='text-lowercase'
              isOpen={danger}
              toggle={() => setDanger(false)}
            >
              <strong>
                <IoMdDoneAll size='15px' />
                {deleteFailedStoreSection}!
              </strong>
              .
            </Alert>
          )
        }
      })()}

      <div className='d-flex flex-row flex-wrap flex-wrap justify-content-start mt-2'>
        {(() => {
          if (getLoadingStores || deleteLoadingStoreSection) {
            return (
              <CardBody className='d-flex hover-scale-rounded px-0 w-100 flex-column w-100'>
                <SkeletonTheme
                  color='#344675'
                  highlightColor='#fff'
                  className='d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between'
                >
                  <div className='d-flex align-items-center flex-row flex-wrap w-100 '>
                    <p className='col-12 col-md-6 pr-0'>
                      <Skeleton height={70} className='w-100' duration={1} />
                    </p>
                    <p className='col-12 col-md-6 pl-0'>
                      <Skeleton height={70} className='w-100' duration={1} />
                    </p>
                  </div>
                </SkeletonTheme>
              </CardBody>
            )
          } else if (getFailedStores !== null) {
            return (
              <Container className='text-warning'>
                <GetFailedComponent failed={getFailedStores} />
              </Container>
            )
          } else if (getSuccessStores !== null) {
            return (
              <>
                <Card className='w-100'>
                  <CardHeader className='rtl border-top py-3 border-default'>
                    <HiUserGroup size='15px' className='ml-1' /> تجار مختارة
                  </CardHeader>
                  {(() => {
                    if (getSuccessStores?.storebrands?.length === 0) {
                      return (
                        <CardBody className='d-flex bg-white hover-scale-rounded justify-content-center align-items-center p-3 w-100'>
                          <div className='font-weight-bold text-center'>
                            لايوجد تجار مختارة
                          </div>
                        </CardBody>
                      )
                    } else {
                      return (
                        <CardBody className=' d-flex flex-row flex-wrap'>
                          {getSuccessStores?.storebrands?.map(storebrands => (
                            <Col
                              sm='12'
                              md='6'
                              key={storebrands?._id}
                              className='border-right border-default mt-2'
                            >
                              <CardBody className='px-0'>
                                <CardTitle className='d-flex justify-content-between align-items-center'>
                                  <span
                                    className='text-right ellipsis-text d-inline-flex align-content-center'
                                    title={
                                      storebrands?.brandData?.settingsStoreName
                                    }
                                    style={{
                                      maxWidth: '300px'
                                    }}
                                  >
                                    <Img
                                      key={
                                        storebrands?.brandData?.settingsStoreImg
                                      }
                                      className='rounded-circle border-0 ml-1'
                                      width='25'
                                      height='25'
                                      style={{
                                        borderWidth: '3px !important',
                                        objectFit: 'contain'
                                      }}
                                      alt={`...`}
                                      src={[
                                        URLIMG +
                                          storebrands?.brandData
                                            ?.settingsStoreImg,
                                        logo
                                      ]}
                                      id={
                                        'CgMenuGridR' +
                                        storebrands?.brandData?._id +
                                        storebrands?.brandData?._id
                                      }
                                      container={children => {
                                        return <>{children}</>
                                      }}
                                      loader={
                                        <SkeletonTheme
                                          width='100%'
                                          height='100%'
                                          color='#344675'
                                          highlightColor='#fff'
                                          className='d-inline-flex'
                                        >
                                          <Skeleton
                                            className='rounded-circle'
                                            duration={0.5}
                                            width={25}
                                            height={25}
                                          />
                                        </SkeletonTheme>
                                      }
                                    />{' '}
                                    {storebrands?.brandData?.settingsStoreName}
                                    <Badge
                                      color='primary'
                                      className='mb-0 mr-1'
                                    >
                                      {storebrands?.sections?.length}
                                    </Badge>
                                  </span>
                                  <UncontrolledDropdown
                                    group
                                    direction='right'
                                    className='ltr'
                                  >
                                    <DropdownToggle
                                      size='sm'
                                      data-toggle='dropdown'
                                      color='default'
                                      className='btn-simple border-0 animation-on-hover px-0 text-white'
                                    >
                                      <HiOutlineDotsVertical size='25px' />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      style={{ left: '0 !important' }}
                                    >
                                      <DropdownItem
                                        onClick={() =>
                                          deleteStoreByInBrandId({
                                            storeId: props.match.params.id,
                                            storebrand: storebrands?._id
                                          })
                                        }
                                        className='text-danger'
                                      >
                                        حذف التاجر{' '}
                                        <BsTrash size='20px' className='ml-1' />
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </CardTitle>
                                <div className='text-center border-top border-default py-3 mt-2'>
                                  <Table>
                                    <thead className='text-primary'>
                                      <tr>
                                        <th className='text-right'>
                                          الحد الادني للعمولة
                                        </th>
                                        <th className='text-right'>
                                          الزيادة فالسعر
                                        </th>
                                        <th className='text-right'>الخصم</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr key={storebrands?._id}>
                                        <td className='text-right'>
                                          {' '}
                                          {storebrands?.minimumCommission}{' '}
                                          {storebrands?.isPercent === true
                                            ? '%'
                                            : storebrands?.brandData?.currency}
                                        </td>
                                        <td className='text-right'>
                                          {' '}
                                          {storebrands?.overprice}{' '}
                                          {storebrands?.isPercent === true
                                            ? '%'
                                            : storebrands?.brandData?.currency}
                                        </td>
                                        <td className='text-right'>
                                          {' '}
                                          {storebrands?.discount}{' '}
                                          {storebrands?.isPercent === true
                                            ? '%'
                                            : storebrands?.brandData?.currency}
                                        </td>
                                      </tr>{' '}
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Col>
                          ))}
                        </CardBody>
                      )
                    }
                  })()}
                </Card>
                <Card className='w-100'>
                  <CardHeader className='rtl border-top py-3 border-default'>
                    <TiThList size='15px' className='ml-1' /> أقسام مختارة لبعض
                    التجار
                  </CardHeader>
                  {(() => {
                    if (getSuccessStores?.storesections?.length === 0) {
                      return (
                        <CardBody className='d-flex bg-white hover-scale-rounded justify-content-center align-items-center p-3 w-100'>
                          <div className='font-weight-bold text-center'>
                            لايوجد أقسام في المتجر
                          </div>
                        </CardBody>
                      )
                    } else {
                      return (
                        <CardBody className=' d-flex flex-row flex-wrap'>
                          {getSuccessStores?.storesections?.map(
                            storeSections => (
                              <Col
                                sm='12'
                                md='6'
                                key={storeSections?._id}
                                className='border-right border-default mt-2'
                              >
                                <CardBody className='px-0'>
                                  <CardTitle className='d-flex justify-content-between align-items-center'>
                                    <span
                                      className='text-right ellipsis-text d-inline-flex align-content-center'
                                      title={
                                        storeSections?.mainSection
                                          ?.systemSection?.sectionName
                                      }
                                      style={{
                                        maxWidth: '300px'
                                      }}
                                    >
                                      <Img
                                        key={
                                          storeSections?.mainSection
                                            ?.sectionConfig?._id
                                        }
                                        className='rounded-circle border-0'
                                        width='25'
                                        height='25'
                                        style={{
                                          borderWidth: '3px !important',
                                          objectFit: 'contain'
                                        }}
                                        alt={`...`}
                                        src={[
                                          URLIMG +
                                            storeSections?.mainSection
                                              ?.systemSection?.sectionImg,
                                          logo
                                        ]}
                                        id={
                                          'CgMenuGridR' +
                                          storeSections?.mainSection
                                            ?.systemSection?._id +
                                          storeSections?.mainSection
                                            ?.sectionConfig?._id
                                        }
                                        container={children => {
                                          return <>{children}</>
                                        }}
                                        loader={
                                          <SkeletonTheme
                                            width='100%'
                                            height='100%'
                                            color='#344675'
                                            highlightColor='#fff'
                                            className='d-flex align-items-center flex-row'
                                          >
                                            <Skeleton
                                              className='rounded-circle'
                                              duration={0.5}
                                              width={25}
                                              height={25}
                                            />
                                          </SkeletonTheme>
                                        }
                                      />{' '}
                                      {
                                        storeSections?.mainSection
                                          ?.systemSection?.sectionName
                                      }
                                      <Badge
                                        color='primary'
                                        className='mb-0 mr-1'
                                      >
                                        {storeSections?.sections?.length}
                                      </Badge>
                                    </span>
                                    <UncontrolledDropdown
                                      group
                                      direction='right'
                                      className='ltr'
                                    >
                                      <DropdownToggle
                                        size='sm'
                                        data-toggle='dropdown'
                                        color='default'
                                        className='btn-simple border-0 animation-on-hover px-0 text-white'
                                      >
                                        <HiOutlineDotsVertical size='25px' />
                                      </DropdownToggle>
                                      <DropdownMenu
                                        style={{ left: '0 !important' }}
                                      >
                                        <DropdownItem
                                          onClick={() =>
                                            deleteStoreSectionByInStoreId({
                                              storeSectionId:
                                                storeSections?._id,
                                              storeId: props.match.params.id
                                            })
                                          }
                                          className='text-danger'
                                        >
                                          حذف القسم{' '}
                                          <BsTrash
                                            size='20px'
                                            className='ml-1'
                                          />
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </CardTitle>
                                  <div className='text-center border-top border-default py-3 mt-2'>
                                    <Table>
                                      <thead className='text-primary'>
                                        <tr>
                                          <th className='text-right pr-0'>
                                            القسم
                                          </th>
                                          <th className='text-right'>
                                            الحد الادني للعمولة
                                          </th>
                                          <th className='text-right'>
                                            الزيادة فالسعر
                                          </th>
                                          <th className='text-right'>الخصم</th>
                                          <th className='text-center'></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {storeSections?.sections?.map(
                                          section => (
                                            <tr
                                              key={section?.sectionConfig?._id}
                                            >
                                              <td
                                                className='text-right ellipsis-text pr-0'
                                                title={
                                                  section?.systemSection
                                                    ?.sectionName
                                                }
                                                style={{ maxWidth: '100px' }}
                                              >
                                                <Img
                                                  key={
                                                    section?.sectionConfig?._id
                                                  }
                                                  className='rounded-circle border-0'
                                                  width='25'
                                                  height='25'
                                                  style={{
                                                    borderWidth:
                                                      '3px !important',
                                                    objectFit: 'contain'
                                                  }}
                                                  alt={`...`}
                                                  src={[
                                                    URLIMG +
                                                      section?.systemSection
                                                        ?.sectionImg,
                                                    logo
                                                  ]}
                                                  id={
                                                    'CgMenuGridR' +
                                                    section?.systemSection
                                                      ?._id +
                                                    section?.sectionConfig?._id
                                                  }
                                                  container={children => {
                                                    return <>{children}</>
                                                  }}
                                                  loader={
                                                    <SkeletonTheme
                                                      width='100%'
                                                      height='100%'
                                                      color='#344675'
                                                      highlightColor='#fff'
                                                      className='d-flex align-items-center flex-row'
                                                    >
                                                      <Skeleton
                                                        className='rounded-circle'
                                                        duration={0.5}
                                                        width={25}
                                                        height={25}
                                                      />
                                                    </SkeletonTheme>
                                                  }
                                                />{' '}
                                                {
                                                  section?.systemSection
                                                    ?.sectionName
                                                }
                                              </td>
                                              <td className='text-right'>
                                                {' '}
                                                {
                                                  section?.sectionConfig
                                                    ?.minimumCommission
                                                }{' '}
                                                {section?.sectionConfig
                                                  ?.isPercent === true
                                                  ? '%'
                                                  : section?.brandData
                                                      ?.currency}
                                              </td>

                                              <td className='text-right'>
                                                {' '}
                                                {
                                                  section?.sectionConfig
                                                    ?.overprice
                                                }{' '}
                                                {section?.sectionConfig
                                                  ?.isPercent === true
                                                  ? '%'
                                                  : section?.brandData
                                                      ?.currency}
                                              </td>
                                              <td className='text-right'>
                                                {' '}
                                                {
                                                  section?.sectionConfig
                                                    ?.discount
                                                }{' '}
                                                {section?.sectionConfig
                                                  ?.isPercent === true
                                                  ? '%'
                                                  : section?.brandData
                                                      ?.currency}
                                              </td>
                                              <td className='text-center'>
                                                {(() => {
                                                  if (
                                                    interActive?.key ===
                                                    'storeSections' +
                                                      section?.systemSection
                                                        ?._id
                                                  ) {
                                                    return (
                                                      <InterActive
                                                        className='overflow-hidden h-100 btn-simple border-0 animation-on-hover px-2 btn-behance btn-sm d-flex align-items-center justify-content-center'
                                                        onEndFN={
                                                          reset_registerInSystem_FN
                                                        }
                                                      />
                                                    )
                                                  } else {
                                                    return (
                                                      <>
                                                        <StyleRoot className='h-100 '>
                                                          <div
                                                            className='d-flex align-items-center justify-content-center h-100'
                                                            style={{
                                                              animation: 'x 1s',
                                                              animationName: Radium.keyframes(
                                                                bounceInDown,
                                                                'bounceInDown'
                                                              )
                                                            }}
                                                          >
                                                            {(() => {
                                                              if (
                                                                !section
                                                                  ?.sectionConfig
                                                                  ?.isMain
                                                              ) {
                                                                return (
                                                                  <UncontrolledDropdown
                                                                    group
                                                                    direction='right'
                                                                    className='ltr'
                                                                  >
                                                                    <DropdownToggle
                                                                      size='sm'
                                                                      data-toggle='dropdown'
                                                                      color='default'
                                                                      className='btn-simple border-0 animation-on-hover px-0 text-white-50'
                                                                    >
                                                                      <HiOutlineDotsVertical size='25px' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu
                                                                      style={{
                                                                        left:
                                                                          '0 !important'
                                                                      }}
                                                                    >
                                                                      <DropdownItem
                                                                        onClick={() => {
                                                                          setSectionActiveInStoreSection(
                                                                            section
                                                                              ?.systemSection
                                                                              ?._id,
                                                                            storeSections?._id,
                                                                            props
                                                                              .match
                                                                              .params
                                                                              .id
                                                                          )

                                                                          runInterActive(
                                                                            {
                                                                              key:
                                                                                'storeSections' +
                                                                                section
                                                                                  ?.systemSection
                                                                                  ?._id
                                                                            }
                                                                          )
                                                                        }}
                                                                      >
                                                                        قسم
                                                                        رئيسي{' '}
                                                                        <BsCheckAll
                                                                          size='20px'
                                                                          className='ml-1'
                                                                        />
                                                                      </DropdownItem>
                                                                      <DropdownItem
                                                                        onClick={() => {
                                                                          deleteSection_StoreSectionByInStoreId(
                                                                            {
                                                                              sectionId:
                                                                                section
                                                                                  ?.systemSection
                                                                                  ?._id,
                                                                              storeSectionId:
                                                                                storeSections?._id,
                                                                              storeId:
                                                                                props
                                                                                  .match
                                                                                  .params
                                                                                  .id
                                                                            }
                                                                          )
                                                                          runInterActive(
                                                                            {
                                                                              key:
                                                                                'storeSections' +
                                                                                section
                                                                                  ?.systemSection
                                                                                  ?._id
                                                                            }
                                                                          )
                                                                        }}
                                                                        className='text-danger'
                                                                      >
                                                                        كسر
                                                                        الدمج{' '}
                                                                        <ImHeartBroken
                                                                          size='20px'
                                                                          className='ml-1'
                                                                        />
                                                                      </DropdownItem>
                                                                    </DropdownMenu>
                                                                  </UncontrolledDropdown>
                                                                )
                                                              } else {
                                                                return (
                                                                  <BsCheckAll
                                                                    size='20px'
                                                                    className='text-success'
                                                                  />
                                                                )
                                                              }
                                                            })()}
                                                          </div>
                                                        </StyleRoot>
                                                      </>
                                                    )
                                                  }
                                                })()}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Col>
                            )
                          )}
                        </CardBody>
                      )
                    }
                  })()}
                  <Navbar color='dark' className='navbar-dark'>
                    <ul className='px-0'>
                      <li className='navbar-text'>
                        يمكنك دمج متجرين ولكن قد يتشابة قسمين بنفس الأسم ، وقد
                        يؤدي ذلك إلى تشتيت انتباه العميل ، لذلك قمنا بإضافة ميزة
                        "الأقسام المحددة" ، حيث يمكنك دمج قسمين تحت قسم واحد،
                        وعندما تحدد قسمًا كقسم رئيسي ، سيظهر اسمه وصورته ، بما
                        في ذلك جميع منتجات الأقسام المحددة.{' '}
                      </li>
                    </ul>
                  </Navbar>
                </Card>
              </>
            )
          }
        })()}
      </div>
    </div>
  )
}

const GetStoreSystemsById = connect(state => state, {
  getStoresSectionsByID,
  setSectionActiveInStoreSection,
  getAllStoreSectionByStoreId,
  deleteSection_StoreSectionByInStoreId,
  deleteStoreByInBrandId,
  deleteStoreSectionByInStoreId,

  runAlert,
  runInterActive,
  reset_registerInSystem_FN
})(GetStoreSystemsByIdComponent)
export default GetStoreSystemsById
