import {
  apiGetAllSection,
  apiGetSystemSectionsById,
  apiGetBestCommissions,
  apiGetAllSectionForSelect,
  apiGetAllProductsInSections,
  apiDeleteAllSection,
  apiGetSectionByID,
  apiGetSectionReportByID,
  apiGetStoreSectionById,
  apiGetSectionDetailsByID,
  apiSaveSection,
  apiUpdateSectionByID,
  apiDeleteSectionByID
} from '../api/section.api'
import { getStoreSectionBySectionId } from '../config'

import { HandelMyError } from '../store/Redux.Hander.js'
const SECTION_RESET = 'SECTION_RESET'
const SECTION_RESETALL = 'SECTION_RESETALL'

const RESETSTOREBYID = 'RESETSTOREBYID'

const SECTION_RESETALLSECTION_IN_PRODUCTS =
  'SECTION_RESETALLSECTION_IN_PRODUCTS'
const GETALLLOADINGSECTION_IN_PRODUCTS = 'GETALLLOADINGSECTION_IN_PRODUCTS'
const GETALLSUCCESSSECTION_IN_PRODUCTS = 'GETALLSUCCESSSECTION_IN_PRODUCTS'
const GETALLFAILEDSECTION_IN_PRODUCTS = 'GETALLFAILEDSECTION_IN_PRODUCTS'

const GETALLLOADINGSECTION = 'GETALLLOADINGSECTION'
const GETALLSUCCESSSECTION = 'GETALLSUCCESSSECTION'
const GETALLFAILEDSECTION = 'GETALLFAILEDSECTION'

const SECTIONWIZARD = 'SECTIONWIZARD'

const BRANDWIZARD = 'BRANDWIZARD'

const DELETEALLLOADINGSECTION = 'DELETEALLLOADINGSECTION'
const DELETEALLSUCCESSSECTION = 'DELETEALLSUCCESSSECTION'
const DELETEALLFAILEDSECTION = 'DELETEALLFAILEDSECTION'

const GETLOADINGSECTION = 'GETLOADINGSECTION'
const GETSUCCESSSECTION = 'GETSUCCESSSECTION'
const GETFAILEDSECTION = 'GETFAILEDSECTION'

const SAVELOADINGSECTION = 'SAVELOADINGSECTION'
const SAVESUCCESSSECTION = 'SAVESUCCESSSECTION'
const SAVEFAILEDSECTION = 'SAVEFAILEDSECTION'

const UPDATELOADINGSECTION = 'UPDATELOADINGSECTION'
const UPDATESUCCESSSECTION = 'UPDATESUCCESSSECTION'
const UPDATEFAILEDSECTION = 'UPDATEFAILEDSECTION'

const DELETELOADINGSECTION = 'DELETELOADINGSECTION'
const DELETESUCCESSSECTION = 'DELETESUCCESSSECTION'
const DELETEFAILEDSECTION = 'DELETEFAILEDSECTION'

export const resetSection = () => {
  return dispatch => {
    dispatch({ type: SECTION_RESET })
  }
}

export const resetAllSection = () => {
  return dispatch => {
    dispatch({ type: SECTION_RESETALL })
  }
}

export const getAllSectionForSelect = () => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESETALL })
      dispatch({ type: GETALLLOADINGSECTION })
      const {
        data: { sections }
      } = await apiGetAllSectionForSelect()

      dispatch({
        type: GETALLSUCCESSSECTION,
        payload: { sections }
      })
    } catch (e) {
      dispatch({ type: GETALLFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

export const getHomeProductsInSections = () => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESETALLSECTION_IN_PRODUCTS })
      dispatch({ type: GETALLLOADINGSECTION_IN_PRODUCTS })
      const {
        data: { sections }
      } = await apiGetAllProductsInSections()

      dispatch({
        type: GETALLSUCCESSSECTION_IN_PRODUCTS,
        payload: { sections }
      })
    } catch (e) {
      dispatch({
        type: GETALLFAILEDSECTION_IN_PRODUCTS,
        payload: HandelMyError(e)
      })
    }
  }
}

export const openSectionWizard = section => {
  return async dispatch => {
    dispatch({ type: RESETSTOREBYID })
    dispatch({ type: SECTIONWIZARD, payload: { model: true, ...section } })
  }
}

export const setSectionWizard = data => {
  return async dispatch => {
    dispatch({ type: SECTIONWIZARD, payload: data })
  }
}

export const closeSectionWizard = () => {
  return async dispatch => {
    dispatch({ type: SECTIONWIZARD, payload: { model: false } })
  }
}

export const openBrandWizard = section => {
  return async dispatch => {
    dispatch({ type: RESETSTOREBYID })
    dispatch({ type: BRANDWIZARD, payload: { model: true, ...section } })
  }
}

export const setBrandWizard = data => {
  return async dispatch => {
    dispatch({ type: BRANDWIZARD, payload: data })
  }
}

export const closeBrandWizard = () => {
  return async dispatch => {
    dispatch({ type: BRANDWIZARD, payload: { model: false } })
  }
}

export const getBestCommissions = query => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESETALL })
      dispatch({ type: GETALLLOADINGSECTION })
      const { data } = await apiGetBestCommissions(query)
      dispatch({ type: GETALLSUCCESSSECTION, payload: data })
    } catch (e) {
      dispatch({ type: GETALLFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

export const getAllSection = () => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESETALL })
      dispatch({ type: GETALLLOADINGSECTION })
      const {
        data: { sections }
      } = await apiGetAllSection()

      dispatch({
        type: GETALLSUCCESSSECTION,
        payload: { sections }
      })
    } catch (e) {
      dispatch({ type: GETALLFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

export const getSystemSectionsById = id => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESETALL })
      dispatch({ type: GETALLLOADINGSECTION })
      const {
        data: { sections }
      } = await apiGetSystemSectionsById(id)
      dispatch({
        type: GETALLSUCCESSSECTION,
        payload: { sections }
      })
    } catch (e) {
      dispatch({ type: GETALLFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

export const deleteAllSection = id => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESETALL })
      dispatch({ type: DELETEALLLOADINGSECTION })
      const {
        data: { success }
      } = await apiDeleteAllSection()
      dispatch({
        type: DELETEALLSUCCESSSECTION,
        payload: success
      })
    } catch (e) {
      dispatch({ type: DELETEALLFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}
export const getSectionByID = id => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESET })
      dispatch({ type: GETLOADINGSECTION })
      const {
        data: { section }
      } = await apiGetSectionByID(id)
      dispatch({
        type: GETSUCCESSSECTION,
        payload: { section }
      })
    } catch (e) {
      dispatch({ type: GETFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}
export const getBasicSectionByID = (sectionId, storeId) => {
  return async dispatch => {
    try {
      dispatch({ type: GETLOADINGSECTION })
      const {
        data: { section }
      } = await apiGetSectionDetailsByID(sectionId)
      dispatch({ type: GETSUCCESSSECTION, payload: { section } })
      dispatch(getStoreSectionBySectionId(sectionId, storeId))
    } catch (e) {
      dispatch({ type: GETFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}
export const getStoreSectionById = sectionId => {
  return async dispatch => {
    try {
      dispatch({ type: GETLOADINGSECTION })
      const {
        data: { section }
      } = await apiGetStoreSectionById(sectionId)
      dispatch({ type: GETSUCCESSSECTION, payload: { section } })
    } catch (e) {
      dispatch({ type: GETFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}
export const getSectionReportByID = id => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESET })
      dispatch({ type: GETLOADINGSECTION })
      const {
        data: { allProductsProfit }
      } = await apiGetSectionReportByID(id)
      dispatch({
        type: GETSUCCESSSECTION,
        payload: { allProductsProfit }
      })
    } catch (e) {
      dispatch({ type: GETFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}
export const getSectionDetailsByID = (id, data) => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESET })
      dispatch({ type: GETLOADINGSECTION })
      const {
        data: { section }
      } = await apiGetSectionDetailsByID(id)
      dispatch({ type: GETSUCCESSSECTION, payload: { section } })
    } catch (e) {
      dispatch({ type: GETFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

export const updateSectionByID = (id, data) => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESET })
      dispatch({ type: UPDATELOADINGSECTION })
      const {
        data: { success }
      } = await apiUpdateSectionByID(id, data)
      dispatch({ type: UPDATESUCCESSSECTION, payload: success })
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSECTION, payload: HandelMyError(e) })
    }
    try {
      dispatch({ type: GETLOADINGSECTION })
      const {
        data: { section }
      } = await apiGetSectionDetailsByID(id)
      dispatch({ type: GETSUCCESSSECTION, payload: { section } })
    } catch (e) {
      dispatch({ type: GETFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

export const saveSection = section => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESET })
      dispatch({ type: SAVELOADINGSECTION })
      const {
        data: { success }
      } = await apiSaveSection(section)

      dispatch({
        type: SAVESUCCESSSECTION,
        payload: success
      })
    } catch (e) {
      dispatch({ type: SAVEFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

export const deleteSectionByID = id => {
  return async dispatch => {
    try {
      dispatch({ type: SECTION_RESET })
      dispatch({ type: DELETELOADINGSECTION })
      const {
        data: { success }
      } = await apiDeleteSectionByID(id)
      dispatch({
        type: DELETESUCCESSSECTION,
        payload: success
      })
    } catch (e) {
      dispatch({ type: DELETEFAILEDSECTION, payload: HandelMyError(e) })
    }
  }
}

const INITIAL_STATE = {
  getAllLoadingSection_In_Products: false,
  getAllSuccessSection_In_Products: null,
  getAllFailedSection_In_Products: null,

  getAllLoadingSection: false,
  getAllSuccessSection: null,
  getAllFailedSection: null,

  deleteAllLoadingSection: false,
  deleteAllSuccessSection: null,
  deleteAllFailedSection: null,

  getLoadingSection: false,
  getSuccessSection: null,
  getFailedSection: null,

  saveLoadingSection: false,
  saveSuccessSection: null,
  saveFailedSection: null,

  updateLoadingSection: false,
  updateSuccessSection: null,
  updateFailedSection: null,

  deleteLoadingSection: false,
  deleteSuccessSection: null,
  deleteFailedSection: null,

  sectionWizard: {},
  brandWizard: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SECTIONWIZARD:
      return {
        ...state,
        sectionWizard: { ...state?.sectionWizard, ...action?.payload }
      }
    case BRANDWIZARD:
      return {
        ...state,
        brandWizard: { ...state?.brandWizard, ...action?.payload }
      }

    case SECTION_RESETALLSECTION_IN_PRODUCTS:
      return {
        getAllLoadingSection_In_Products: false,
        getAllSuccessSection_In_Products: null,
        getAllFailedSection_In_Products: null
      }

    case GETALLLOADINGSECTION_IN_PRODUCTS:
      return {
        ...state,
        getAllLoadingSection_In_Products: true,
        getAllSuccessSection_In_Products: null,
        getAllFailedSection_In_Products: null
      }
    case GETALLSUCCESSSECTION_IN_PRODUCTS:
      return {
        ...state,
        getAllLoadingSection_In_Products: false,
        getAllSuccessSection_In_Products: action.payload || {},
        getAllFailedSection_In_Products: null
      }
    case GETALLFAILEDSECTION_IN_PRODUCTS:
      return {
        ...state,
        getAllLoadingSection_In_Products: false,
        getAllSuccessSection_In_Products: null,
        getAllFailedSection_In_Products: action.payload || ''
      }

    case SECTION_RESETALL:
      return {
        getAllLoadingSection: false,
        getAllSuccessSection: null,
        getAllFailedSection: null,

        deleteAllLoadingSection: false,
        deleteAllSuccessSection: null,
        deleteAllFailedSection: null,

        getLoadingSection: false,
        getSuccessSection: null,
        getFailedSection: null,

        saveLoadingSection: false,
        saveSuccessSection: null,
        saveFailedSection: null,

        updateLoadingSection: false,
        updateSuccessSection: null,
        updateFailedSection: null,

        deleteLoadingSection: false,
        deleteSuccessSection: null,
        deleteFailedSection: null
      }
    case SECTION_RESET:
      return {
        ...state,
        getLoadingSection: false,
        getSuccessSection: null,
        getFailedSection: null,

        saveLoadingSection: false,
        saveSuccessSection: null,
        saveFailedSection: null,

        updateLoadingSection: false,
        updateSuccessSection: null,
        updateFailedSection: null,

        deleteLoadingSection: false,
        deleteSuccessSection: null,
        deleteFailedSection: null
      }

    case GETALLLOADINGSECTION:
      return {
        ...state,
        getAllLoadingSection: true,
        getAllSuccessSection: null,
        getAllFailedSection: null
      }
    case GETALLSUCCESSSECTION:
      return {
        ...state,
        getAllLoadingSection: false,
        getAllSuccessSection: action.payload || {},
        getAllFailedSection: null
      }
    case GETALLFAILEDSECTION:
      return {
        ...state,
        getAllLoadingSection: false,
        getAllSuccessSection: null,
        getAllFailedSection: action.payload || ''
      }

    case DELETEALLLOADINGSECTION:
      return {
        ...state,
        deleteAllLoadingSection: true,
        deleteAllSuccessSection: null,
        deleteAllFailedSection: null
      }
    case DELETEALLSUCCESSSECTION:
      return {
        ...state,
        deleteAllLoadingSection: false,
        deleteAllSuccessSection: action.payload || '',
        deleteAllFailedSection: null
      }
    case DELETEALLFAILEDSECTION:
      return {
        ...state,
        deleteAllLoadingSection: false,
        deleteAllSuccessSection: null,
        deleteAllFailedSection: action.payload || ''
      }

    case GETLOADINGSECTION:
      return {
        ...state,
        getLoadingSection: true,
        getSuccessSection: null,
        getFailedSection: null
      }
    case GETSUCCESSSECTION:
      return {
        ...state,
        getLoadingSection: false,
        getSuccessSection: action.payload || {},
        getFailedSection: null
      }
    case GETFAILEDSECTION:
      return {
        ...state,
        getLoadingSection: false,
        getSuccessSection: null,
        getFailedSection: action.payload || ''
      }

    case SAVELOADINGSECTION:
      return {
        ...state,
        saveLoadingSection: true,
        saveSuccessSection: null,
        saveFailedSection: null
      }
    case SAVESUCCESSSECTION:
      return {
        ...state,
        saveLoadingSection: false,
        saveSuccessSection: action.payload || '',
        saveFailedSection: null
      }
    case SAVEFAILEDSECTION:
      return {
        ...state,
        saveLoadingSection: false,
        saveSuccessSection: null,
        saveFailedSection: action.payload || ''
      }

    case UPDATELOADINGSECTION:
      return {
        ...state,
        updateLoadingSection: true,
        updateSuccessSection: null,
        updateFailedSection: null
      }
    case UPDATESUCCESSSECTION:
      return {
        ...state,
        updateLoadingSection: false,
        updateSuccessSection: action.payload || '',
        updateFailedSection: null
      }
    case UPDATEFAILEDSECTION:
      return {
        ...state,
        updateLoadingSection: false,
        updateSuccessSection: null,
        updateFailedSection: action.payload || ''
      }

    case DELETELOADINGSECTION:
      return {
        ...state,
        deleteLoadingSection: true,
        deleteSuccessSection: null,
        deleteFailedSection: null
      }
    case DELETESUCCESSSECTION:
      return {
        ...state,
        deleteLoadingSection: false,
        deleteSuccessSection: action.payload || '',
        deleteFailedSection: null
      }
    case DELETEFAILEDSECTION:
      return {
        ...state,
        deleteLoadingSection: false,
        deleteSuccessSection: null,
        deleteFailedSection: action.payload || ''
      }

    default:
      return state
  }
}
