import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  CardSubtitle
} from 'reactstrap'

import {
  Card,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Container,
  CardBody,
  ButtonGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Table
} from 'reactstrap'
import { VscGitMerge } from 'react-icons/vsc'
import { AiOutlineToTop } from 'react-icons/ai'

import {
  setSectionWizard,
  openSectionWizard,
  closeSectionWizard,
  getAllStoresWithSectionChecker,
  getStoresSectionsByID,
  resetStoreById,
  addNewStoreSection_Affiliator,
  runAlert
} from '../redux/config'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Img } from 'react-image'
import { FcShop } from 'react-icons/fc'
import { RiCloudLine } from 'react-icons/ri'
import { GiStarsStack } from 'react-icons/gi'
import { MdWbIncandescent } from 'react-icons/md'
import { URLIMG, STORE_MAIN_URL } from '../redux/api/api.conf'
import { Link } from 'react-router-dom'
import storeBanner from 'assets/images/storeBanner.svg'
import storeSectionBanner from 'assets/images/storeSectionBanner.svg'
import detectStoreSectionData from 'assets/images/detectStoreSectionData.svg'
import GetFailedComponent from '../components/GetFailed'
import { BsFilePlus, BsArrowRight, BsCheckAll } from 'react-icons/bs'
import { Formik } from 'formik'
import { Col, Row, Badge } from 'reactstrap'
import * as Yup from 'yup'
import { IoMdDoneAll } from 'react-icons/io'
import { BsPlusSquare } from 'react-icons/bs'
import { BiMessageSquareMinus, BiMessageSquareAdd } from 'react-icons/bi'
import logo from 'assets/images/ator.gif'

const CreateSectionWizard = props => {
  const {
    section: { sectionWizard },
    storeSection: {
      saveLoadingStoreSection,
      saveSuccessStoreSection,
      saveFailedStoreSection
    },
    stores: {
      getAllLoadingStores,
      getAllSuccessStores,
      getAllFailedStores,

      getLoadingStores,
      getSuccessStores,
      getFailedStores
    },
    setSectionWizard,
    openSectionWizard,
    closeSectionWizard,
    getAllStoresWithSectionChecker,
    getStoresSectionsByID,
    resetStoreById,
    addNewStoreSection_Affiliator,
    runAlert
  } = props

  const systemLink = store => {
    return `https://${
      store?.hostName !== '' && store?.hostName !== null
        ? store?.hostName
        : store?.subDomain + '.' + STORE_MAIN_URL
    }`
  }

  useEffect(() => {
    const { runAlert } = props

    if (saveLoadingStoreSection) {
      runAlert({ type: 'loading' })
    }
    if (saveSuccessStoreSection !== null) {
      runAlert({ type: 'success' })
    }
    if (saveFailedStoreSection !== null) {
      runAlert({ type: 'failed', msg: saveFailedStoreSection })
    }
  }, [saveLoadingStoreSection, saveSuccessStoreSection, saveFailedStoreSection])

  useEffect(() => {
    const {
      section: { sectionWizard }
    } = props
    if (sectionWizard?.section !== undefined && sectionWizard?.model === true) {
      getAllStoresWithSectionChecker({ sectionId: sectionWizard?.section })
    }
  }, [sectionWizard])

  useEffect(() => {
    if (
      sectionWizard?.currentStep === 'selectStoreSection' &&
      sectionWizard?.storeId !== null
    ) {
      resetStoreById()
      getStoresSectionsByID(sectionWizard?.storeId)
    }
  }, [sectionWizard?.currentStep])

  const [minimumCommission, setMinimumCommission] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [overprice, setOverprice] = useState(0)
  const [isPercent, setIsPercent] = useState(false)

  const valueType = system => {
    if (system?.isPercent) {
      return '%'
    }
    return 'EG'
    return `ليك ${
      store?.tsPercent === false
        ? store?.tsValue + ' ' + store?.currency
        : store?.tsValue + '%'
    } علي عمولة المسوقين المنضمين من خلالك بحد أقصي ${store?.tsOrdersCount} طلب`
  }

  return (
    <Modal
      isOpen={sectionWizard?.model === true}
      toggle={() => closeSectionWizard()}
      className='ltr pb-4 mb-5 mt-0'
    >
      <ModalBody className='border-0 p-0 rtl'>
        {(() => {
          if (sectionWizard?.currentStep === 'selectStore') {
            return (
              <Card className='rtl bg-white mb-0'>
                <CardHeader className='p-0'>
                  <Card className='bg-dark text-white mb-0'>
                    <Img
                      className='img-banner-custom'
                      style={{ maxHeight: '300px', objectFit: 'cover' }}
                      alt={`...`}
                      src={[storeBanner, logo]}
                      container={children => {
                        return children
                      }}
                      loader={
                        <SkeletonTheme
                          width='100%'
                          height='100%'
                          color='#344675'
                          highlightColor='#fff'
                        >
                          <Skeleton duration={0.5} width='100%' height={256} />
                        </SkeletonTheme>
                      }
                    />

                    <CardImgOverlay>
                      <div className='w-100 h-100 position-relative rtl'>
                        <Badge
                          color='success'
                          size='sm'
                          className='font-weight-light d-flex justify-content-start flex-row align-items-center pt-2 pb-2'
                          style={{ fontSize: '1.3em' }}
                        >
                          اختر المتجر المراد دمج القسم فيه
                        </Badge>
                        <Button
                          tag={Link}
                          to='/stores/add'
                          className='position-absolute bottom-0 right-0 animation-on-hover font-weight-light'
                          size='sm'
                          color='success'
                        >
                          <BsFilePlus size='20px' /> أضافة متجر جديد
                        </Button>
                      </div>
                    </CardImgOverlay>
                  </Card>
                </CardHeader>
                <CardBody className='px-0 py-1'>
                  <ListGroup className='rtl px-1'>
                    {(() => {
                      if (getAllLoadingStores) {
                        return (
                          <SkeletonTheme
                            color='#344675'
                            highlightColor='#fff'
                            className='d-flex align-items-center flex-row'
                          >
                            <p>
                              <Skeleton height={70} duration={1} />
                            </p>
                            <p>
                              <Skeleton height={70} duration={2} />
                            </p>
                            <p className='mb-0'>
                              <Skeleton height={70} duration={4} />
                            </p>
                          </SkeletonTheme>
                        )
                      } else if (getAllFailedStores) {
                        return (
                          <GetFailedComponent failed={getAllFailedStores} />
                        )
                      } else if (getAllSuccessStores !== null) {
                        if (getAllSuccessStores?.stores?.length === 0) {
                          return (
                            <Card tag={ListGroupItem} className=' bg-default'>
                              <CardTitle className='text-white m-0'>
                                <RiCloudLine size='20px' /> لا يوجد متاجر مسجلة
                              </CardTitle>
                            </Card>
                          )
                        } else {
                          return getAllSuccessStores?.stores?.map(store => (
                            <ListGroupItem
                              action
                              className='d-flex bg-white justify-content-between align-items-center py-1 rounded mb-1'
                              key={store?._id}
                            >
                              <div
                                className={`text-${store?.storeColor} d-flex align-items-center`}
                              >
                                <div className='d-flex py-2 align-items-center position-relative'>
                                  <div className='d-flex flex-column'>
                                    <h4 className='text-info m-0 d-flex flex-row align-items-center'>
                                      {(() => {
                                        if (
                                          store?.storeImg !== '' &&
                                          store?.storeImg !== undefined
                                        ) {
                                          return (
                                            <>
                                              <Img
                                                className='ml-2 mr-0'
                                                width='20px'
                                                height='20px'
                                                style={{ objectFit: 'contain' }}
                                                alt={`${store?.storeName}`}
                                                src={[
                                                  URLIMG +
                                                    store?.storeImg,
                                                  logo
                                                ]}
                                                container={children => children}
                                                loader={
                                                  <SkeletonTheme
                                                    width='100%'
                                                    height='100%'
                                                    color='#344675'
                                                    highlightColor='#fff'
                                                    className='d-flex align-items-center flex-row'
                                                  >
                                                    <Skeleton
                                                      duration={0.5}
                                                      width={20}
                                                      height={20}
                                                    />
                                                  </SkeletonTheme>
                                                }
                                              />
                                            </>
                                          )
                                        } else {
                                          return (
                                            <FcShop
                                              className='text-info'
                                              size='20px'
                                              className='ml-1'
                                            />
                                          )
                                        }
                                      })()}

                                      {store?.storeName}
                                    </h4>
                                    <CardSubtitle
                                      style={{ maxWidth: '180px' }}
                                      className='m-0 py-0 text-muted ellipsis-text small'
                                    >
                                      <MdWbIncandescent
                                        size='15px'
                                        className='ml-1'
                                      />
                                      {store?.storeDesc}
                                    </CardSubtitle>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {(() => {
                                  if (!store?.sectionExists) {
                                    return (
                                      <FormGroup
                                        check
                                        className='form-check-radio'
                                      >
                                        <Label className='form-check-label text-default'>
                                          <Input
                                            type='radio'
                                            name='exampleRadios'
                                            id='exampleRadios1'
                                            value='option1'
                                            onClick={() =>
                                              setSectionWizard({
                                                ...sectionWizard,
                                                storeId: store?._id,
                                                currentStep:
                                                  'selectStoreSection'
                                              })
                                            }
                                          />
                                          اختر
                                          <span className='form-check-sign'></span>
                                        </Label>
                                      </FormGroup>
                                    )
                                  } else {
                                    return (
                                      <BsCheckAll
                                        className='text-success'
                                        size='25'
                                      />
                                    )
                                  }
                                })()}
                              </div>
                            </ListGroupItem>
                          ))
                        }
                      }
                    })()}
                  </ListGroup>
                </CardBody>
              </Card>
            )
          } else if (sectionWizard?.currentStep === 'selectStoreSection') {
            return (
              <Card className='rtl bg-white mb-0'>
                <CardHeader className='p-0'>
                  <Card className='bg-dark text-white mb-0'>
                    <Img
                      className='img-banner-custom'
                      style={{ maxHeight: '300px', objectFit: 'cover' }}
                      alt={`...`}
                      src={[storeSectionBanner, logo]}
                      container={children => {
                        return children
                      }}
                      loader={
                        <SkeletonTheme
                          width='100%'
                          height='100%'
                          color='#344675'
                          highlightColor='#fff'
                        >
                          <Skeleton duration={0.5} width='100%' height={256} />
                        </SkeletonTheme>
                      }
                    />

                    {/* <CardImg className="img-banner-custom" style={{ maxHeight: "300px", objectFit: "cover" }} src={storeSectionBanner} alt="..." /> */}
                    <CardImgOverlay>
                      <div className='w-100 h-100 position-relative'>
                        <Badge
                          color='success'
                          size='sm'
                          className='font-weight-light d-flex justify-content-start flex-column'
                        >
                          <span className='d-flex justify-content-start align-items-center'>
                            اضافة قسم
                            {(() => {
                              if (
                                sectionWizard?.section?.sectionImg !== '' &&
                                sectionWizard?.section?.sectionImg !== undefined
                              ) {
                                return (
                                  <>
                                    <Img
                                      className='rounded-circle mx-1'
                                      width='25'
                                      height='25'
                                      style={{ objectFit: 'contain' }}
                                      alt={`...`}
                                      src={[
                                        URLIMG +
                                          sectionWizard?.section?.sectionIm,
                                        logo
                                      ]}
                                      container={children => {
                                        return children
                                      }}
                                      loader={
                                        <SkeletonTheme
                                          width='100%'
                                          height='100%'
                                          color='#344675'
                                          highlightColor='#fff'
                                          className='d-flex align-items-center flex-row'
                                        >
                                          <Skeleton
                                            duration={0.5}
                                            width={25}
                                            height={25}
                                          />
                                        </SkeletonTheme>
                                      }
                                    />
                                  </>
                                )
                              }
                            })()}{' '}
                            {sectionWizard?.section?.sectionName} داحل متجر
                            {(() => {
                              if (
                                getSuccessStores?.storeImg !== '' &&
                                getSuccessStores?.storeImg !== undefined
                              ) {
                                return (
                                  <>
                                    <Img
                                      className='rounded-circle ml-1'
                                      width='25'
                                      height='25'
                                      style={{ objectFit: 'contain' }}
                                      alt={`...`}
                                      src={[
                                        URLIMG +
                                          getSuccessStores?.storeImg ,
                                        logo
                                      ]}
                                      container={children => {
                                        return children
                                      }}
                                      loader={
                                        <SkeletonTheme
                                          width='100%'
                                          height='100%'
                                          color='#344675'
                                          highlightColor='#fff'
                                          className='d-flex align-items-center flex-row'
                                        >
                                          <Skeleton
                                            duration={0.5}
                                            width={25}
                                            height={25}
                                          />
                                        </SkeletonTheme>
                                      }
                                    />
                                  </>
                                )
                              }
                            })()}{' '}
                            {getSuccessStores?.storeName}
                          </span>
                        </Badge>

                        <div className='position-absolute bottom-0'>
                          <Button
                            size='sm'
                            color='warning'
                            onClick={() => {
                              openSectionWizard({ currentStep: 'selectStore' })
                              resetStoreById()
                            }}
                            className='animation-on-hover ml-1'
                          >
                            <BsArrowRight size='20px' />
                          </Button>
                          <Button
                            onClick={() =>
                              setSectionWizard({
                                ...sectionWizard,
                                storeSectionId: 'new',
                                currentStep: 'detectStoreSectionData'
                              })
                            }
                            className='animation-on-hover'
                            size='sm'
                            color='success'
                          >
                            <BsFilePlus size='20px' /> أضافة كا قسم منفصل
                          </Button>
                        </div>
                      </div>
                    </CardImgOverlay>
                  </Card>
                </CardHeader>
                {(() => {
                  if (getLoadingStores) {
                    return (
                      <CardBody className='d-flex hover-scale-rounded p-3 w-100 flex-column w-100'>
                        <SkeletonTheme
                          color='#344675'
                          highlightColor='#fff'
                          className='d-flex align-items-center flex-row'
                        >
                          <p>
                            <Skeleton height={70} duration={1} />
                          </p>
                          <p>
                            <Skeleton height={70} duration={2} />
                          </p>
                          <p className='mb-0'>
                            <Skeleton height={70} duration={4} />
                          </p>
                        </SkeletonTheme>
                      </CardBody>
                    )
                  } else if (getFailedStores !== null) {
                    return (
                      <Container className='text-warning'>
                        <GetFailedComponent failed={getFailedStores} />
                      </Container>
                    )
                  } else if (getSuccessStores !== null) {
                    return (
                      <>
                        {(() => {
                          if (
                            getSuccessStores !== null &&
                            getSuccessStores?.storesections?.length === 0
                          ) {
                            setSectionWizard({
                              ...sectionWizard,
                              storeSectionId: 'new',
                              currentStep: 'detectStoreSectionData'
                            })
                            // return (
                            //   <CardTitle className="font-weight-bold text-center">لايوجد أقسام في المتجر</CardTitle>
                            // );
                          } else {
                            return (
                              <>
                                <Card className='w-100 mb-0 mt-2 bg-white'>
                                  {/* <Label className="text-dark opacity-6 font-weight-bold"><VscGitMerge size="15px" /> دمج مع قسم موجود بالفعل</Label> */}
                                  <CardBody>
                                    <Label className='text-warning small font-weight-light d-flex justify-content-start align-items-center'>
                                      <GiStarsStack
                                        className='ml-1'
                                        size='15'
                                      />
                                      هذة الميزة تتيح لك دمج نفس القسم لأكثر من
                                      تاجر
                                    </Label>
                                    {getSuccessStores?.storesections?.map(
                                      storeSections => (
                                        <CardTitle
                                          className='d-flex align-items-center justify-content-between w-100'
                                          key={storeSections?._id}
                                        >
                                          <span
                                            className='text-right ellipsis-text text-default'
                                            title={
                                              storeSections?.mainSection
                                                ?.systemSection?.sectionName
                                            }
                                            style={{
                                              maxWidth: '300px'
                                            }}
                                          >
                                            <Img
                                              key={
                                                storeSections?.mainSection
                                                  ?.sectionConfig?._id
                                              }
                                              className='rounded-circle border-0'
                                              width='25'
                                              height='25'
                                              style={{
                                                borderWidth: '3px !important',
                                                objectFit: 'contain'
                                              }}
                                              alt={`...`}
                                              src={[
                                                URLIMG +
                                                  storeSections?.mainSection
                                                    ?.systemSection
                                                    ?.sectionImg +
                                                  '/25/25/80',
                                                logo
                                              ]}
                                              id={
                                                'CgMenuGridR' +
                                                storeSections?.mainSection
                                                  ?.systemSection?._id +
                                                storeSections?.mainSection
                                                  ?.sectionConfig?._id
                                              }
                                              container={children => {
                                                return <>{children}</>
                                              }}
                                              loader={
                                                <SkeletonTheme
                                                  width='100%'
                                                  height='100%'
                                                  color='#344675'
                                                  highlightColor='#fff'
                                                  className='d-flex align-items-center flex-row'
                                                >
                                                  <Skeleton
                                                    className='rounded-circle'
                                                    duration={0.5}
                                                    width={25}
                                                    height={25}
                                                  />
                                                </SkeletonTheme>
                                              }
                                            />{' '}
                                            {
                                              storeSections?.mainSection
                                                ?.systemSection?.sectionName
                                            }
                                            <Badge
                                              color='default'
                                              className='mb-0 mr-1'
                                            >
                                              {storeSections?.sections?.length}
                                            </Badge>
                                          </span>
                                          <span>
                                            <FormGroup
                                              check
                                              className='form-check-radio mt-0'
                                            >
                                              <Label className='form-check-label text-default'>
                                                <Input
                                                  type='radio'
                                                  onClick={() =>
                                                    setSectionWizard({
                                                      ...sectionWizard,
                                                      storeSectionId:
                                                        storeSections?._id,
                                                      currentStep:
                                                        'detectStoreSectionData'
                                                    })
                                                  }
                                                />{' '}
                                                اختر
                                                <span className='form-check-sign'></span>
                                              </Label>
                                            </FormGroup>
                                          </span>
                                        </CardTitle>
                                      )
                                    )}
                                  </CardBody>
                                </Card>
                              </>
                            )
                          }
                        })()}
                      </>
                    )
                  }
                })()}
              </Card>
            )
          } else if (sectionWizard?.currentStep === 'detectStoreSectionData') {
            return (
              <Card className='rtl bg-white mb-0'>
                <CardHeader className='p-0'>
                  <Card className='bg-dark text-white mb-0'>
                    <Img
                      className='img-banner-custom'
                      style={{ maxHeight: '300px', objectFit: 'cover' }}
                      alt={`...`}
                      src={[detectStoreSectionData, logo]}
                      container={children => {
                        return children
                      }}
                      loader={
                        <SkeletonTheme
                          width='100%'
                          height='100%'
                          color='#344675'
                          highlightColor='#fff'
                        >
                          <Skeleton duration={0.5} width='100%' height={256} />
                        </SkeletonTheme>
                      }
                    />

                    <CardImgOverlay>
                      <div className='w-100 h-100 position-relative'>
                        {(() => {
                          if (
                            sectionWizard?.currentStep ===
                              'selectStoreSection' ||
                            getSuccessStores?.storesections?.length === 0
                          ) {
                            return (
                              <Button
                                size='sm'
                                color='warning'
                                onClick={() => {
                                  openSectionWizard({
                                    currentStep: 'selectStore'
                                  })
                                  resetStoreById()
                                }}
                                className='animation-on-hover position-absolute bottom-0 right-0'
                              >
                                <BsArrowRight size='20px' />
                              </Button>
                            )
                          } else if (
                            sectionWizard?.currentStep ===
                              'detectStoreSectionData' &&
                            getSuccessStores?.storesections?.length !== 0
                          ) {
                            return (
                              <Button
                                size='sm'
                                color='warning'
                                onClick={() =>
                                  openSectionWizard({
                                    currentStep: 'selectStoreSection'
                                  })
                                }
                                className='animation-on-hover position-absolute bottom-0 right-0 '
                              >
                                <BsArrowRight size='20px' />
                              </Button>
                            )
                          }
                        })()}
                      </div>
                    </CardImgOverlay>
                  </Card>
                </CardHeader>
                <CardBody>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      minimumCommission,
                      discount,
                      overprice
                    }}
                    validationSchema={Yup.object().shape({
                      minimumCommission: Yup.number(),
                      discount: Yup.number(),
                      overprice: Yup.number()
                    })}
                    onSubmit={() => {
                      addNewStoreSection_Affiliator({
                        minimumCommission,
                        overprice,
                        discount,
                        isPercent,
                        sectionId: sectionWizard?.section,
                        storeId: sectionWizard?.storeId,
                        storeSectionId: sectionWizard?.storeSectionId,
                        brandId: sectionWizard?.brandId
                      })
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Row className='no-gutters rtl'>
                          <Col
                            sm='12'
                            className='d-flex align-items-start flex-column px-0 mb-4'
                          >
                            <Label
                              style={{ fontSize: '1.5em' }}
                              className={`text-info font-weight-bolder text-right`}
                              style={{ direction: 'rtl' }}
                            >
                              <AiOutlineToTop size='20px' /> الحد الادني للعمولة
                            </Label>
                            <Input
                              onBlur={handleBlur}
                              className='border-info text-default'
                              style={{ direction: 'rtl' }}
                              invalid={
                                errors.minimumCommission &&
                                touched.minimumCommission
                              }
                              // placeholder="مثل elmotaheda"
                              autoComplete='off'
                              type='text'
                              name='discount'
                              onChange={e => {
                                setMinimumCommission(
                                  Number(
                                    e.target.value.replace(/[^0-9\.]+/g, '')
                                  )
                                )
                              }}
                              value={values.minimumCommission}
                            />
                            {errors.minimumCommission &&
                            touched.minimumCommission ? (
                              <Badge
                                className='my-1 text-warning'
                                color='neutral-warning'
                              >
                                {errors.minimumCommission}
                              </Badge>
                            ) : null}
                          </Col>
                          <Col
                            sm='12'
                            className='d-flex align-items-start flex-column px-0 mb-4'
                          >
                            <Label
                              style={{ fontSize: '1.5em' }}
                              className={`text-info font-weight-bolder text-right`}
                              style={{ direction: 'rtl' }}
                            >
                              <BiMessageSquareMinus size='20px' /> خصم من
                              العمولة
                            </Label>
                            <Input
                              onBlur={handleBlur}
                              className='border-info text-default'
                              style={{ direction: 'rtl' }}
                              invalid={errors.discount && touched.discount}
                              // placeholder="مثل elmotaheda"
                              autoComplete='off'
                              type='text'
                              name='discount'
                              onChange={e => {
                                setDiscount(
                                  Number(
                                    e.target.value.replace(/[^0-9\.]+/g, '')
                                  )
                                )
                              }}
                              value={values.discount}
                            />
                            {errors.discount && touched.discount ? (
                              <Badge
                                className='my-1 text-warning'
                                color='neutral-warning'
                              >
                                {errors.discount}
                              </Badge>
                            ) : null}
                          </Col>

                          <Col
                            sm='12'
                            className='d-flex align-items-start flex-column px-0'
                          >
                            <Label
                              style={{ fontSize: '1.5em' }}
                              className={`text-info font-weight-bolder text-right`}
                              style={{ direction: 'rtl' }}
                            >
                              <BiMessageSquareAdd size='20px' /> الزيادة في
                              السعر
                            </Label>
                            <Input
                              onBlur={handleBlur}
                              className='border-info text-default'
                              style={{ direction: 'rtl' }}
                              invalid={errors.overprice && touched.overprice}
                              // placeholder="مثل elmotaheda"
                              autoComplete='off'
                              type='text'
                              name='overprice'
                              onChange={e => {
                                setOverprice(
                                  Number(
                                    e.target.value.replace(/[^0-9\.]+/g, '')
                                  )
                                )
                              }}
                              value={values.overprice}
                            />
                            {errors.overprice && touched.overprice ? (
                              <Badge
                                className='my-1 text-warning'
                                color='neutral-warning'
                              >
                                {errors.overprice}
                              </Badge>
                            ) : null}
                          </Col>
                          <Col
                            sm='12'
                            className='d-flex align-items-start flex-column px-0'
                          >
                            <ButtonGroup>
                              <Button
                                color='info'
                                size='sm'
                                onClick={() => setIsPercent(false)}
                                type='button'
                              >
                                {(() => {
                                  if (!isPercent) {
                                    return <IoMdDoneAll size='20px' />
                                  }
                                })()}{' '}
                                عملة التاجر
                              </Button>
                              <Button
                                color='info'
                                size='sm'
                                active={isPercent}
                                onClick={() => setIsPercent(true)}
                                type='button'
                              >
                                {(() => {
                                  if (isPercent) {
                                    return <IoMdDoneAll size='20px' />
                                  }
                                })()}{' '}
                                نسبة مئوية %
                              </Button>
                            </ButtonGroup>
                          </Col>
                          <Col
                            sm='6'
                            className='d-flex align-items-start flex-column px-0'
                          >
                            <Button
                              color='success'
                              className='animation-on-hover'
                              size='sm'
                              type='submit'
                              disabled={
                                !(
                                  isValid &&
                                  Object.keys(touched).length !== 0 &&
                                  touched.constructor === Object
                                )
                              }
                            >
                              <span className='btn-wrapper--icon font-weight-bolder d-flex flex-row align-items-center'>
                                <BsFilePlus />
                                <span className='btn-wrapper--label mr-1'>
                                  أضافة
                                </span>
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            )
          }
        })()}
      </ModalBody>
    </Modal>
  )
}

export default connect(state => state, {
  setSectionWizard,
  openSectionWizard,
  closeSectionWizard,
  getAllStoresWithSectionChecker,
  getStoresSectionsByID,
  resetStoreById,
  addNewStoreSection_Affiliator,
  runAlert
})(CreateSectionWizard)
