import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse } from "reactstrap";

var ps;

const Sidebar = (props) => {
  const [state, setState] = React.useState({});
  const sidebarRef = React.useRef(null);
  const location = useLocation();
  // React.useEffect(() => {
  //   setState(getCollapseStates(props.routes));
  // }, [props, getCollapseStates]);
  // React.useEffect(() => {
  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebarRef.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   return function cleanup() {
  //     // we need to destroy the false scrollbar when we navigate
  //     // to a page that doesn't have this component rendered
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    // const { rtlActive } = props;
    const rtlActive = true;
    return routes.map((prop, key) => {
      if (prop.isHidden) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <li className={getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <span className="d-flex">
                  <span>{prop.icon} </span>
                  <p className="mr-2 font-weight-bold font-size-xxl">{rtlActive ? prop.rtlName : prop.name}</p>
                  {/* <b className="caret" /> */}
                </span>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{rtlActive ? prop.rtlMini : prop.mini}</span>
                  <span className="sidebar-normal">
                    {rtlActive ? prop.rtlName : prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[prop.state]}>
              <ul className="nav pr-0">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.path)} key={key}>
          <NavLink to={prop.path} activeClassName="" onClick={props.closeSidebar}>
            {prop.icon !== undefined ? (
              <span className="d-flex">
                <span>{prop.icon} </span>
                <p className="mr-2 font-weight-bold font-size-xxl">{rtlActive ? prop.rtlName : prop.name}</p>
              </span>
            ) : (
              <>
                <span className="sidebar-mini-icon">{rtlActive ? prop.rtlMini : prop.mini}</span>
                <span className="sidebar-normal">{rtlActive ? prop.rtlName : prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const { activeColor, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          rel="noreferrer"
          href={logo.outterLink}
          className="w-100 h-100 d-flex p-0 text-white align-items-center"
          target="_blank"
          onClick={props.closeSidebar}
        >
          <span className="simple-text logo-normal">منصة</span>
          <img
            src={logo.imgSrc}
            title={logo.text}
            alt={logo.text}
            className=" mr-1 mb-1"
            style={{ objectFit: "contain", height: "15px", borderRadius: "0px" }}
          />
        </a>
      );
      logoText = <a rel="noreferrer" href={logo.outterLink} className="simple-text logo-normal" target="_blank" onClick={props.closeSidebar}></a>;
    } else {
      logoImg = (
        <NavLink to={logo.innerLink} className="simple-text logo-mini" onClick={props.closeSidebar}>
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </NavLink>
      );
      logoText = (
        <NavLink to={logo.innerLink} className="simple-text logo-normal" onClick={props.closeSidebar}>
          {logo.text}
        </NavLink>
      );
    }
  }
  return (
    <div className="sidebar h-100" data={activeColor}>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        {/* {logoImg !== null || logoText !== null ? (
          <div className='logo py-2 px-4'>
            {logoImg}
          </div>
        ) : null} */}
        <Nav className="p-0 pr-2">{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
};
export default Sidebar;
