import { combineReducers } from "redux";

import auth from "../config/authentication.redux";
import stores from "../config/stores.redux";
import storeSection from "../config/storeSection.redux";
import section from "../config/section.redux";
import systems from "../config/systems.redux";

export default combineReducers({
  auth,
  stores,
  storeSection,
  section,
  systems
});
