import React from "react";
import { Card, CardImgOverlay,Button } from "reactstrap";
import { Img } from 'react-image'
import SystemList from 'assets/images/SystemList.svg'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { GiRibbonMedal } from 'react-icons/gi'
import { TiPlusOutline } from 'react-icons/ti'
import { RiUserAddLine } from 'react-icons/ri'
const NotAuth = props => {
  return (
    <Card className='bg-dark text-white'>
      <Img
        className='img-banner-custom'
        style={{ maxHeight: '300px', objectFit: 'cover' }}
        alt={`...`}
        src={[SystemList]}
        container={children => {
          return children
        }}
        loader={
          <SkeletonTheme
            width='100%'
            height='100%'
            color='#344675'
            highlightColor='#fff'
          >
            <Skeleton duration={0.5} width='100%' height={256} />
          </SkeletonTheme>
        }
      />
      <CardImgOverlay>
        <div className='w-100 h-100 position-relative'>
          <h2 className=' text-default'>
            <GiRibbonMedal size='35px' /> تصفح أقوي أنظمة التسويق بالعمولة
          </h2>
          <div className="d-flex flex-row position-absolute bottom-0 w-100 justify-content-between justify-content-md-start">
            <Button
              tag='a'
              href='https://affilih.com'
              target='_blanck'
              className='animation-on-hover btn-sm-block'
              color='success'
            >
              <TiPlusOutline size='25px' /> <span className="text-nowrap">أنشئ نظامك</span>
            </Button>
            {(() => {
              if (props?.affiliator === null) {
                return (
                  <Button
                    tag='a'
                    href='https://affilih.com/affiliator'
                    target='_blanck'
                    className='animation-on-hover btn-sm-block mr-1'
                    color='info'
                  >
                    <RiUserAddLine size='25px' /> <span className="text-nowrap">انضم كمسوق</span>
                  </Button>
                );
              }
            })()}
          </div>
        </div>
      </CardImgOverlay>
    </Card>
  );
}
export default NotAuth;
