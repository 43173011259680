import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  getAllSection,
  getStoreSectionById,
  addAndUpdateStoreSection,
  deleteStoreSectionByID
} from '../../redux/config'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Img } from 'react-image'
import ToastComponent from '../../components/Toast'

import LOGO from 'assets/images/logo.png'
import { FaStore } from 'react-icons/fa'
import { BiMerge } from 'react-icons/bi'
import { HiOutlineArrowsExpand } from 'react-icons/hi'
import GetFailedComponent from '../../components/GetFailed'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
  Col,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Input,
  Container,
  ListGroupItem,
  CardSubtitle,
  ListGroup,
  Badge,
  Collapse
} from 'reactstrap'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap'

import { CgArrowRightR } from 'react-icons/cg'
import { AiFillSetting } from 'react-icons/ai'
import { TiThMenuOutline } from 'react-icons/ti'

import { URLIMG, STORE_MAIN_URL } from '../../redux/api/api.conf'

import { CgMenuGridR } from 'react-icons/cg'
import { TiThList } from 'react-icons/ti'
import { AiOutlineLink } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import { HiDuplicate } from 'react-icons/hi'
import { FcShop, FcAdvertising } from 'react-icons/fc'
import { RiCloudLine } from 'react-icons/ri'
import { MdWbIncandescent } from 'react-icons/md'
import { BsFilePlus, BsArrowsExpand } from 'react-icons/bs'
import InterActive from 'components/InterActive'
import { bounceInDown } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
// import CreateSectionWizard from 'components/CreateSectionWizard.js'
import { RiBarChartLine } from 'react-icons/ri'
import { GiMailShirt, GiFamilyTree, GiTakeMyMoney } from 'react-icons/gi'
import { GiExitDoor, GiDoor } from 'react-icons/gi'
import { CgTimer } from 'react-icons/cg'
import {
  getSystemSectionsById,
  getBestCommissions,
  openSectionWizard,
  registerInSystem_FN,
  registerInSystem_FN_Merge,
  runAlert,
  runInterActive,
  reset_registerInSystem_FN
} from '../../redux/config'
import notExistsYet from 'assets/images/notExistsYet.svg'

const systemLink = store => {
  return `https://${
    store?.hostName !== '' && store?.hostName !== null
      ? store?.hostName
      : store?.subDomain + '.' + STORE_MAIN_URL
  }`
}

const SystemSections = props => {
  const {
    section: {
      getLoadingSection,
      getSuccessSection,
      getFailedSection,

      getAllLoadingSection,
      getAllSuccessSection,
      getAllFailedSection
    },
    storeSection: {
      getLoadingStoreSection,
      getSuccessStoreSection,
      // getFailedStoreSection,

      saveLoadingStoreSection,
      saveSuccessStoreSection,
      saveFailedStoreSection,

      deleteLoadingStoreSection,
      deleteSuccessStoreSection,
      deleteFailedStoreSection
    },
    stores: {
      getLoadingStores,
      getSuccessStores,
      getFailedStores,

      getAllLoadingStores,
      getAllSuccessStores,
      getAllFailedStores
    }
  } = props

  const {
    auth: { affiliator, interActive, store }
  } = props
  const { systems } = props
  const brandId = props.match.params.brandId

  const {
    getBestCommissions,
    openSectionWizard,
    registerInSystem_FN,
    registerInSystem_FN_Merge,
    runAlert,
    runInterActive,
    reset_registerInSystem_FN,
    getSystemSectionsById
  } = props
  const [fetchingDone, setFetchingDone] = useState(false)

  useEffect(() => {
    props.getSystemSectionsById(brandId)
    setFetchingDone(true)
  }, [])

  useEffect(() => {
    if (systems?.registerInSystem?.loading === true) {
      runInterActive({ type: 'loading' })
      // runAlert({type:"loading"});
    }
    if (systems?.registerInSystem?.success !== undefined) {
      runInterActive({ type: 'success' })
      // runAlert({type:"success", msg: systems?.registerInSystem?.success});
    }
    if (systems?.registerInSystem?.failed !== undefined) {
      runInterActive({ type: 'failed' })
      runAlert({ type: 'failed', msg: systems?.registerInSystem?.failed })
    }
  }, [systems])

  return (
    <div className='content'>
      {/* <CreateSectionWizard /> */}
      {saveSuccessStoreSection !== null ? (
        <ToastComponent Body={saveSuccessStoreSection} icon={`success`} />
      ) : null}
      {deleteSuccessStoreSection !== null ? (
        <ToastComponent Body={deleteSuccessStoreSection} icon={`success`} />
      ) : null}
      {deleteFailedStoreSection !== null ? (
        <ToastComponent Body={deleteFailedStoreSection} icon={`danger`} />
      ) : null}
      {saveFailedStoreSection !== null ? (
        <ToastComponent Body={saveFailedStoreSection} icon={`danger`} />
      ) : null}
      {getFailedSection !== null ? (
        <ToastComponent Body={getFailedSection} icon={`danger`} />
      ) : null}
      {getFailedStores !== null ? (
        <ToastComponent Body={getFailedStores} icon={`danger`} />
      ) : null}
      <Card className='mb-0'>
        <CardBody>
          {(() => {
            if (getAllLoadingSection || getLoadingSection) {
              return (
                <CardBody>
                  <SkeletonTheme color='#344675' highlightColor='#fff'>
                    <ListGroup>
                      <ListGroupItem className='py-3 bg-default border-default w-100'>
                        <Skeleton duration={2} height={25} />
                      </ListGroupItem>
                      <ListGroupItem className='py-3 bg-default border-default w-100'>
                        <Skeleton duration={1} height={25} />
                      </ListGroupItem>
                      <ListGroupItem className='py-3 bg-default border-default w-100'>
                        <Skeleton duration={2} height={25} />
                      </ListGroupItem>
                      <ListGroupItem className='py-3 bg-default border-default w-100'>
                        <Skeleton duration={1} height={25} />
                      </ListGroupItem>
                    </ListGroup>
                  </SkeletonTheme>
                </CardBody>
              )
            } else if (getAllSuccessSection !== null && fetchingDone) {
              return (
                <Card className='mb-0'>
                  <div
                    aria-multiselectable={true}
                    className='card-collapse'
                    id='accordion'
                    role='tablist'
                  >
                    {(() => {
                      if (getAllSuccessSection?.sections?.length === 0) {
                        return (
                          <CardBody className='row d-flex align-items-center'>
                            <p className='px-0 mb-0 text-justify col-12 col-md-6 mb-4 mb-md-0 px-0 px-md-3'>
                              لا توجد لهذا النظام أقسام مؤهلة للدمج في متجرك حتى الآن، جرب نظامًا آخر{' '}
                            </p>
                            <span className='col-12 col-md-6'>
                              <img
                                className=' w-100'
                                src={notExistsYet}
                                style={{
                                  maxHeight: '120px'
                                }}
                              />
                            </span>
                          </CardBody>
                        )
                      } else {
                        return getAllSuccessSection?.sections?.map(section => (
                          <div className='mb-3' key={section?._id?._id}>
                            <div className='font-weight-bold opacity-7 mb-3 d-flex flex-row justify-content-between'>
                              <span className='d-flex flex-row align-items-center text-white font-weight-light'>
                                <TiThMenuOutline size='20px' /> الأقسام المتاحة
                                للدمج للتاجر
                                {(() => {
                                  if (section?._id?.settingsStoreImg) {
                                    return (
                                      <Img
                                        className='rounded-circle mx-1'
                                        width='20'
                                        height='20'
                                        style={{ objectFit: 'contain' }}
                                        alt={`${section?._id?.settingsStoreName}`}
                                        src={[
                                          URLIMG +
                                            section?._id?.settingsStoreImg
                                        ]}
                                        container={children => {
                                          return (
                                            <div className='overflow-hidden'>
                                              {children}
                                            </div>
                                          )
                                        }}
                                        loader={
                                          <SkeletonTheme
                                            width='100%'
                                            height='100%'
                                            color='#f0f0f0'
                                            highlightColor='#fff'
                                          >
                                            <SkeletonTheme
                                              duration={0.5}
                                              width={20}
                                              height={20}
                                            />
                                          </SkeletonTheme>
                                        }
                                      />
                                    )
                                  } else {
                                    return (
                                      <FaStore size={20} className='ml-1' />
                                    )
                                  }
                                })()}
                                {section?._id?.settingsStoreName}
                              </span>
                              {(() => {
                                if (
                                  interActive?.key ===
                                  'joinUs' + section?._id?._id
                                ) {
                                  return (
                                    <InterActive
                                      className='overflow-hidden h-100 btn-simple border-0 animation-on-hover btn-behance btn-sm d-flex align-items-center justify-content-center'
                                      onEndFN={reset_registerInSystem_FN}
                                    />
                                  )
                                } else {
                                  return (
                                    <>
                                      {(() => {
                                        if (!section?._id?.isJoined) {
                                          return (
                                            <>
                                              <Button
                                                className='btn-simple border-0 animation-on-hover'
                                                id={
                                                  'jointSystemsystem' +
                                                  section?._id?._id
                                                }
                                                style={{}}
                                                size='sm'
                                                color='success'
                                                onClick={() => {
                                                  props.registerInSystem_FN_Merge(
                                                    section?._id?._id,
                                                    section?._id?._id
                                                  )
                                                  props.runInterActive({
                                                    key:
                                                      'joinUs' +
                                                      section?._id?._id
                                                  })
                                                }}
                                              >
                                                <GiExitDoor size='25' />
                                              </Button>
                                              {/* <UncontrolledPopover
                                              trigger='hover'
                                              placement='top'
                                              target={
                                                'jointSystemsystem' +
                                                section?._id?._id
                                              }
                                            >
                                              <PopoverHeader>
                                                كن مسوق معنا
                                              </PopoverHeader>
                                            </UncontrolledPopover> */}
                                            </>
                                          )
                                        } else if (section?._id?.isPending) {
                                          return (
                                            <>
                                              <Button
                                                className='btn-simple border-0 animation-on-hover'
                                                size='sm'
                                                color='warning'
                                                id={
                                                  'waitingsystem' +
                                                  section?._id?._id
                                                }
                                              >
                                                <CgTimer size='30' />
                                              </Button>
                                              {/* <UncontrolledPopover
                                              trigger='hover'
                                              placement='top'
                                              target={
                                                'waitingsystem' + section?._id?._id
                                              }
                                            >
                                              <PopoverHeader>
                                                ننتظر موافقة التاجر
                                              </PopoverHeader>
                                            </UncontrolledPopover> */}
                                            </>
                                          )
                                        }
                                      })()}
                                    </>
                                  )
                                }
                              })()}

                              {/* <Badge color='info'>{section?.count}</Badge> */}
                            </div>
                            <ListGroup>
                              {section?.sections?.map(sec => (
                                <ListGroupItem
                                  key={sec?._id}
                                  className='d-flex justify-content-between align-items-center py-3 bg-transparent border-default'
                                >
                                  <div className='d-flex align-items-center ml-4'>
                                    <div>
                                      <div className='font-weight-bold d-flex flex-row mb-2'>
                                        {(() => {
                                          if (sec?.sectionImg) {
                                            return (
                                              <Img
                                                className='rounded-circle ml-1'
                                                width='20'
                                                height='20'
                                                style={{ objectFit: 'contain' }}
                                                alt={`${sec?.sectionName}`}
                                                src={[URLIMG + sec?.sectionImg]}
                                                container={children => {
                                                  return (
                                                    <div className='overflow-hidden'>
                                                      {children}
                                                    </div>
                                                  )
                                                }}
                                                loader={
                                                  <SkeletonTheme
                                                    width='100%'
                                                    height='100%'
                                                    color='#f0f0f0'
                                                    highlightColor='#fff'
                                                  >
                                                    <Skeleton
                                                      duration={0.5}
                                                      width={20}
                                                      height={20}
                                                    />
                                                  </SkeletonTheme>
                                                }
                                              />
                                            )
                                          } else {
                                            return <FaStore size={20} />
                                          }
                                        })()}
                                        {sec?.sectionName}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='d-flex align-items-center'>
                                    <StyleRoot className='h-100 overflow-hidden '>
                                      <div
                                        className='d-flex align-items-center justify-content-center h-100'
                                        style={{
                                          animation: 'x 1s',
                                          animationName: Radium.keyframes(
                                            bounceInDown,
                                            'bounceInDown'
                                          )
                                        }}
                                      >
                                        {(() => {
                                          if (section?._id?.isJoined) {
                                            return (
                                              <>
                                                <Button
                                                  size='sm'
                                                  className='btn-simple border-0 animation-on-hover'
                                                  onClick={() =>
                                                    openSectionWizard({
                                                      section: sec?._id,
                                                      brandId:
                                                        section?._id?._id,
                                                      currentStep: 'selectStore'
                                                    })
                                                  }
                                                  color='default'
                                                  id={
                                                    'mergeSectionsystem' +
                                                    sec?._id
                                                  }
                                                >
                                                  <FcShop size='25' />
                                                </Button>
                                                {/* <UncontrolledPopover
                                                trigger='hover'
                                                placement='top'
                                                target={
                                                  'mergeSectionsystem' + sec?._id
                                                }
                                              >
                                                <PopoverHeader>
                                                  دمج القسم مع متجرك
                                                </PopoverHeader>
                                              </UncontrolledPopover> */}
                                              </>
                                            )
                                          }
                                        })()}
                                      </div>
                                    </StyleRoot>
                                  </div>
                                </ListGroupItem>
                              ))}
                            </ListGroup>
                          </div>
                        ))
                      }
                    })()}
                  </div>
                </Card>
              )
            } else if (getAllFailedSection !== null) {
              return (
                <Container className='text-warning'>
                  <GetFailedComponent failed={getAllFailedSection} />
                </Container>
              )
            }
          })()}
        </CardBody>
      </Card>
    </div>
  )
}

export default connect(state => state, {
  getSystemSectionsById,
  getAllSection,
  getStoreSectionById,
  addAndUpdateStoreSection,
  deleteStoreSectionByID,

  getBestCommissions,
  openSectionWizard,
  registerInSystem_FN,
  registerInSystem_FN_Merge,
  runAlert,
  runInterActive,
  reset_registerInSystem_FN
})(SystemSections)
