/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react'
import { Container, Row, Button } from 'reactstrap'
// used for making the prop types of this component
import PropTypes from 'prop-types'
import { BsBoxArrowDown } from 'react-icons/bs'
import { usePwa } from 'react-pwa-app'
import store from '../redux/store'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Img } from 'react-image'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

const Footer = props => {
  let pwa = usePwa()

  return (
    <footer
      className={'footer py-3 ' + (props.default ? ' footer-default' : '')}
    >
      <Container fluid={props.fluid ? true : false}>
        <ul className='nav'>
          <li className='nav-item'>
            <Link className='nav-link' to='/profile'>
              {(() => {
                if (store.getState()?.auth?.affiliator?.state !== undefined) {
                  return (
                    <img
                      className='rounded mr-1'
                      style={{ maxWidth: '20px' }}
                      src={findFlagUrlByIso2Code(
                        store.getState()?.auth?.affiliator?.state
                      )}
                    />
                  )
                }
              })()}
            </Link>
          </li>
          {(() => {
            if (pwa.isInstalled !== 'standalone' && pwa.supports) {
              return (
                <li className='nav-item'>
                  <Button
                    className='btn-simple py-0 d-flex flex-row align-items-center border-0 px-1'
                    color='facebook'
                    onClick={pwa.install}
                  >
                    <span className='nav-link'>
                      <BsBoxArrowDown size='16px' /> تحميل التطبيق
                    </span>
                  </Button>
                </li>
              )
            }
          })()}
          <li className='nav-item'>
            <a className='nav-link' href='https://affilih.com'>
              منصة التجار
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              target='_blank'
              href='https://affilih.website/blog/%d9%85%d8%b9%d9%84%d9%88%d9%85%d8%a7%d8%aa-%d8%b9%d9%86-%d9%85%d9%86%d8%b5%d8%a9-affilih/'
            >
              عنا
            </a>
          </li>{' '}
          <li className='nav-item'>
            <a
              className='nav-link'
              target='_blank'
              href='https://affilih.website/blog/'
            >
              المدونة
            </a>
          </li>
        </ul>
        <div className='copyright'>
          © {new Date().getFullYear()} منصة المسوقين واحدة من منصات{' '}
          <a href='https://affilih.com/' target='_blank'>
            Affilih
          </a>{' '}
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
}

export default Footer
