import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import { Edit2, Check, X, User } from "react-feather";
import { VscSymbolColor } from "react-icons/vsc";

export default function StoreImg(props) {
  const [files, setFiles] = useState([]);
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      props.handleProfileImg(acceptedFiles);
    },
  });

  const thumbs = files.map((file) => (
    <div
      key={file.name}
      className="rounded avatar-image overflow-hidden text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
    >
      <img
        className="img-fluid img-fit-container rounded-sm"
        src={file.preview}
        alt="..."
      />
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <>
      <div className="py-4 d-flex align-items-center">
        <div className="dropzone rounded-circle shadow-sm bg-info d-flex align-items-center justify-content-center position-relative" style={{ width: "120px", height: "120px" }}>
          <Button
            color="default"
            onClick={open}
            size="sm"
            style={{ marginTop: "-10px", marginLeft: "-10px" }}
            className=" position-absolute bottom-0 right-0 p-2"
          >
            <Edit2 />
          </Button>

          <div {...getRootProps({ className: "dropzone-upload-wrapper" })}>
            <input {...getInputProps()} />
            <div className="dropzone-inner-wrapper d-100 rounded-circle dropzone-avatar h-100">
              <div className="d-flex align-items-center justify-content-center h-100 w-100">
              </div>
              {(() => {
                if (thumbs.length > 0) {
                  return (<div>{thumbs}</div>);
                } else {
                  return (
                    <>
                      {isDragAccept && (
                        <div className="rounded-circle overflow-hidden d-100 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <Check className="d-30" />
                        </div>
                      )}
                      {isDragReject && (
                        <div className="rounded-circle overflow-hidden d-100 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <X className="d-40" />
                        </div>
                      )}
                      {!isDragActive && (
                        <div className=" d-flex justify-content-center align-items-center text-white">
                          <VscSymbolColor size="40px" />
                        </div>
                      )}
                    </>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
