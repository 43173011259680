
import {
  apiGetAllStoreSection,
  apiGetAllStoreSectionByStoreId,
  apiDeleteAllStoreSection,
  apiGetStoreSectionByID,
  apiSaveStoreSection,
  apiAddNewStoreSection_Affiliator,
  apiUpdateStoreSectionByID,
  apiSetSectionActiveInStoreSection,
  apiDeleteStoreSectionByID,
  apiDeleteStoreByBrandId,
  apiDeleteSection_StoreSectionByID,
} from "../api/";

import { HandelMyError } from "../store/Redux.Hander.js";
import { getStoresSectionsByID } from "../config";

const STORESECTION_RESETALL = "GETALLSUCCESSSTORESECTION_RESETALL"

const GETALLLOADINGSTORESECTION = "GETALLLOADINGSTORESECTION"
const GETALLSUCCESSSTORESECTION = "GETALLSUCCESSSTORESECTION"
const GETALLFAILEDSTORESECTION = "GETALLFAILEDSTORESECTION"

const DELETEALLLOADINGSTORESECTION = "DELETEALLLOADINGSTORESECTION"
const DELETEALLSUCCESSSTORESECTION = "DELETEALLSUCCESSSTORESECTION"
const DELETEALLFAILEDSTORESECTION = "DELETEALLFAILEDSTORESECTION"

const GETLOADINGSTORESECTION = "GETLOADINGSTORESECTION"
const GETSUCCESSSTORESECTION = "GETSUCCESSSTORESECTION"
const GETFAILEDSTORESECTION = "GETFAILEDSTORESECTION"

const SAVELOADINGSTORESECTION = "SAVELOADINGSTORESECTION"
const SAVESUCCESSSTORESECTION = "SAVESUCCESSSTORESECTION"
const SAVEFAILEDSTORESECTION = "SAVEFAILEDSTORESECTION"

const UPDATELOADINGSTORESECTION = "UPDATELOADINGSTORESECTION"
const UPDATESUCCESSSTORESECTION = "UPDATESUCCESSSTORESECTION"
const UPDATEFAILEDSTORESECTION = "UPDATEFAILEDSTORESECTION"

const DELETELOADINGSTORESECTION = "DELETELOADINGSTORESECTION"
const DELETESUCCESSSTORESECTION = "DELETESUCCESSSTORESECTION"
const DELETEFAILEDSTORESECTION = "DELETEFAILEDSTORESECTION"

const SECTIONWIZARD = "SECTIONWIZARD";

export const resetAllStoreSection = () => {
  return (dispatch) => {
    dispatch({ type: STORESECTION_RESETALL });
  };
};

export const getAllStoreSection = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORESECTION_RESETALL });
      dispatch({ type: GETALLLOADINGSTORESECTION });
      const { data: { storeSections } } = await apiGetAllStoreSection();
      dispatch({ type: GETALLSUCCESSSTORESECTION, payload: { storeSections } });
    } catch (e) {
      dispatch({ type: GETALLFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const getAllStoreSectionByStoreId = (storeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORESECTION_RESETALL });
      dispatch({ type: GETALLLOADINGSTORESECTION });
      const { data: { storeSections } } = await apiGetAllStoreSectionByStoreId(storeId);
      dispatch({ type: GETALLSUCCESSSTORESECTION, payload: { storeSections } });
    } catch (e) {
      dispatch({ type: GETALLFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};
export const deleteAllStoreSection = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORESECTION_RESETALL });
      dispatch({ type: DELETEALLLOADINGSTORESECTION });
      const { data: { success } } = await apiDeleteAllStoreSection();
      dispatch({ type: DELETEALLSUCCESSSTORESECTION, payload: success });
    } catch (e) {
      dispatch({ type: DELETEALLFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const getStoreSectionBySectionId = (sectionId, storeId) => {
  return async (dispatch) => {
    try {
      dispatch(resetAllStoreSection());
      dispatch({ type: GETLOADINGSTORESECTION });
      const { data: { storeSection } } = await apiGetStoreSectionByID(sectionId, storeId);
      dispatch({ type: GETSUCCESSSTORESECTION, payload: { storeSection } });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const getStoreSectionByID = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETLOADINGSTORESECTION });
      const { data: { storeSection } } = await apiGetStoreSectionByID(id);
      dispatch({ type: GETSUCCESSSTORESECTION, payload: { storeSection } });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};
export const addAndUpdateStoreSection = (storeSection) => {
  let { sectionId, storeId } = storeSection
  return async (dispatch) => {
    try {
      dispatch({ type: SAVELOADINGSTORESECTION });
      const { data: { success } } = await apiSaveStoreSection(storeSection);
      dispatch({ type: SAVESUCCESSSTORESECTION, payload: success });
      dispatch(getStoreSectionBySectionId(sectionId, storeId));
    } catch (e) {
      dispatch({ type: SAVEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const addNewStoreSection_Affiliator = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SECTIONWIZARD, payload: { model: false } });
      dispatch({ type: SAVELOADINGSTORESECTION });
      const { data: { success } } = await apiAddNewStoreSection_Affiliator(data);
      dispatch({ type: SAVESUCCESSSTORESECTION, payload: success });
    } catch (e) {
      dispatch({ type: SAVEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const saveStoreSection = (storeSection) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVELOADINGSTORESECTION });
      const { data: { success } } = await apiSaveStoreSection(storeSection);
      dispatch({ type: SAVESUCCESSSTORESECTION, payload: success });
    } catch (e) {
      dispatch({ type: SAVEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGSTORESECTION });
      const { data: { storeSection } } = await apiGetStoreSectionByID();
      dispatch({ type: GETSUCCESSSTORESECTION, payload: { storeSection } });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const setSectionActiveInStoreSection = (sectionId, storeSectionId, storeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSTORESECTION });
      const { data: { success } } = await apiSetSectionActiveInStoreSection(sectionId, storeSectionId);
      dispatch({ type: UPDATESUCCESSSTORESECTION, payload: success });
      dispatch(getStoresSectionsByID(storeId));
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const updateStoreSectionByID = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSTORESECTION });
      const { data: { success } } = await apiUpdateStoreSectionByID(id, data);
      dispatch({ type: UPDATESUCCESSSTORESECTION, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGSTORESECTION });
      const { data: { storeSection } } = await apiGetStoreSectionByID(id);
      dispatch({ type: GETSUCCESSSTORESECTION, payload: { storeSection } });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const deleteStoreSectionByID = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETELOADINGSTORESECTION });
      const { data: { success } } = await apiDeleteStoreSectionByID(data);
      dispatch({ type: DELETESUCCESSSTORESECTION, payload: success });
      dispatch({ type: STORESECTION_RESETALL });
    } catch (e) {
      dispatch({ type: DELETEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const deleteStoreSectionByInStoreId = (data) => {
  let { storeId } = data;
  return async (dispatch) => {
    try {
      dispatch({ type: DELETELOADINGSTORESECTION });
      const { data: { success } } = await apiDeleteStoreSectionByID(data);
      dispatch(getStoresSectionsByID(storeId));
      dispatch({ type: DELETESUCCESSSTORESECTION, payload: success });
    } catch (e) {
      dispatch({ type: DELETEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const deleteStoreByInBrandId = (data) => {
  let { storeId } = data;
  return async (dispatch) => {
    try {
      dispatch({ type: DELETELOADINGSTORESECTION });
      const { data: { success } } = await apiDeleteStoreByBrandId(data);
      dispatch(getStoresSectionsByID(data?.storeId));
      dispatch({ type: DELETESUCCESSSTORESECTION, payload: success });
    } catch (e) {
      dispatch({ type: DELETEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

export const deleteSection_StoreSectionByInStoreId = (data) => {
  let { storeId } = data;
  return async (dispatch) => {
    try {
      dispatch({ type: DELETELOADINGSTORESECTION });
      const { data: { success } } = await apiDeleteSection_StoreSectionByID(data);
      dispatch(getStoresSectionsByID(storeId));
      dispatch({ type: DELETESUCCESSSTORESECTION, payload: success });
    } catch (e) {
      dispatch({ type: DELETEFAILEDSTORESECTION, payload: HandelMyError(e) });
    }
  };
};

const INITIAL_STATE = {
  getAllLoadingStoreSection: false,
  getAllSuccessStoreSection: null,
  getAllFailedStoreSection: null,

  deleteAllLoadingStoreSection: false,
  deleteAllSuccessStoreSection: null,
  deleteAllFailedStoreSection: null,

  getLoadingStoreSection: false,
  getSuccessStoreSection: null,
  getFailedStoreSection: null,

  saveLoadingStoreSection: false,
  saveSuccessStoreSection: null,
  saveFailedStoreSection: null,

  updateLoadingStoreSection: false,
  updateSuccessStoreSection: null,
  updateFailedStoreSection: null,

  deleteLoadingStoreSection: false,
  deleteSuccessStoreSection: null,
  deleteFailedStoreSection: null,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORESECTION_RESETALL: return {
      ...state,
      getAllLoadingStoreSection: false,
      getAllSuccessStoreSection: null,
      getAllFailedStoreSection: null,

      deleteAllLoadingStoreSection: false,
      deleteAllSuccessStoreSection: null,
      deleteAllFailedStoreSection: null,

      getLoadingStoreSection: false,
      getSuccessStoreSection: null,
      getFailedStoreSection: null,

      saveLoadingStoreSection: false,
      saveSuccessStoreSection: null,
      saveFailedStoreSection: null,

      updateLoadingStoreSection: false,
      updateSuccessStoreSection: null,
      updateFailedStoreSection: null,

      deleteLoadingStoreSection: false,
      deleteSuccessStoreSection: null,
      deleteFailedStoreSection: null,
    };

    case GETALLLOADINGSTORESECTION: return { ...state, getAllLoadingStoreSection: true, getAllSuccessStoreSection: null, getAllFailedStoreSection: null };
    case GETALLSUCCESSSTORESECTION: return { ...state, getAllLoadingStoreSection: false, getAllSuccessStoreSection: action.payload || {}, getAllFailedStoreSection: null };
    case GETALLFAILEDSTORESECTION: return { ...state, getAllLoadingStoreSection: false, getAllSuccessStoreSection: null, getAllFailedStoreSection: action.payload || "" };

    case DELETEALLLOADINGSTORESECTION: return { ...state, deleteAllLoadingStoreSection: true, deleteAllSuccessStoreSection: null, deleteAllFailedStoreSection: null };
    case DELETEALLSUCCESSSTORESECTION: return { ...state, deleteAllLoadingStoreSection: false, deleteAllSuccessStoreSection: action.payload || "", deleteAllFailedStoreSection: null };
    case DELETEALLFAILEDSTORESECTION: return { ...state, deleteAllLoadingStoreSection: false, deleteAllSuccessStoreSection: null, deleteAllFailedStoreSection: action.payload || "" };

    case GETLOADINGSTORESECTION: return { ...state, getLoadingStoreSection: true, getSuccessStoreSection: null, getFailedStoreSection: null };
    case GETSUCCESSSTORESECTION: return { ...state, getLoadingStoreSection: false, getSuccessStoreSection: action.payload || {}, getFailedStoreSection: null };
    case GETFAILEDSTORESECTION: return { ...state, getLoadingStoreSection: false, getSuccessStoreSection: null, getFailedStoreSection: action.payload || "" };

    case SAVELOADINGSTORESECTION: return { ...state, saveLoadingStoreSection: true, saveSuccessStoreSection: null, saveFailedStoreSection: null };
    case SAVESUCCESSSTORESECTION: return { ...state, saveLoadingStoreSection: false, saveSuccessStoreSection: action.payload || "", saveFailedStoreSection: null };
    case SAVEFAILEDSTORESECTION: return { ...state, saveLoadingStoreSection: false, saveSuccessStoreSection: null, saveFailedStoreSection: action.payload || "" };

    case UPDATELOADINGSTORESECTION: return { ...state, updateLoadingStoreSection: true, updateSuccessStoreSection: null, updateFailedStoreSection: null };
    case UPDATESUCCESSSTORESECTION: return { ...state, updateLoadingStoreSection: false, updateSuccessStoreSection: action.payload || "", updateFailedStoreSection: null };
    case UPDATEFAILEDSTORESECTION: return { ...state, updateLoadingStoreSection: false, updateSuccessStoreSection: null, updateFailedStoreSection: action.payload || "" };

    case DELETELOADINGSTORESECTION: return { ...state, deleteLoadingStoreSection: true, deleteSuccessStoreSection: null, deleteFailedStoreSection: null };
    case DELETESUCCESSSTORESECTION: return { ...state, deleteLoadingStoreSection: false, deleteSuccessStoreSection: action.payload || "", deleteFailedStoreSection: null };
    case DELETEFAILEDSTORESECTION: return { ...state, deleteLoadingStoreSection: false, deleteSuccessStoreSection: null, deleteFailedStoreSection: action.payload || "" };

    default: return state;
  }
};

