import axios, { responseType } from "axios";
import { URL } from "./api.conf";

export const apiGetAllSection = () => {
  return axios.get(URL + "/system/available/section");
};

export const apiGetSystemSectionsById = (id) => {
  return axios.get(URL + "/system/available/section/" + `?brandId=${id}`);
};

export const apiGetBestCommissions = ({ page, type }) => {
  return axios.get(URL + "/best/commissions"+ `?page=${page}&type=${type}`);
};

export const apiGetAllSectionForSelect = () => {
  return axios.get(URL + "/sections/select");
};

export const apiGetAllProductsInSections = () => {
  return axios.get(URL + "/home/products");
};
export const apiDeleteAllSection = () => {
  return axios.delete(URL + "/section/");
};

export const apiGetSectionDetailsByID = (id) => {
  return axios.get(URL + "/section/details/" + id);
};

export const apiGetStoreSectionById = (id) => {
  return axios.get(URL + "/store/section/" + id);
};

export const apiGetSectionReportByID = (id) => {
  return axios.get(URL + "/request/report/" + id);
};

export const apiGetSectionByID = (id) => {
  return axios.get(URL + "/section/products/" + id, { responseType });
};

export const apiUpdateSectionByID = (id, section) => {
  return axios.put(URL + "/section/" + id, section);
};

export const apiSaveSection = (section) => {
  return axios.post(URL + "/section", section);
};

export const apiDeleteSectionByID = (id) => {
  return axios.delete(URL + "/section/" + id);
};
