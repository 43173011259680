
import axios from "axios";
import { URL } from "./api.conf";

export const apiGetAllStoreSection = () => {
  return axios.get(URL + "/storeSection");
};
export const apiGetAllStoreSectionByStoreId = (storeId) => {
  return axios.get(URL + "/system/store/sections/" + storeId);
};
export const apiDeleteAllStoreSection = () => {
  return axios.delete(URL + "/storeSection/");
};

export const apiGetStoreSectionByID = (sectionId, storeId) => {
  return axios.get(URL + "/storeSection/" + sectionId + "/" + storeId);
};

export const apiUpdateStoreSectionByID = (id, storeSection) => {
  return axios.put(URL + "/storeSection/" + id, storeSection);
};

export const apiSetSectionActiveInStoreSection = (sectionId, storeSectionId) => {
  return axios.put(URL + "/set/section/active" + `?sectionId=${sectionId}&storeSectionId=${storeSectionId}`);
};

export const apiSaveStoreSection = (storeSection) => {
  return axios.post(URL + "/storeSection", storeSection);
};

export const apiAddNewStoreSection_Affiliator = (data) => {
  return axios.post(URL + "/add/store/section", data);
};

export const apiDeleteStoreSectionByID = ({ storeSectionId, storeId }) => {
  return axios.delete(URL + "/storeSection/" + storeSectionId + "/" + storeId);
};

export const apiDeleteStoreByBrandId = ({ storebrand }) => {
  return axios.delete(URL + "/store/storebrand/" + storebrand);
};

export const apiDeleteSection_StoreSectionByID = ({ sectionId, storeSectionId, storeId }) => {
  return axios.delete(URL + "/storeSection/section/" + sectionId + "/"+ storeSectionId + "/" + storeId);
};

