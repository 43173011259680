
import {
  apiGetAllSystemAvailable,
  apiGetAllSystemQuery,
  apiRegisterInSystem,
  apiGenerateToken,
  apiDeleteAllSystem,
  apiGetSystemByID,
  apiSaveSystem,
  apiUpdateSystemByID,
  apiDeleteSystemByID,
} from "../api/";

import { HandelMyError } from "../store/Redux.Hander.js";
import { getBestCommissions, getSystemSectionsById } from "../config";

const SYSTEM_RESETALL = "GETALLSUCCESSSYSTEM_RESETALL"

const GETALLLOADINGSYSTEM = "GETALLLOADINGSYSTEM"
const GETALLSUCCESSSYSTEM = "GETALLSUCCESSSYSTEM"
const GETALLFAILEDSYSTEM = "GETALLFAILEDSYSTEM"

const GETRESET_SYSTEM = "GETRESET_SYSTEM"
const GETLOADING_SYSTEM = "GETLOADING_SYSTEM"
const GETSUCCESS_SYSTEM = "GETSUCCESS_SYSTEM"
const GETFAILED_SYSTEM = "GETFAILED_SYSTEM"

const REGISTER_IN_SYSTEM = "REGISTER_IN_SYSTEM"



const DELETEALLLOADINGSYSTEM = "DELETEALLLOADINGSYSTEM"
const DELETEALLSUCCESSSYSTEM = "DELETEALLSUCCESSSYSTEM"
const DELETEALLFAILEDSYSTEM = "DELETEALLFAILEDSYSTEM"

const GETLOADINGSYSTEM = "GETLOADINGSYSTEM"
const GETSUCCESSSYSTEM = "GETSUCCESSSYSTEM"
const GETFAILEDSYSTEM = "GETFAILEDSYSTEM"

const SAVELOADINGSYSTEM = "SAVELOADINGSYSTEM"
const SAVESUCCESSSYSTEM = "SAVESUCCESSSYSTEM"
const SAVEFAILEDSYSTEM = "SAVEFAILEDSYSTEM"

const UPDATELOADINGSYSTEM = "UPDATELOADINGSYSTEM"
const UPDATESUCCESSSYSTEM = "UPDATESUCCESSSYSTEM"
const UPDATEFAILEDSYSTEM = "UPDATEFAILEDSYSTEM"

const DELETELOADINGSYSTEM = "DELETELOADINGSYSTEM"
const DELETESUCCESSSYSTEM = "DELETESUCCESSSYSTEM"
const DELETEFAILEDSYSTEM = "DELETEFAILEDSYSTEM"

export const resetAllSystem = () => {
  return (dispatch) => {
    dispatch({ type: SYSTEM_RESETALL });
  };
};
export const resetSystem = () => {
  return (dispatch) => {
    dispatch({ type: GETRESET_SYSTEM });
  };
};

export const getAllSystemAvailable = (query) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: GETRESET_SYSTEM });
      dispatch({ type: GETALLLOADINGSYSTEM });
      const { data } = await apiGetAllSystemAvailable(query);
      dispatch({ type: GETALLSUCCESSSYSTEM, payload: { ...data } });
    } catch (e) {
      dispatch({ type: GETALLFAILEDSYSTEM, payload: HandelMyError(e) });
    }
  };
};

export const getAllSystemQuery = (query) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: GETRESET_SYSTEM });
      dispatch({ type: GETALLLOADINGSYSTEM });
      const { data } = await apiGetAllSystemQuery(query);
      dispatch({ type: GETALLSUCCESSSYSTEM, payload: { ...data } });
    } catch (e) {
      dispatch({ type: GETALLFAILEDSYSTEM, payload: HandelMyError(e) });
    }
  };
};

export const registerInSystem = (systemId, query, url) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETLOADING_SYSTEM, payload: { register: true } });
      const { data } = await apiRegisterInSystem(systemId, url);
      dispatch({ type: GETSUCCESS_SYSTEM, payload: { register: data } });
    } catch (e) {
      dispatch({ type: GETFAILED_SYSTEM, payload: { register: HandelMyError(e) } });
    }
  };
};

export const registerInSystem_FN = (systemId, query, clickedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REGISTER_IN_SYSTEM, payload: { loading: true } });
      const { data } = await apiRegisterInSystem(systemId);
      dispatch({ type: REGISTER_IN_SYSTEM, payload: { ...data, clickedId } });
      dispatch(getBestCommissions(query));
    } catch (e) {
      dispatch({ type: REGISTER_IN_SYSTEM, payload: { failed: HandelMyError(e) } });
    }
  };
};

export const registerInSystem_FN_Merge = (systemId, clickedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REGISTER_IN_SYSTEM, payload: { loading: true } });
      const { data } = await apiRegisterInSystem(systemId);
      dispatch({ type: REGISTER_IN_SYSTEM, payload: { ...data, clickedId } });
      dispatch(getSystemSectionsById(systemId));
    } catch (e) {
      dispatch({ type: REGISTER_IN_SYSTEM, payload: { failed: HandelMyError(e) } });
    }
  };
};

export const reset_registerInSystem_FN = (systemId, query, clickedId) => {
  return async (dispatch) => {
    dispatch({ type: REGISTER_IN_SYSTEM, payload: null });
  };
};

export const generateToken = (systemId, url) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETLOADING_SYSTEM, payload: { gToken: true } });
      const { data } = await apiGenerateToken(systemId, url);
      dispatch({ type: GETSUCCESS_SYSTEM, payload: { gToken: data } });
    } catch (e) {
      dispatch({ type: GETFAILED_SYSTEM, payload: { gToken: HandelMyError(e) } });
    }
  };
};

export const deleteAllSystem = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SYSTEM_RESETALL });
      dispatch({ type: DELETEALLLOADINGSYSTEM });
      const { data: { success } } = await apiDeleteAllSystem();
      dispatch({ type: DELETEALLSUCCESSSYSTEM, payload: success });
    } catch (e) {
      dispatch({ type: DELETEALLFAILEDSYSTEM, payload: HandelMyError(e) });
    }
  };
};

export const getSystemByID = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SYSTEM_RESETALL });
      dispatch({ type: GETLOADINGSYSTEM });
      const { data: { system } } = await apiGetSystemByID(id);
      dispatch({ type: GETSUCCESSSYSTEM, payload: { system } });
    } catch (e) {
      dispatch({ type: GETFAILEDSYSTEM, payload: HandelMyError(e) });
    }
  };
};

export const saveSystem = (system) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVELOADINGSYSTEM });
      const { data: { success } } = await apiSaveSystem(system);
      dispatch({ type: SAVESUCCESSSYSTEM, payload: success });
    } catch (e) {
      dispatch({ type: SAVEFAILEDSYSTEM, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGSYSTEM });
      const { data: { system } } = await apiGetSystemByID();
      dispatch({ type: GETSUCCESSSYSTEM, payload: { system } });
    } catch (e) {
      dispatch({ type: GETFAILEDSYSTEM, payload: HandelMyError(e) });
    }
  };
};

export const updateSystemByID = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSYSTEM });
      const { data: { success } } = await apiUpdateSystemByID(id, data);
      dispatch({ type: UPDATESUCCESSSYSTEM, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSYSTEM, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGSYSTEM });
      const { data: { system } } = await apiGetSystemByID(id);
      dispatch({ type: GETSUCCESSSYSTEM, payload: { system } });
    } catch (e) {
      dispatch({ type: GETFAILEDSYSTEM, payload: HandelMyError(e) });
    }
  };
};

export const deleteSystemByID = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SYSTEM_RESETALL });
      dispatch({ type: DELETELOADINGSYSTEM });
      const { data: { success } } = await apiDeleteSystemByID(id);
      dispatch({ type: DELETESUCCESSSYSTEM, payload: success });
    } catch (e) {
      dispatch({ type: DELETEFAILEDSYSTEM, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGSYSTEM });
      const { data: { system } } = await apiGetSystemByID(id);
      dispatch({ type: GETSUCCESSSYSTEM, payload: { system } });
    } catch (e) {
      dispatch({ type: GETFAILEDSYSTEM, payload: HandelMyError(e) });
    }
  };
};

const INITIAL_STATE = {
  getAllLoadingSystem: false,
  getAllSuccessSystem: null,
  getAllFailedSystem: null,

  deleteAllLoadingSystem: false,
  deleteAllSuccessSystem: null,
  deleteAllFailedSystem: null,

  getLoadingSystem: false,
  getSuccessSystem: null,
  getFailedSystem: null,

  saveLoadingSystem: false,
  saveSuccessSystem: null,
  saveFailedSystem: null,

  updateLoadingSystem: false,
  updateSuccessSystem: null,
  updateFailedSystem: null,

  deleteLoadingSystem: false,
  deleteSuccessSystem: null,
  deleteFailedSystem: null,

  getLoading: {},
  getSuccess: {},
  getFailed: {},

  registerInSystem: {},

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case REGISTER_IN_SYSTEM: return { ...state, ...(action.payload !== null && {registerInSystem: { ...state?.registerInSystem, ...action?.payload } }), ...(action.payload === null && { registerInSystem: {} } )};

    case SYSTEM_RESETALL: return {
      ...state,
      getAllLoadingSystem: false,
      getAllSuccessSystem: null,
      getAllFailedSystem: null,

      deleteAllLoadingSystem: false,
      deleteAllSuccessSystem: null,
      deleteAllFailedSystem: null,

      getLoadingSystem: false,
      getSuccessSystem: null,
      getFailedSystem: null,

      saveLoadingSystem: false,
      saveSuccessSystem: null,
      saveFailedSystem: null,

      updateLoadingSystem: false,
      updateSuccessSystem: null,
      updateFailedSystem: null,

      deleteLoadingSystem: false,
      deleteSuccessSystem: null,
      deleteFailedSystem: null,
    };

    case GETALLLOADINGSYSTEM: return { ...state, getAllLoadingSystem: true, getAllSuccessSystem: null, getAllFailedSystem: null };
    case GETALLSUCCESSSYSTEM: return { ...state, getAllLoadingSystem: false, getAllSuccessSystem: action.payload || {}, getAllFailedSystem: null };
    case GETALLFAILEDSYSTEM: return { ...state, getAllLoadingSystem: false, getAllSuccessSystem: null, getAllFailedSystem: action.payload || "" };

    case DELETEALLLOADINGSYSTEM: return { ...state, deleteAllLoadingSystem: true, deleteAllSuccessSystem: null, deleteAllFailedSystem: null };
    case DELETEALLSUCCESSSYSTEM: return { ...state, deleteAllLoadingSystem: false, deleteAllSuccessSystem: action.payload || "", deleteAllFailedSystem: null };
    case DELETEALLFAILEDSYSTEM: return { ...state, deleteAllLoadingSystem: false, deleteAllSuccessSystem: null, deleteAllFailedSystem: action.payload || "" };

    case GETLOADINGSYSTEM: return { ...state, getLoadingSystem: true, getSuccessSystem: null, getFailedSystem: null };
    case GETSUCCESSSYSTEM: return { ...state, getLoadingSystem: false, getSuccessSystem: action.payload || {}, getFailedSystem: null };
    case GETFAILEDSYSTEM: return { ...state, getLoadingSystem: false, getSuccessSystem: null, getFailedSystem: action.payload || "" };

    case SAVELOADINGSYSTEM: return { ...state, saveLoadingSystem: true, saveSuccessSystem: null, saveFailedSystem: null };
    case SAVESUCCESSSYSTEM: return { ...state, saveLoadingSystem: false, saveSuccessSystem: action.payload || "", saveFailedSystem: null };
    case SAVEFAILEDSYSTEM: return { ...state, saveLoadingSystem: false, saveSuccessSystem: null, saveFailedSystem: action.payload || "" };

    case UPDATELOADINGSYSTEM: return { ...state, updateLoadingSystem: true, updateSuccessSystem: null, updateFailedSystem: null };
    case UPDATESUCCESSSYSTEM: return { ...state, updateLoadingSystem: false, updateSuccessSystem: action.payload || "", updateFailedSystem: null };
    case UPDATEFAILEDSYSTEM: return { ...state, updateLoadingSystem: false, updateSuccessSystem: null, updateFailedSystem: action.payload || "" };

    case DELETELOADINGSYSTEM: return { ...state, deleteLoadingSystem: true, deleteSuccessSystem: null, deleteFailedSystem: null };
    case DELETESUCCESSSYSTEM: return { ...state, deleteLoadingSystem: false, deleteSuccessSystem: action.payload || "", deleteFailedSystem: null };
    case DELETEFAILEDSYSTEM: return { ...state, deleteLoadingSystem: false, deleteSuccessSystem: null, deleteFailedSystem: action.payload || "" };


    case GETRESET_SYSTEM: return {
      ...state,
      getLoading: {},
      getSuccess: {},
      getFailed: {},
    };

    case GETLOADING_SYSTEM:
      return {
        ...state,
        getLoading: { ...INITIAL_STATE.getLoading, ...action.payload },
        getSuccess: { ...Object.assign({}, INITIAL_STATE.getSuccess, ...Object.keys(action.payload).flatMap((e) => { return { [e]: null } })) },
        getFailed: { ...Object.assign({}, INITIAL_STATE.getFailed, ...Object.keys(action.payload).flatMap((e) => { return { [e]: null } })) },
      };
    case GETSUCCESS_SYSTEM:
      return {
        ...state,
        getLoading: { ...Object.assign({}, INITIAL_STATE.getLoading, ...Object.keys(action.payload).flatMap((e) => { return { [e]: null } })) },
        getSuccess: { ...INITIAL_STATE.getSuccess, ...action.payload },
        getFailed: { ...Object.assign({}, INITIAL_STATE.getFailed, ...Object.keys(action.payload).flatMap((e) => { return { [e]: null } })) },
      };
    case GETFAILED_SYSTEM:
      return {
        ...state,
        getSuccess: { ...Object.assign({}, INITIAL_STATE.getSuccess, ...Object.keys(action.payload).flatMap((e) => { return { [e]: null } })) },
        getLoading: { ...Object.assign({}, INITIAL_STATE.getLoading, ...Object.keys(action.payload).flatMap((e) => { return { [e]: null } })) },
        getFailed: { ...INITIAL_STATE.getFailed, ...action.payload },
      };

    default: return state;
  }
};

