import axios from "axios";
import { URL } from "./api.conf";

export const apiGetAffiliator_Oauth = (token) => {
  return axios.post(URL + "/auth", { access_token: token });
};

export const apiGetAffiliator = (token) => {
  return axios.get(URL + "/me");
};
export const apiUpdateAffiliatorProfile = (data) => {
  return axios.put(URL + "/update/profile/", data);
};

export const apiAddPaymentMethod = (data) => {
  return axios.put(URL + "/update/profile/payment", data);
};

export const apiRemovePayment = (id) => {
  return axios.delete(URL + "/update/profile/payment?id=" + id);
};
