import React from "react";
import { Card } from "reactstrap";
import warning from "assets/images/warning.svg";
// import { AlertTriangle } from "react-feather";

class GetFailedComponent extends React.Component {
  render() {
    return (
      <Card className="card-box-alt card-border-top border-warning mb-5 p-4  d-flex">
        <h3 className="font-size-lg font-weight-bold px-3 px-xl-4 m-0">
          <img alt={this.props.failed} className="w-100 mb-2" src={warning} />
          <p className="text-justify text-wrap small font-weight-bold">{this.props.failed}</p>
        </h3>
      </Card>
    );
  }
}
export default GetFailedComponent;
