import React from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
// import { X } from "react-feather";
class UpdateClientComponent extends React.Component {
  state = {
    hover: false,
    show: true,
    seconds: 3000,
  };
  Content = ({ style }) => {
    return (
      <Toast
        onMouseEnter={(e) => {
          this.setState({ hover: true });
        }}
        onMouseLeave={(e) => {
          this.setState({ hover: false });
        }}
        isOpen={this.state.show}
        dir={"rtl"}
        className="bg-midnight-bloom mx-auto"
        style={style}
      >
        <ToastHeader
          className="bg-white"
          icon={this.props.icon}
          toggle={() => {
            this.setState({ show: false });
          }}
        ></ToastHeader>
        <ToastBody className={`text-${this.props.icon} font-weight-bold`}>
          {this.props.Body}
        </ToastBody>
      </Toast>
    );
  };
  render() {
    setTimeout(() => {
      if (!this.state.hover) {
        this.setState({ show: false });
      }
    }, this.state.seconds);

    return (
      <>
        {/* <span className="d-sm-flex d-md-none">
          {this.Content({
            style: {
              position: "fixed",
              top: "0",
              right: "0",
              left: "0",
              width: "80%",
              margin : "8px auto",
              zIndex : "1020"
            },
          })}
        </span>
 */}
        {/* Midd */}
        <span className="d-flex">
        {this.Content({
            style: {
              position: "fixed",
              border: "0",
              bottom: "0",
              left: "0",
              right: "0",
              width: "80%",
              margin : "8px auto",
              zIndex : "1020"
            },
          })}
        </span>
      </>
    );
  }
}
export default UpdateClientComponent;
