import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, CardText, FormGroup, Form, Input, Row, Col, Label, Badge, Alert } from "reactstrap";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import NotAuth from "components/NotAuth";
import "react-phone-input-2/lib/style.css";
import ar from "react-phone-input-2/lang/ar.json";
import { updateProfile } from "../redux/config";
import { Link } from "react-router-dom";
const Profile = (props) => {
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [secPhone, setSecPhone] = useState(null);
  const [fbURL, setFbURL] = useState(null);
  const [success, setSuccess] = useState(true);
  const [danger, setDanger] = useState(true);

  const states = Country.getAllCountries()?.map((ca) => {
    return { value: ca.isoCode, label: ca.name };
  });
  const CountryData = (code) => {
    return Country.getAllCountries()?.find((ca) => String(ca.isoCode) === String(code));
  };
  const CountryCites = (country) => {
    return State.getAllStates()?.filter((ca) => String(ca.countryCode) === String(country));
  };

  const cites = CountryCites(state)?.map((ca) => {
    return { value: ca.isoCode, label: ca.name };
  });

  const {
    auth: { affiliator, updateSuccess, updateFailed },
  } = props;
  const changeStateHandler = (state) => {
    setCity(null);
    setState(state.value);
  };
  const changeCityHandler = (city) => {
    setCity(city.value);
  };
  const handleChangeFormik = (ele, value) => {
    setState();
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderColor: "#344675",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#fff",
      background: "transparent",
    }),
  };
  useEffect(() => {
    if (affiliator?.state !== undefined && affiliator?.state !== null) {
      setState(affiliator?.state);
    }
    if (affiliator?.city !== undefined && affiliator?.city !== null) {
      setCity(affiliator?.city);
    }
    if (affiliator?.phone !== undefined && affiliator?.phone !== null) {
      setPhone(String(affiliator?.phone));
    }
    if (affiliator?.secPhone !== undefined && affiliator?.secPhone !== null) {
      setSecPhone(String(affiliator?.secPhone));
    }
    if (affiliator?.fbURL !== undefined && affiliator?.fbURL !== null) {
      setFbURL(affiliator?.fbURL);
    }
  }, [affiliator]);

  useEffect(() => {
    setSuccess(true);
  }, [updateSuccess?.profile]);

  useEffect(() => {
    setDanger(true);
  }, [updateFailed?.profile]);

  // console.log(state);
  return (
    <>
      <div className="content">
        {(() => {
          if (affiliator === null) {
            return <NotAuth />;
          } else {
            return (
              <Row>
                <Col sm="12">
                  {(() => {
                    if (updateSuccess?.profile && updateSuccess?.profile !== null) {
                      return (
                        <Alert color="info" isOpen={success} toggle={() => setSuccess(false)}>
                          <strong>{updateSuccess?.profile}!</strong>.
                        </Alert>
                      );
                    }
                  })()}
                  {(() => {
                    if (updateFailed?.profile && updateFailed?.profile !== null) {
                      return (
                        <Alert color="danger" isOpen={danger} toggle={() => setDanger(false)}>
                          <strong>{updateFailed?.profile}!</strong>.
                        </Alert>
                      );
                    }
                  })()}
                </Col>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img alt={affiliator?.name} className="avatar" src={affiliator?.picture} />
                          <h5 className="title">{affiliator?.name}</h5>
                        </a>
                        <p className="description">{affiliator?.email}</p>
                      </div>
                    </CardBody>
                    {(() => {
                      if (affiliator?.fbURL !== undefined && affiliator?.fbURL !== null) {
                        return (
                          <CardFooter>
                            <div className="button-container">
                              <Button
                                className="btn-icon btn-round d-grid align-items-center justify-content-center"
                                tag={Link}
                                to={{ pathname: affiliator?.fbURL }}
                                target="_blank"
                                color="facebook"
                              >
                                <i className="fab fa-facebook" />
                              </Button>
                            </div>
                          </CardFooter>
                        );
                      }
                    })()}
                  </Card>
                </Col>
                <Col md="8">
                  <Card>
                    <CardHeader>
                      <h5 className="title">تعديل حسابي</h5>
                    </CardHeader>
                    <CardBody className="px-4">
                      <Form>
                        <FormGroup>
                          <Label for="state">
                            اختر الدولة <Badge color="default">{states.find((option) => option.value === affiliator?.state)?.label}</Badge>
                          </Label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            // tag={Input}
                            // className="bg-transparent"
                            placeholder="بحث..."
                            id="state"
                            autoComplete="off"
                            options={states}
                            onChange={changeStateHandler}
                          />
                        </FormGroup>
                        {(() => {
                          if (state !== null && state !== undefined) {
                            return (
                              <>
                                <FormGroup>
                                  <Label for="city">
                                    اختر المحافظة <Badge color="default">{cites.find((option) => option.value === affiliator?.city)?.label}</Badge>
                                  </Label>
                                  <Select
                                    styles={customStyles}
                                    tag={Input}
                                    id="city"
                                    autoComplete="off"
                                    placeholder="بحث..."
                                    autoComplete="off"
                                    options={cites}
                                    onChange={changeCityHandler}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="phoneHtml">رقم الهاتف</Label>
                                  <PhoneInput
                                    enableAreaCodeStretch
                                    enableSearch="false"
                                    disableDropdown="false"
                                    disableSearchIcon="true"
                                    country={state?.toLowerCase()}
                                    value={phone}
                                    onChange={(phone) => {
                                      setPhone(phone);
                                    }}
                                    localization={ar}
                                    id="phoneHtml"
                                    inputClass="pl-5 mb-3 mb-md-0 w-100 bg-transparent border-default"
                                    buttonClass="border-default bg-transparent"
                                    containerClass="ltr text-dark"
                                    type="phone"
                                    name="phone"
                                    autoComplete="tel"
                                    searchClass="w-100 d-flex justify-content-center m-auto rtl"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="secPhoneHtml">رقم الهاتف الاحتياطي</Label>
                                  <PhoneInput
                                    enableAreaCodeStretch
                                    enableSearch="false"
                                    disableDropdown="false"
                                    disableSearchIcon="true"
                                    country={state?.toLowerCase()}
                                    value={secPhone}
                                    onChange={(secPhone) => {
                                      setSecPhone(secPhone);
                                    }}
                                    localization={ar}
                                    id="secPhoneHtml"
                                    inputClass="pl-5 mb-3 mb-md-0 w-100 bg-transparent border-default"
                                    buttonClass="border-default bg-transparent"
                                    containerClass="ltr text-dark"
                                    type="phone"
                                    name="secPhone"
                                    autoComplete="off"
                                    searchClass="w-100 d-flex justify-content-center m-auto rtl"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="fbURL">حساب الفيسبوك</Label>
                                  <Input
                                    defaultValue={fbURL}
                                    onChange={(e) => {
                                      setFbURL(e.target.value);
                                    }}
                                    type="url"
                                    id="fbURL"
                                  />
                                </FormGroup>
                              </>
                            );
                          }
                        })()}
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button
                        color="default"
                        className="font-cairo"
                        onClick={() => {
                          // updateProfile
                          props.updateProfile({
                            state,
                            city,
                            phone,
                            secPhone,
                            fbURL,
                          });
                        }}
                        type="submit"
                      >
                        حفظ
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            );
          }
        })()}
      </div>
    </>
  );
};

export default connect((state) => state, { updateProfile })(Profile);
