import React, { useEffect, useState } from "react";
import {
  // Badge,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
  // CardTitle,
  // CardText,
  // CardImg,
  CardFooter,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  Input,
  // ListGroup,
  // ListGroupItem
} from "reactstrap";
import { connect } from "react-redux";
import {
  resetAllSection,
  getBestCommissions,
  openSectionWizard,
  registerInSystem_FN,
  runAlert,
  runInterActive,
  reset_registerInSystem_FN,
} from "../redux/config";
import { URLIMG, MAIN_URL } from "../redux/api/api.conf";
import { BiLinkExternal } from "react-icons/bi";
import { CgMenuGridR } from "react-icons/cg";
import { BsViewList } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiCoin, BiCoinStack } from "react-icons/bi";
import { FcShop } from "react-icons/fc";
import { RiBarChartLine } from "react-icons/ri";
import { GiMailShirt, GiTreeBranch, GiTakeMyMoney } from "react-icons/gi";
import { Img } from "react-image";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import logo from "assets/images/ator.gif";
// import Moment from "react-moment";
// import "moment-timezone";
// import "moment/locale/ar";
// import CreateSectionWizard from 'components/CreateSectionWizard.js'
import { GiExitDoor, GiDoor } from "react-icons/gi";
import queryString from "query-string";
import converter from "number-to-words";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { CgTimer } from "react-icons/cg";
import InterActive from "components/InterActive";
import { bounceInDown } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Link } from "react-router-dom";
import doneEasyHand from "assets/images/doneEasyHand.svg";
import coffeBreak from "assets/images/coffe-break.svg";
import useGeoLocation from "react-ipgeolocation";
import { Country, State, City } from "country-state-city";
import Select from "react-select";

const treeMessage = (store) => {
  return `ليك ${store?.tsPercent === false ? store?.tsValue + " " + store?.currency : store?.tsValue + "%"} علي عمولة المسوقين المنضمين من خلالك بحد أقصي ${
    store?.tsOrdersCount
  } طلب`;
};
const states = Country.getAllCountries()?.map((ca) => {
  return { value: ca.isoCode, label: ca.name };
});
// const customStyles = {
//   control: (base, state) => ({
//     ...base,
//     background: "transparent",
//     borderColor: "#344675",
//   }),
//   singleValue: (base, state) => ({
//     ...base,
//     color: "#fff",
//     background: "transparent",
//   }),
// };
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: "transparent",
    borderColor: "#344675",
    width: "100%",
    fontSize: "small",
  }),
  control: (base, state) => ({
    ...base,
    background: "transparent",
    borderColor: "#344675",
    zIndex: 999,
  }),
  input: (base, state) => ({
    ...base,
    // width: "5em",
    fontSize: "small",
    zIndex: 999,
  }),
  menuList: (base, state) => ({
    ...base,
    width: "100%",
  }),
  container: (base, state) => ({
    ...base,
    width: "100%",
  }),
  menu: (base, state) => ({
    ...base,
    width: "100%",
  }),

  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = "opacity 300ms";

  //   return { ...provided, opacity, transition };
  // },
};

const BestCommissions = (props) => {
  const query = queryString.parse(props.location.search);
  const page = query.page !== undefined ? Number(query.page) : 1;
  const type = query.type !== undefined ? String(query.type) : "highestCommission";

  const { resetAllSection, getBestCommissions, openSectionWizard, registerInSystem_FN, runAlert, runInterActive, reset_registerInSystem_FN } = props;
  const {
    section: { getAllLoadingSection, getAllSuccessSection, getAllFailedSection },
  } = props;
  const {
    auth: { affiliator, interActive },
  } = props;
  const { systems } = props;

  const routerToPage = (page, type) => {
    props.history.push({ search: `?page=${page}&type=${type}` });
  };
  const [fetchingDone, setFetchingDone] = useState(false);

  let cApi = useGeoLocation()?.country?.toLowerCase() || "eg";
  useEffect(() => {
    if (cApi) {
      getBestCommissions({ page, type });
      setFetchingDone(true);
    }
  }, [page, type, cApi]);

  useEffect(() => {
    if (systems?.registerInSystem?.loading === true) {
      runInterActive({ type: "loading" });
      // runAlert({type:"loading"});
    }
    if (systems?.registerInSystem?.success !== undefined) {
      runInterActive({ type: "success" });
      // runAlert({type:"success", msg: systems?.registerInSystem?.success});
    }
    if (systems?.registerInSystem?.failed !== undefined) {
      runInterActive({ type: "failed" });
      runAlert({ type: "failed", msg: systems?.registerInSystem?.failed });
    }
  }, [systems]);

  const systemLink = (store) => {
    return `https://${store?.hostName !== "" && store?.hostName !== null ? store?.hostName : store?.subDomain + "." + MAIN_URL}`;
  };

  const getUserIdFromSystem = (store) => {
    return affiliator?.users?.find((user) => user?.system?._id === store?._id)?._id;
  };
  const widgetsCarousels1A = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  const statusName = (key) => {
    switch (key) {
      case "highestCommission":
        return (
          <>
            <GiTakeMyMoney size="30" className="ml-1" /> الاعلي عمولة
          </>
        );
      case "bestSeller":
        return (
          <>
            <RiBarChartLine size="30" className="ml-1" /> الأكثر مبيعا
          </>
        );
      case "cheapest":
        return (
          <>
            <BiCoin size="30" className="ml-1" /> الأرخص
          </>
        );
      case "expensive":
        return (
          <>
            <BiCoinStack size="30" className="ml-1" /> الأغلى
          </>
        );
      default:
        return "";
    }
  };
  const statusNameBadge = (key) => {
    switch (key) {
      case "highestCommission":
        return (
          <>
            <GiTakeMyMoney size="15" className="" /> الاعلي عمولة
          </>
        );
      case "bestSeller":
        return (
          <>
            <RiBarChartLine size="15" className="" /> الأكثر مبيعا
          </>
        );
      case "cheapest":
        return (
          <>
            <BiCoin size="15" className="" /> الأرخص
          </>
        );
      case "expensive":
        return (
          <>
            <BiCoinStack size="15" className="" /> الأغلى
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h2 className="text-center mb-3 d-flex flex-row align-items-center">
            <span style={{ fontSize: "1em" }}>المنتجات </span>
          </h2>
          <h2 className="text-center mb-3 d-flex flex-row align-items-center">
            <UncontrolledDropdown group direction="down">
              <DropdownToggle
                data-toggle="dropdown"
                style={{ fontSize: "1em" }}
                className="btn-simple border-0 animation-on-hover px-0 d-flex flex-row flex-row align-items-center mb-0 font-cairo"
                color="twitter"
              >
                {statusName(type)}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="text-info" onClick={() => routerToPage(1, "highestCommission")}>
                  {statusName("highestCommission")}
                </DropdownItem>
                <DropdownItem className="text-info" onClick={() => routerToPage(1, "bestSeller")}>
                  {statusName("bestSeller")}
                </DropdownItem>
                <DropdownItem className="text-info" onClick={() => routerToPage(1, "cheapest")}>
                  {statusName("cheapest")}
                </DropdownItem>
                <DropdownItem className="text-info" onClick={() => routerToPage(1, "expensive")}>
                  {statusName("expensive")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <span style={{ fontSize: "1em" }} className="mx-1">
              {" "}
              في{" "}
            </span>
            <Select
              className="react-select react-select-custom primary bg-transparent font-cairo"
              classNamePrefix="react-select"
              styles={customStyles}
              // tag={Input}
              placeholder="بحث..."
              id="state"
              value={cApi}
              autoComplete="off"
              options={states}
              // onChange={changeStateHandler}
            />
          </h2>
          <p className="category text-center mb-3">
            لضمان اظهار المنتجات الشائعة سيتم تجاهل المنتجات التي لم يتم شراؤها أكثر من{" "}
            <a href="#" onClick={(e) => e.preventDefault()}>
              {" "}
              5 مرات{" "}
            </a>
            ولم يتم شراؤها منذ{" "}
            <a href="#" onClick={(e) => e.preventDefault()}>
              {" "}
              30 يومًا{" "}
            </a>{" "}
          </p>
        </div>
        <Card className="card-timeline card-plain">
          <CardBody className="pt-0">
            <ul className="timeline">
              {(() => {
                if (getAllSuccessSection !== null && getAllSuccessSection?.count === 0) {
                  return (
                    <li className="timeline-inverted">
                      <div className="timeline-panel py-0 px-0">
                        <div className="timeline-body">
                          <Card className={`card-primary mb-0 position-relative`}>
                            <CardBody className="text-center mb-0 mt-0 pb-0 border border-default py-3 px-2 border-bottom-0 mb-0 text-right mx-2 mt-2">
                              <p
                                className="px-0 mb-0 text-justify d-flex flex-column justify-content-center"
                                // style={{ fontSize: '1.2em' }}
                              >
                                <span className="mb-4">
                                  لم يستوف التجار الشروط حتى الآن ولكن يمكنك الانضمام إليهم للمساعدة في تحقيق ذلك اعتمادًا على نظرتك للمنتج "غير موصى به
                                  للمبتدئين" ، تصفح مرة أخرى مع قهوتك غدًا.
                                </span>
                                <img
                                  // className='mr-md-5 pr-md-5'
                                  src={coffeBreak}
                                  // style={{
                                  //   maxHeight: '28px'
                                  // }}
                                />
                              </p>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </li>
                  );
                }
              })()}

              {(() => {
                if (getAllSuccessSection !== null && fetchingDone) {
                  return getAllSuccessSection?.sections?.map((section, i) => (
                    <li className={i % 2 === 1 ? "" : "timeline-inverted"} key={section?._id + i}>
                      <UncontrolledDropdown
                        group
                        direction="right"
                        className="ltr btn-round timeline-badge d-flex justify-content-center align-items-center mb-0 bg-transparent"
                      >
                        <DropdownToggle
                          caret
                          data-toggle="dropdown"
                          className="btn-round btn-icon p-0"
                          color="default"
                          style={{ height: "50px", width: "50px" }}
                        >
                          {(() => {
                            if (section?.store?.settingsStoreImg !== "" && section?.store?.settingsStoreImg !== undefined) {
                              return (
                                <>
                                  <Img
                                    className="rounded-circle"
                                    width="50"
                                    height="50"
                                    style={{ objectFit: "contain" }}
                                    alt={`${section?.store?.settingsStoreName}`}
                                    src={[URLIMG + section?.store?.settingsStoreImg, logo]}
                                    container={(children) => {
                                      return (
                                        <div
                                          style={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            overflow: "hidden",
                                          }}
                                          className="overflow-hidden d-flex align-items-center"
                                        >
                                          {children}
                                        </div>
                                      );
                                    }}
                                    loader={
                                      <SkeletonTheme width="100%" height="100%" color="#f0f0f0" highlightColor="#fff">
                                        <Skeleton duration={0.5} width={50} height={50} />
                                      </SkeletonTheme>
                                    }
                                  />
                                </>
                              );
                            } else {
                              return <img src={logo} alt={section?.store?.settingsStoreName} />;
                            }
                          })()}
                        </DropdownToggle>
                        <DropdownMenu style={{ left: "0 !important" }}>
                          <DropdownItem
                            tag="a"
                            target="_blank"
                            href={systemLink(section?.store)}
                            className="d-flex align-items-center justify-content-center py-0"
                            style={{ height: "30px" }}
                          >
                            فتح نظام التاجر <BiLinkExternal size="20px" className="ml-1" />
                          </DropdownItem>
                          <div className="border-top my-2" />
                        </DropdownMenu>
                      </UncontrolledDropdown>

                      <div className="timeline-panel py-0 px-0">
                        <div className="timeline-body">
                          <Card
                            className={`card-pricing card-primary mb-0 position-relative ${
                              systems?.registerInSystem?.clickedId === section?._id ? "border-gradient" : ""
                            }`}
                          >
                            <div
                              className="timeline-heading position-absolute border-right px-1 border-default"
                              style={{
                                marginBottom: "-3em",
                                bottom: "0",
                              }}
                            >
                              <span className={"" + (i < 3 ? "text-warning " : "text-white")} pill="true">
                                <span style={{ fontSize: "2em" }}>{converter.toOrdinal(1 + i + (page - 1) * 10)}</span>
                                {statusNameBadge(type)}
                              </span>
                            </div>
                            <CardBody className="text-center mb-0 mt-0 pb-0 border border-default py-3 px-2 border-bottom-0 mb-0 text-right mx-2 mt-2 ">
                              <a
                                style={{ fontSize: "1.5em" }}
                                tag="h2"
                                target="_blank"
                                href={`${systemLink(section?.store)}/product/${section?.product?._id}`}
                                className="mb-1 text-white"
                              >
                                {section?.product?.productName}.
                              </a>
                              <h4 className="blockquote-footer px-0 mb-0" style={{ fontSize: "1.2em" }}>
                                <cite title="Source Title">
                                  {new Intl.NumberFormat("ar-EG").format(section?.product?.productSellingPrice)} {section?.store?.currency}
                                </cite>
                              </h4>
                              {/* <footer className="blockquote-footer px-0">اخر عملية شراء <cite title="Source Title"><Moment locale="ar" format="D MMM YYYY">{section?.toDate}</Moment> - <Moment locale="ar" fromNow>{section?.toDate}</Moment></cite></footer> */}
                              <CardBody>
                                <div className="card-prices mt-5 pb-3">
                                  <h3 className="text-on-front text-white">{new Intl.NumberFormat("ar-EG").format(section?.commission)} </h3>
                                  <h5 className="text-on-back opacity-1 text-success">{new Intl.NumberFormat("ar-EG").format(section?.commission)}</h5>
                                  <h3 className="plan text-on-front text-white font-weight-light" style={{ fontSize: "1.2em" }}>
                                    <span
                                      style={{
                                        fontSize: "1.2em",
                                        marginRight: "-1.2em",
                                      }}
                                      className="ml-2 d-inline-flex"
                                    >
                                      عمولتك
                                    </span>
                                    <span style={{ fontSize: "1.2em" }} className="mr-2 d-inline-flex">
                                      {section?.store?.currency}
                                    </span>
                                  </h3>
                                </div>
                                <footer className="blockquote-footer px-0 text-right">
                                  <cite title="Source Title">{section?.product?.productDescription}</cite>
                                </footer>
                              </CardBody>
                            </CardBody>
                            <Slider className="rounded-lg overflow-hidden mb-lg-0 slider-dots-light position-relative" {...widgetsCarousels1A}>
                              {section?.product?.productImgs?.map((img, i) => (
                                <div key={img + i}>
                                  <Card className="shadow-none rounded-0 overflow-hidden">
                                    <div className="card-img-wrapper rounded">
                                      <Img
                                        width="100%"
                                        height="100%"
                                        style={{
                                          objectFit: "cover",
                                          maxHeight: "256px",
                                        }}
                                        alt={`${section?.store?.settingsStoreName}`}
                                        src={[URLIMG + img, logo]}
                                        className="card-overlay-image img-fit-container"
                                        container={(children) => children}
                                        loader={
                                          <SkeletonTheme width="100%" height="100%" color="#f0f0f0" highlightColor="#fff">
                                            <Skeleton duration={0.5} width="100%" height={256} />
                                          </SkeletonTheme>
                                        }
                                      />
                                    </div>
                                  </Card>
                                </div>
                              ))}
                            </Slider>
                            <CardFooter className="text-center d-flex justify-content-between w-100">
                              <div className="d-flex flex-row align-items-center">
                                {/* <Button
                                        size='sm'
                                        className='btn-simple border-0 animation-on-hover px-2'
                                        tag='a'
                                        target='_blank'
                                        href={`${systemLink( section?.store )}/product/${section?.product?._id}`}
                                        color='twitter'
                                        id={'GiMailShirt' + section?._id}
                                      >
                                        <GiMailShirt size='25' />
                                      </Button>
                                      <UncontrolledPopover
                                        trigger='hover'
                                        placement='top'
                                        target={'GiMailShirt' + section?._id}
                                      >
                                        <PopoverHeader>
                                          فتح صفحة المنتج
                                        </PopoverHeader>
                                      </UncontrolledPopover> */}

                                <Button
                                  size="sm"
                                  className="btn-simple border-0 animation-on-hover px-2"
                                  tag="a"
                                  target="_blank"
                                  href={`${systemLink(section?.store)}/section/${section?.product?.productSectionId}`}
                                  color="info"
                                  id={"CgMenuGridR" + section?._id}
                                >
                                  <CgMenuGridR size="30" />
                                </Button>
                                <UncontrolledPopover trigger="hover" placement="top" target={"CgMenuGridR" + section?._id}>
                                  <PopoverHeader>فتح صفحة القسم</PopoverHeader>
                                </UncontrolledPopover>
                                <Button
                                  size="sm"
                                  className="btn-simple border-0 animation-on-hover px-2"
                                  tag={Link}
                                  to={`/system/sections/${section?.store?._id}`}
                                  color="behance"
                                  id={"BsViewList" + section?._id}
                                >
                                  <BsViewList size="25" />
                                </Button>
                                <UncontrolledPopover trigger="hover" placement="top" target={"BsViewList" + section?._id}>
                                  <PopoverHeader>عرض جميع اقسام التاجر</PopoverHeader>
                                </UncontrolledPopover>
                              </div>
                              <div className="d-flex flex-row align-items-center">
                                {(() => {
                                  if (section?.store?.treeSystem) {
                                    return (
                                      <>
                                        <Button
                                          size="sm"
                                          className="btn-simple border-0 animation-on-hover px-2"
                                          tag="a"
                                          target="_blank"
                                          href={`https://authentication.website/?source=system&sourceId=${section?.store?._id}&sourceImg=${
                                            section?.store?.settingsStoreImg
                                          }&sourceName=${section?.store?.settingsStoreName}&refId=${getUserIdFromSystem(
                                            section?.store
                                          )}&sourceRedirect=${systemLink(section?.store)}`}
                                          color="success"
                                          id={"GiTreeBranch" + section?._id}
                                        >
                                          <GiTreeBranch size="25" />
                                        </Button>
                                        <UncontrolledPopover trigger="hover" placement="top" target={"GiTreeBranch" + section?._id}>
                                          <PopoverHeader>نسح رابط الاحالة</PopoverHeader>
                                          <PopoverBody>{treeMessage(section?.store)}</PopoverBody>
                                        </UncontrolledPopover>
                                      </>
                                    );
                                  }
                                })()}
                                {(() => {
                                  if (interActive?.key === "joinUs" + section?._id) {
                                    return (
                                      <InterActive
                                        className="overflow-hidden h-100 btn-simple border-0 animation-on-hover px-2 btn-behance btn-sm d-flex align-items-center justify-content-center"
                                        onEndFN={reset_registerInSystem_FN}
                                      />
                                    );
                                  } else {
                                    return (
                                      <>
                                        <StyleRoot className="h-100 overflow-hidden ">
                                          <div
                                            className="d-flex align-items-center justify-content-center h-100"
                                            style={{
                                              animation: "x 1s",
                                              animationName: Radium.keyframes(bounceInDown, "bounceInDown"),
                                            }}
                                          >
                                            {(() => {
                                              if (!section?.store?.isJoined) {
                                                return (
                                                  <>
                                                    <Button
                                                      className="btn-simple border-0 animation-on-hover px-2"
                                                      id={"jointSystem" + section?._id}
                                                      style={{}}
                                                      size="sm"
                                                      color="success"
                                                      onClick={() => {
                                                        props.registerInSystem_FN(section?.store?._id, { page, type }, section?._id);
                                                        props.runInterActive({
                                                          key: "joinUs" + section?._id,
                                                        });
                                                      }}
                                                    >
                                                      <GiExitDoor size="25" />
                                                    </Button>
                                                    <UncontrolledPopover trigger="hover" placement="top" target={"jointSystem" + section?._id}>
                                                      <PopoverHeader>كن مسوق معنا</PopoverHeader>
                                                    </UncontrolledPopover>
                                                  </>
                                                );
                                              } else if (section?.store?.isPending) {
                                                return (
                                                  <>
                                                    <Button
                                                      className="btn-simple border-0 animation-on-hover px-2"
                                                      size="sm"
                                                      color="warning"
                                                      id={"waiting" + section?._id}
                                                    >
                                                      <CgTimer size="30" />
                                                    </Button>
                                                    <UncontrolledPopover trigger="hover" placement="top" target={"waiting" + section?._id}>
                                                      <PopoverHeader>ننتظر موافقة التاجر</PopoverHeader>
                                                    </UncontrolledPopover>
                                                  </>
                                                );
                                              }
                                            })()}
                                          </div>
                                        </StyleRoot>
                                      </>
                                    );
                                  }
                                })()}
                              </div>
                            </CardFooter>
                            {(() => {
                              if (interActive?.key === "joinUs" + section?._id) {
                                return (
                                  <InterActive
                                    className="overflow-hidden h-100 btn-simple border-0 animation-on-hover px-2 btn-behance btn-sm d-flex align-items-center justify-content-center"
                                    onEndFN={reset_registerInSystem_FN}
                                  />
                                );
                              } else {
                                return (
                                  <>
                                    <StyleRoot className="h-100 overflow-hidden ">
                                      <div
                                        className="d-flex align-items-center justify-content-center h-100"
                                        style={{
                                          animation: "x 1s",
                                          animationName: Radium.keyframes(bounceInDown, "bounceInDown"),
                                        }}
                                      >
                                        {(() => {
                                          if (section?.store?.isJoined) {
                                            return (
                                              <>
                                                <Button
                                                  color="primary"
                                                  className="animation-on-hover w-100 mb-0 rounded-top-0 border-top-0 d-flex flex-row align-items-center justify-content-center px-2"
                                                  onClick={() =>
                                                    openSectionWizard({
                                                      section: section?.rootSection?.id,
                                                      brandId: section?.store?._id,
                                                      currentStep: "selectStore",
                                                    })
                                                  }
                                                  id={"MergeSection" + section?._id}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "1.3em",
                                                    }}
                                                  >
                                                    افلتة
                                                  </span>
                                                  <small style={{ fontSize: "1em" }} className="font-weight-light mr-1">
                                                    ~ ضم القسم لمتجرك بدوسة
                                                  </small>
                                                  <img
                                                    // className='mr-md-5 pr-md-5'
                                                    src={doneEasyHand}
                                                    style={{
                                                      maxHeight: "28px",
                                                    }}
                                                  />
                                                </Button>
                                                <UncontrolledPopover trigger="hover" placement="top" target={"MergeSection" + section?._id}>
                                                  <PopoverHeader>دمج القسم مع متجرك</PopoverHeader>
                                                </UncontrolledPopover>
                                              </>
                                            );
                                          }
                                        })()}
                                      </div>
                                    </StyleRoot>
                                  </>
                                );
                              }
                            })()}
                          </Card>
                        </div>
                      </div>
                    </li>
                  ));
                } else {
                  return (
                    <>
                      <li className="timeline-inverted">
                        <Button className="timeline-badge d-flex align-items-center justify-content-center p-0">
                          <SkeletonTheme width="100%" height="100%" color="#344675" highlightColor="#fff" className="m-0">
                            <Skeleton circle={true} duration={0.5} width={25} height={25} />
                          </SkeletonTheme>
                        </Button>
                        <div className="timeline-panel py-0 px-0">
                          <div className="timeline-body">
                            <Card className="card-pricing card-primary mb-0">
                              <CardBody className="text-center border border-default p-2 m-2">
                                <SkeletonTheme width="100%" height="100%" color="#344675" highlightColor="#fff" className="m-0">
                                  <div className="d-flex flex-column">
                                    <Skeleton duration={0.5} width="100%" height={25} className="mb-2" />
                                    <Skeleton duration={0.5} width="30%" height={5} className="mb-2" />
                                    <Skeleton duration={0.5} width="25%" height={5} className="mb-2" />
                                    <Skeleton duration={0.5} width="60%" height={200} className="mb-2" />
                                    <Skeleton duration={0.5} width="100%" height={256} className="mb-2" />
                                    <CardFooter className="d-flex flex-row justify-content-between align-items-center px-0">
                                      <div className="d-flex flex-row">
                                        <Skeleton duration={0.5} width={40} height={40} className="ml-2 mb-2" />
                                        <Skeleton duration={0.5} width={40} height={40} className="mb-2" />
                                      </div>
                                      <div className="d-flex flex-row">
                                        <Skeleton duration={0.5} width={40} height={40} className="ml-2 mb-2" />
                                        <Skeleton duration={0.5} width={40} height={40} className="mb-2" />
                                      </div>
                                    </CardFooter>
                                  </div>
                                </SkeletonTheme>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      </li>
                      <li>
                        <Button className="timeline-badge d-flex align-items-center justify-content-center p-0">
                          <SkeletonTheme width="100%" height="100%" color="#344675" highlightColor="#fff" className="m-0">
                            <Skeleton circle={true} duration={0.5} width={25} height={25} />
                          </SkeletonTheme>
                        </Button>
                        <div className="timeline-panel py-0 px-0">
                          <div className="timeline-body">
                            <Card className="card-pricing card-primary mb-0">
                              <CardBody className="text-center border border-default p-2 m-2">
                                <SkeletonTheme width="100%" height="100%" color="#344675" highlightColor="#fff" className="m-0">
                                  <div className="d-flex flex-column">
                                    <Skeleton duration={0.5} width="100%" height={25} className="mb-2" />
                                    <Skeleton duration={0.5} width="30%" height={5} className="mb-2" />
                                    <Skeleton duration={0.5} width="25%" height={5} className="mb-2" />
                                    <Skeleton duration={0.5} width="60%" height={200} className="mb-2" />
                                    <Skeleton duration={0.5} width="100%" height={256} className="mb-2" />
                                    <CardFooter className="d-flex flex-row justify-content-between align-items-center px-0">
                                      <div className="d-flex flex-row">
                                        <Skeleton duration={0.5} width={40} height={40} className="ml-2 mb-2" />
                                        <Skeleton duration={0.5} width={40} height={40} className="mb-2" />
                                      </div>
                                      <div className="d-flex flex-row">
                                        <Skeleton duration={0.5} width={40} height={40} className="ml-2 mb-2" />
                                        <Skeleton duration={0.5} width={40} height={40} className="mb-2" />
                                      </div>
                                    </CardFooter>
                                  </div>
                                </SkeletonTheme>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                }
              })()}
            </ul>
          </CardBody>
        </Card>
        {(() => {
          if (getAllSuccessSection?.count !== 0) {
            return (
              <nav aria-label="Page navigation example" className="rtl my-2">
                <Pagination className="d-flex align-items-center">
                  {(() => {
                    if (getAllSuccessSection !== null && getAllSuccessSection?.currentPage !== 1) {
                      return (
                        <PaginationItem className="ml-2">
                          <PaginationLink className="d-flex align-items-center py-1 text-default" onClick={() => routerToPage(page - 1, type)}>
                            السابق{" "}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }
                  })()}
                  {(() => {
                    if (getAllSuccessSection !== null) {
                      return (
                        <FormGroup className="ltr d-flex align-items-center my-0 py-0">
                          <Input
                            type="select"
                            onChange={(e) => {
                              routerToPage(Number(e.target.value), type);
                            }}
                            defaultValue={getAllSuccessSection?.currentPage}
                            name="select"
                            id="exampleSelect1"
                            className="py-0 px-3"
                          >
                            {[...Array(getAllSuccessSection?.totalPages)].map((x, i) => (
                              <option key={i} className="text-default">
                                {i + 1}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      );
                    }
                  })()}

                  {(() => {
                    if (getAllSuccessSection?.currentPage !== getAllSuccessSection?.totalPages) {
                      return (
                        <PaginationItem className="mr-2">
                          <PaginationLink className="d-flex align-items-center py-1 text-default" onClick={() => routerToPage(page + 1, type)}>
                            التالي
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }
                  })()}
                </Pagination>
              </nav>
            );
          }
        })()}
      </div>
    </>
  );
};

export default connect((state) => state, {
  resetAllSection,
  getBestCommissions,
  openSectionWizard,
  registerInSystem_FN,
  runAlert,
  runInterActive,
  reset_registerInSystem_FN,
})(BestCommissions);
