import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getStoresByID,
  checkDomainStore,
  updateStoresByID,
  resetStoresDomainChecker
} from '../../redux/config'
import { ClimbingBoxLoader } from 'react-spinners'
import GetFailedComponent from '../../components/GetFailed'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import StoreImg from './StoreImg'
import { Formik } from 'formik'
import {
  Col,
  Row,
  Badge,
  Card,
  CardHeader,
  Button,
  CardBody,
  Input,
  Container,
  Table,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  Alert,
  Label,
  FormFeedback
} from 'reactstrap'
import * as Yup from 'yup'
import { IoMdDoneAll } from 'react-icons/io'
import { GiCheckedShield } from 'react-icons/gi'
import { AiOutlineToTop } from 'react-icons/ai'
import { BarLoader } from 'react-spinners'
import welcome from 'assets/images/welcome.svg'
import { FiRefreshCcw } from 'react-icons/fi'
import { FaQuestionCircle } from 'react-icons/fa'
import { FcBrokenLink } from 'react-icons/fc'
import { MdDescription, MdInvertColors } from 'react-icons/md'
import { FcCurrencyExchange } from 'react-icons/fc'
import { FaStoreAlt } from 'react-icons/fa'
import ContentLoader from 'react-content-loader'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Country, State, City } from 'country-state-city'
import Select from 'react-select'
import Switch from 'react-bootstrap-switch'
import { BiMessageSquareMinus, BiMessageSquareAdd } from 'react-icons/bi'
import StoreBreadcrumb from '../../components/Store/StoreBreadcrumb'

let Schema = Yup.object().shape({
  domain: Yup.string()
    .matches(
      /^[a-z]+$/,
      'يتم قبول الأحرف الصغيرة فقط ، تأكد من عدم وجود مسافات صغيرة أو أرقام'
    )
    .min(6, 'الحد الادني 6 حروف')
    .max(20, 'الحد الاقصي 20 حرف'),

  storeName: Yup.string().min(4, 'الحد الادني 4 حروف'),

  storeDesc: Yup.string().min(4, 'الحد الادني 4 حروف')
})

let domainSchema = Yup.object().shape({
  domain: Yup.string()
    .matches(
      /^[a-z]+$/,
      'يتم قبول الأحرف الصغيرة فقط ، تأكد من عدم وجود مسافات صغيرة أو أرقام'
    )
    .min(6, 'الحد الادني 6 حروف')
    .max(20, 'الحد الاقصي 20 حرف')
})

const UpdateStoresComponent = props => {
  const {
    stores: {
      getLoadingStores,
      getSuccessStores,
      getFailedStores,

      updateLoadingStores,
      updateSuccessStores,
      updateFailedStores,

      checkDomainLoadingStore,
      checkDomainSuccessStore,
      checkDomainFailedStore
    },
    getStoresByID,
    checkDomainStore,
    updateStoresByID,
    resetStoresDomainChecker
  } = props

  const [domain, setDomain] = useState('')
  const [storeName, setStoreName] = useState('')
  const [storeDesc, setStoreDesc] = useState('')
  const [storeCurrency, setStoreCurrency] = useState('')

  const [storeImg, setStoreImg] = useState('')

  const [minimumCommission, setMinimumCommission] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [overprice, setOverprice] = useState(0)
  const [isPercent, setIsPercent] = useState(false)
  const [
    includeProductsMeetRequirements,
    setIncludeProductsMeetRequirements
  ] = useState(false)

  const [success, setSuccess] = useState(true)
  const [danger, setDanger] = useState(true)

  useEffect(() => {
    resetStoresDomainChecker()
    if (getSuccessStores?.store?.subDomain !== domain) {
      ;(async function () {
        const delayDebounceFn = setTimeout(async () => {
          let data = await domainSchema.validate({ domain })
          if (data?.domain) {
            checkDomainStore({ domain, domainType: 'sub' })
          }
        }, 800)
        return () => clearTimeout(delayDebounceFn)
      })()
    }
  }, [domain])

  useEffect(() => {
    getStoresByID(props.match.params.id)
  }, [])

  useEffect(() => {
    if (getSuccessStores?.store?.subDomain !== domain) {
      setDomain(getSuccessStores?.store?.subDomain)
    }
    if (getSuccessStores?.store?.storeName !== storeName) {
      setStoreName(getSuccessStores?.store?.storeName)
    }
    if (getSuccessStores?.store?.storeDesc !== storeDesc) {
      setStoreDesc(getSuccessStores?.store?.storeDesc)
    }
    if (getSuccessStores?.store?.storeImg !== storeImg) {
      setStoreImg(getSuccessStores?.store?.storeImg)
    }
    if (getSuccessStores?.store?.overprice !== overprice) {
      setOverprice(getSuccessStores?.store?.overprice)
    }
    if (getSuccessStores?.store?.discount !== discount) {
      setDiscount(getSuccessStores?.store?.discount)
    }
    if (getSuccessStores?.store?.minimumCommission !== minimumCommission) {
      setMinimumCommission(getSuccessStores?.store?.minimumCommission)
    }
    if (getSuccessStores?.store?.isPercent !== isPercent) {
      setIsPercent(getSuccessStores?.store?.isPercent)
    }
    if (getSuccessStores?.store?.storeCurrency !== storeCurrency) {
      setStoreCurrency(getSuccessStores?.store?.storeCurrency)
    }
    if (
      getSuccessStores?.store?.includeProductsMeetRequirements !==
      includeProductsMeetRequirements
    ) {
      setIncludeProductsMeetRequirements(
        Boolean(getSuccessStores?.store?.includeProductsMeetRequirements)
      )
    }
  }, [getSuccessStores])

  const changeStoreCurrency = state => {
    setStoreCurrency(state.value)
  }

  const preUpdateStoresByID = (id, internaldata) => {
    let data = new FormData()
    data.append('storeImg', storeImg[0])
    data.append('file_name', 'avatar')
    if (getSuccessStores?.store?.subDomain !== domain) {
      data.set('domainType', 'sub')
      data.set('domain', domain)
    }
    if (getSuccessStores?.store?.storeName !== storeName) {
      data.set('storeName', storeName)
    }
    if (getSuccessStores?.store?.storeDesc !== storeDesc) {
      data.set('storeDesc', storeDesc)
    }
    if (getSuccessStores?.store?.storeCurrency !== storeCurrency) {
      data.set('storeCurrency', storeCurrency)
    }
    if (getSuccessStores?.store?.minimumCommission !== minimumCommission) {
      data.set('minimumCommission', minimumCommission)
    }
    if (getSuccessStores?.store?.discount !== discount) {
      data.set('discount', discount)
    }
    if (getSuccessStores?.store?.overprice !== overprice) {
      data.set('overprice', overprice)
    }
    if (getSuccessStores?.store?.isPercent !== isPercent) {
      data.set('isPercent', isPercent)
    }
    if (
      getSuccessStores?.store?.includeProductsMeetRequirements !==
      includeProductsMeetRequirements
    ) {
      data.set(
        'includeProductsMeetRequirements',
        includeProductsMeetRequirements
      )
    }

    updateStoresByID(id, data)
  }

  const handleProfileImg = storeImg => {
    setStoreImg(storeImg)
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      borderColor: '#1D8CF8'
    }),
    singleValue: (base, state) => ({
      ...base,
      color: '#1D8CF8',
      background: 'transparent'
    })
  }

  const currencyOption = Country.getAllCountries()
    .filter(e => e.currency !== '')
    .map(c => {
      return { label: c.name + ', ' + c.currency, value: c.currency }
    })

  return (
    <div className='content'>
      {(() => {
        if (getLoadingStores) {
          return (
            <SkeletonTheme color='#f0f0f0' highlightColor='#fff'>
              <p>
                <Skeleton height={70} duration={1} />
              </p>
              <p>
                <Skeleton duration={1} width={60} />
              </p>
              <p>
                <Skeleton circle={true} height={128} width={128} />
              </p>
              <p>
                <Skeleton height={70} duration={1} />
              </p>
              <p>
                <Skeleton height={70} duration={2} />
              </p>
              <p className='mb-0'>
                <Skeleton height={70} duration={4} />
              </p>
            </SkeletonTheme>
          )
        } else if (getFailedStores !== null) {
          return (
            <Container className='text-warning'>
              <GetFailedComponent failed={getFailedStores} />
            </Container>
          )
        } else if (getSuccessStores !== null) {
          return (
            <>
              <StoreBreadcrumb store={getSuccessStores?.store} page='inside' />

              <Formik
                enableReinitialize={true}
                initialValues={{
                  domain,
                  storeName,
                  storeDesc,
                  minimumCommission,
                  discount,
                  overprice,
                  isPercent,
                  includeProductsMeetRequirements
                }}
                validationSchema={Schema}
                onSubmit={() => {
                  preUpdateStoresByID(getSuccessStores?.store?._id, {
                    ...(getSuccessStores?.store?.subDomain !== domain && {
                      domain
                    }),
                    ...(getSuccessStores?.store?.storeImg !== storeImg && {
                      storeImg
                    }),
                    ...(getSuccessStores?.store?.storeName !== storeName && {
                      storeName
                    }),
                    ...(getSuccessStores?.store?.storeDesc !== storeDesc && {
                      storeDesc
                    }),
                    ...(getSuccessStores?.store?.minimumCommission !==
                      minimumCommission && {
                      minimumCommission
                    }),

                    ...(getSuccessStores?.store?.discount !== discount && {
                      discount
                    }),

                    ...(getSuccessStores?.store?.overprice !== overprice && {
                      overprice
                    }),
                    ...(getSuccessStores?.store?.isPercent !== isPercent && {
                      isPercent
                    }),
                    ...(getSuccessStores?.store
                      ?.includeProductsMeetRequirements !==
                      includeProductsMeetRequirements && {
                      includeProductsMeetRequirements
                    })
                  })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    {(() => {
                      if (updateFailedStores !== null) {
                        return (
                          <Card className='card-box-alt card-border-top border-danger mb-3 px-3 py-2 d-flex card'>
                            <h5 className='font-size-lg font-weight-bold m-0 text-danger'>
                              {updateFailedStores}
                            </h5>
                          </Card>
                        )
                      }
                      if (updateSuccessStores !== null) {
                        return (
                          <Card className='card-box-alt card-border-top border-success mb-3 px-3 py-2 d-flex card'>
                            <h5 className='font-size-lg font-weight-bold m-0 text-success'>
                              {updateSuccessStores}
                            </h5>
                          </Card>
                        )
                      }
                    })()}
                    <Row className='no-gutters'>
                      <Col md='12' className='d-flex flex-column py-2 px-0'>
                        <Row className=''>
                          <Col className='d-flex flex-column py-2' sm='12'>
                            <Label
                              htmlFor='StoreImgHtml'
                              className='text-info font-weight-bolder text-right'
                              style={{ direction: 'rtl' }}
                            >
                              <MdInvertColors size='20px' /> لوجو المتجر
                            </Label>
                            <StoreImg
                              id='StoreImgHtml'
                              handleProfileImg={handleProfileImg}
                            />
                            {errors.StoreImg && touched.StoreImg ? (
                              <Badge
                                className='m-1 text-danger'
                                color='neutral-danger'
                              >
                                {errors.StoreImg}
                              </Badge>
                            ) : null}
                          </Col>

                          <Col className='form-group' sm='12'>
                            <Label
                              style={{ fontSize: '1.5em' }}
                              className={`text-info font-weight-bolder text-right d-flex justify-content-between`}
                              style={{ direction: 'rtl' }}
                            >
                              <span>
                                <FcBrokenLink size='20px' /> أكتب اسم فريد
                                للمتجر{' '}
                                <b className='text-warning'>
                                  باللغة الإنجليزية
                                </b>{' '}
                              </span>
                            </Label>
                            <Input
                              onBlur={handleBlur}
                              className='border-info mb-1'
                              style={{ direction: 'rtl' }}
                              valid={
                                checkDomainSuccessStore !== null &&
                                !(errors.domain && touched.domain)
                              }
                              invalid={
                                (errors.domain && touched.domain) ||
                                checkDomainFailedStore !== null
                              }
                              placeholder='مثل elmotaheda'
                              autoComplete='off'
                              type='text'
                              name='domain'
                              onChange={e => {
                                setDomain(
                                  String(e.target.value)
                                    .replace(/\s+/g, '')
                                    .replace(/[^A-Za-z\s]/g, '')
                                    .toLowerCase()
                                )
                              }}
                              value={values.domain}
                            />
                            {(() => {
                              if (checkDomainLoadingStore) {
                                return (
                                  <Label className='text-white-50 small'>
                                    جاري التحقق ...
                                  </Label>
                                )
                              }
                            })()}
                            {(() => {
                              if (checkDomainSuccessStore !== null) {
                                return (
                                  <FormFeedback valid>
                                    {checkDomainSuccessStore?.success}
                                  </FormFeedback>
                                )
                              }
                            })()}
                            {errors.domain && touched.domain ? (
                              <Badge
                                className='text-warning'
                                color='neutral-warning'
                              >
                                {errors.domain}
                              </Badge>
                            ) : null}
                            {checkDomainFailedStore !== null ? (
                              <Badge
                                className='text-warning'
                                color='neutral-warning'
                              >
                                {checkDomainFailedStore}
                              </Badge>
                            ) : null}{' '}
                          </Col>
                          <Col className='form-group' sm='12' md='6'>
                            <Label
                              htmlFor='storeNameHtml'
                              className={`text-info font-weight-bolder text-right d-flex justify-content-between`}
                              style={{ direction: 'rtl' }}
                            >
                              <span>
                                <FaStoreAlt size='20px' /> اسم المتجر باللغة
                                العربية{' '}
                              </span>
                            </Label>
                            <Input
                              invalid={errors.storeName && touched.storeName}
                              valid={
                                !(errors.storeName && touched.storeName) &&
                                values.storeName !== ''
                              }
                              id='storeNameHtml'
                              className='border-info mb-1'
                              placeholder="مثال 'المتحدة للملابس'"
                              type='text'
                              name='storeName'
                              autoComplete='off'
                              onBlur={handleBlur}
                              onChange={e => {
                                setStoreName(e.target.value)
                              }}
                              value={values.storeName}
                            />
                            {errors.storeName && touched.storeName ? (
                              <Badge
                                className='text-warning'
                                color='neutral-warning'
                              >
                                {errors.storeName}
                              </Badge>
                            ) : null}
                          </Col>
                          <Col className='form-group' sm='12' md='6'>
                            <Label
                              htmlFor='storeDescHtml'
                              className={`text-info font-weight-bolder text-right d-flex justify-content-between`}
                              style={{ direction: 'rtl' }}
                            >
                              <span>
                                <MdDescription size='20px' /> وصف المتجر باللغة
                                العربية{' '}
                              </span>
                            </Label>
                            <Input
                              id='storeDescHtml'
                              invalid={errors.storeDesc && touched.storeDesc}
                              valid={
                                !(errors.storeDesc && touched.storeDesc) &&
                                values.storeDesc !== ''
                              }
                              className='border-info mb-1'
                              placeholder="مثال 'نقوم ببيع الملابس بسعر التكلفة'"
                              type='text'
                              name='storeDesc'
                              autoComplete='off'
                              onBlur={handleBlur}
                              onChange={e => {
                                setStoreDesc(e.target.value)
                              }}
                              value={values.storeDesc}
                            />
                            {errors.storeDesc && touched.storeDesc ? (
                              <Badge
                                className='text-warning'
                                color='neutral-warning'
                              >
                                {errors.storeDesc}
                              </Badge>
                            ) : null}
                          </Col>
                          <Col className='form-group' sm='12' md='6'>
                            <Label
                              htmlFor='storeDescHtml'
                              className={`text-info font-weight-bolder text-right d-flex justify-content-between`}
                              style={{ direction: 'rtl' }}
                            >
                              <span>
                                <FcCurrencyExchange size='20px' /> عملة المتجر{' '}
                              </span>
                            </Label>
                            <Select
                              styles={customStyles}
                              // tag={Input}
                              className=' text-info'
                              placeholder='بحث...'
                              id='state'
                              autoComplete='off'
                              value={currencyOption.filter(
                                option => option.value === storeCurrency
                              )}
                              options={currencyOption}
                              onChange={changeStoreCurrency}
                              invalid={
                                errors.storeCurrency &&
                                touched.storeCurrency
                              }
                              valid={
                                !(
                                  errors.storeCurrency &&
                                  touched.storeCurrency
                                ) && storeCurrency
                              }
                            />{' '}
                            {errors.storeDesc && touched.storeDesc ? (
                              <Badge
                                className='text-warning'
                                color='neutral-warning'
                              >
                                {errors.storeDesc}
                              </Badge>
                            ) : null}
                          </Col>

                          <Col
                            className='form-group d-flex justify-content-between align-items-center mt-2 mb-3 border-top border-default py-3'
                            sm='12'
                            // md='6'
                          >
                            <Label
                              htmlFor='storeCurrencyHtml'
                              className='text-info font-weight-bolder text-right d-flex mb-0'
                              style={{ direction: 'rtl' }}
                            >
                              <FaQuestionCircle size='20px' className='ml-1' />{' '}
                              ضم جميع منتجات التجار المنضم لهم التي تستوفي الحد
                              الادني للعمولة
                            </Label>
                            <Switch
                              value={includeProductsMeetRequirements}
                              className='mb-0'
                              onColor='success'
                              offColor='default'
                              onText='نعم'
                              offText='لا'
                              onChange={(el, state) =>
                                setIncludeProductsMeetRequirements(state)
                              }
                            />
                          </Col>

                          <Col className='form-group' sm='12' md='6'>
                            <Label
                              htmlFor='storeDescHtml'
                              className={`text-info font-weight-bolder text-right d-flex justify-content-between`}
                              style={{ direction: 'rtl' }}
                            >
                              <span>
                                <AiOutlineToTop size='20px' /> الحد الادني
                                للعمولة
                              </span>
                            </Label>
                            <Input
                              onBlur={handleBlur}
                              className='border-info'
                              style={{ direction: 'rtl' }}
                              invalid={
                                errors.minimumCommission &&
                                touched.minimumCommission
                              }
                              // placeholder="مثل elmotaheda"
                              autoComplete='off'
                              type='text'
                              name='minimumCommission'
                              onChange={e => {
                                setMinimumCommission(
                                  Number(
                                    e.target.value.replace(/[^0-9\.]+/g, '')
                                  )
                                )
                              }}
                              value={values.minimumCommission}
                            />
                            {errors.minimumCommission &&
                            touched.minimumCommission ? (
                              <Badge
                                className='my-1 text-warning'
                                color='neutral-warning'
                              >
                                {errors.minimumCommission}
                              </Badge>
                            ) : null}
                          </Col>
                          <Col className='form-group' sm='12' md='6'>
                            <Label
                              htmlFor='storeDescHtml'
                              className={`text-info font-weight-bolder text-right d-flex justify-content-between`}
                              style={{ direction: 'rtl' }}
                            >
                              <span>
                                <BiMessageSquareMinus size='20px' /> الخصم
                                للعمولة
                              </span>
                            </Label>
                            <Input
                              onBlur={handleBlur}
                              className='border-info'
                              style={{ direction: 'rtl' }}
                              invalid={errors.discount && touched.discount}
                              // placeholder="مثل elmotaheda"
                              autoComplete='off'
                              type='text'
                              name='discount'
                              onChange={e => {
                                setDiscount(
                                  Number(
                                    e.target.value.replace(/[^0-9\.]+/g, '')
                                  )
                                )
                              }}
                              value={values.discount}
                            />
                            {errors.discount && touched.discount ? (
                              <Badge
                                className='my-1 text-warning'
                                color='neutral-warning'
                              >
                                {errors.discount}
                              </Badge>
                            ) : null}
                          </Col>
                          <Col className='form-group' sm='12' md='6'>
                            <Label
                              htmlFor='storeDescHtml'
                              className={`text-info font-weight-bolder text-right d-flex justify-content-between`}
                              style={{ direction: 'rtl' }}
                            >
                              <span>
                                <BiMessageSquareAdd size='20px' /> الزيادة في
                                السعر
                              </span>
                            </Label>
                            <Input
                              onBlur={handleBlur}
                              className='border-info'
                              style={{ direction: 'rtl' }}
                              invalid={errors.overprice && touched.overprice}
                              // placeholder="مثل elmotaheda"
                              autoComplete='off'
                              type='text'
                              name='overprice'
                              onChange={e => {
                                setOverprice(
                                  Number(
                                    e.target.value.replace(/[^0-9\.]+/g, '')
                                  )
                                )
                              }}
                              value={values.overprice}
                            />
                            {errors.overprice && touched.overprice ? (
                              <Badge
                                className='my-1 text-warning'
                                color='neutral-warning'
                              >
                                {errors.overprice}
                              </Badge>
                            ) : null}{' '}
                          </Col>
                          <Col
                            className='form-group mt-md-3 pt-md-3'
                            sm='12'
                            md='6'
                          >
                            <ButtonGroup>
                              <Button
                                color='info'
                                size='sm'
                                onClick={() => setIsPercent(false)}
                                type='button'
                              >
                                {(() => {
                                  if (!isPercent) {
                                    return <IoMdDoneAll size='20px' />
                                  }
                                })()}{' '}
                                {getSuccessStores?.store?.storeCurrency}
                              </Button>
                              <Button
                                color='info'
                                size='sm'
                                active={isPercent}
                                onClick={() => setIsPercent(true)}
                                type='button'
                              >
                                {(() => {
                                  if (isPercent) {
                                    return <IoMdDoneAll size='20px' />
                                  }
                                })()}{' '}
                                نسبة مئوية %
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Button
                        color='success'
                        className='btn-pill my-1'
                        type='submit'
                      >
                        <span className='btn-wrapper--icon font-weight-bolder text-nowrap d-flex flex-row align-items-center'>
                          <IoMdDoneAll size='15px' />
                          <span className='btn-wrapper--label mr-1'>تعديل</span>
                        </span>
                      </Button>
                    </Row>
                  </form>
                )}
              </Formik>
            </>
          )
        }
      })()}
    </div>
  )
}

const UpdateStores = connect(state => state, {
  getStoresByID,
  checkDomainStore,
  updateStoresByID,
  resetStoresDomainChecker
})(UpdateStoresComponent)
export default UpdateStores
