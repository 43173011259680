import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { BoltLoader } from 'react-awesome-loaders'

import { BounceLoader } from 'react-spinners'
import { flash, bounceOutUp } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import { BsCheckAll } from 'react-icons/bs'
import { VscChromeClose } from 'react-icons/vsc'
// reactstrap components
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import { clearInterActive } from '../redux/config'

const InterActive = props => {
  const {
    auth: { interActive },
    clearInterActive
  } = props
  const { CurrentElemen, key, onElementClicked } = props
  const [currentStyleInterActive, setCurrentStyleInterActive] = useState({
    animation: 'x 1s',
    animationName: Radium.keyframes(flash, 'flash')
  })
  const myRefScroll = useRef(null)

  useEffect(() => {
    if (interActive?.type === 'success' || interActive?.type === 'failed') {
      setInterval(() => {
        myRefScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }, 500)

      setTimeout(() => {
        setCurrentStyleInterActive({
          animation: 'x 1s',
          animationName: Radium.keyframes(bounceOutUp, 'bounceOutUp')
        })
      }, 2000)

      setTimeout(() => {
        clearInterActive()
        props.onEndFN()
      }, 3000)

      setTimeout(() => {
        // Reset
        setCurrentStyleInterActive({
          animation: 'x 1s',
          animationName: Radium.keyframes(flash, 'flash')
        })
      }, 4000)
    }
  }, [interActive?.type])

  return (
    <span ref={myRefScroll} className={props.className}>
      {(() => {
        if (interActive?.type === 'success') {
          // if (true) {
          return (
            <StyleRoot className='h-100'>
              <div
                className='d-flex align-items-center justify-content-center h-100 text-success'
                style={currentStyleInterActive}
              >
                <BsCheckAll size='30' />
              </div>
            </StyleRoot>
          )
        } else if (interActive?.type === 'failed') {
          return (
            <StyleRoot className='h-100'>
              <div
                className='d-flex align-items-center justify-content-center h-100 text-danger'
                style={currentStyleInterActive}
              >
                <VscChromeClose size='30' />
              </div>
            </StyleRoot>
          )
        }
        return (
          <BounceLoader
            size={30}
            color={'var(--info)'}
            speedMultiplier='1'
            loading={true}
          />
        )
      })()}
    </span>
  )
}

export default connect(state => state, { clearInterActive })(InterActive)
