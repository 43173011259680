import React from "react";
import { connect } from "react-redux";
import { addNewStore, checkDomainStore, resetStoresDomainChecker, resetStores } from "../../redux/config";
import { ClimbingBoxLoader } from 'react-spinners';
import GetFailedComponent from "../../components/GetFailed";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { Formik } from 'formik';
import {
  Col,
  Row,
  Badge,
  Card,
  CardHeader,
  Button,
  CardBody,
  Input,
  Label,
  Container
} from "reactstrap";
import clsx from "clsx";
import * as Yup from "yup";
import { IoMdDoneAll } from "react-icons/io";
import { GiCheckedShield } from "react-icons/gi";
// import BlockUi from 'react-block-ui';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BarLoader } from 'react-spinners';
import welcome from "assets/images/welcome.svg";
import { FiRefreshCcw } from "react-icons/fi";
import { BsCheckAll } from "react-icons/bs";
import { FcBrokenLink } from "react-icons/fc";
import { MdDescription } from "react-icons/md";
import { FaStoreAlt } from "react-icons/fa";


const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

class AddStoreSectionComponent extends React.Component {
  state = {
    domainChecker: false,

    domain: "",
    domainType: "sub",
    storeColor: "info",
    storeName: "",
    storeDesc: "",
  }
  componentDidMount() {
  }
  handleChangeFormik = (ele, value) => { this.setState({ [ele]: value, }); };
  render() {
    const {
      saveLoadingStore,
      saveSuccessStore,
      saveFailedStore,

      checkDomainLoadingStore,
      checkDomainSuccessStore,
      checkDomainFailedStore,
    } = this.props;
    return (
      <React.Fragment>
        <div className="content">
          <Card className="w-100">
            <CardBody>

              {(() => {
                if (saveLoadingStore || checkDomainLoadingStore) {
                  return (
                    <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                      <p>
                        <Skeleton height={70} duration={1} />
                      </p>
                      <p>
                        <Skeleton height={70} duration={2} />
                      </p>
                      <p className="mb-0">
                        <Skeleton height={70} duration={4} />
                      </p>
                    </SkeletonTheme>
                  );
                } else {
                  return (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        domain: this.state.domain,
                        storeName: this.state.storeName,
                        storeDesc: this.state.storeDesc,
                      }}
                      validationSchema={Yup.object().shape({
                        domain: Yup.string().matches(/^[a-z]+$/, "يتم قبول الأحرف الصغيرة فقط ، تأكد من عدم وجود مسافات صغيرة أو أرقام").min(6, "الحد الادني 6 حروف").max(20, "الحد الاقصي 20 حرف").required('اسم المتجر باللغة الإنجليزية ضروري في عملية التسجيل'),
                        storeName: Yup.string().min(5, "الحد الادني 5 حروف").required('اسم المتجر اساسي في عملية التسجيل'),
                        storeDesc: Yup.string().min(5, "الحد الادني 5 حروف").required('وصف المتجر اساسي في عملية التسجيل'),
                      })}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid
                        /* and other goodies */
                      }) => (
                          <form onSubmit={handleSubmit}>
                            <Row className="no-gutters py-4">
                              <Col md="12" className="d-flex flex-column">
                                <div className="row">
                                  <Col className="form-group" sm="12" md="6">
                                    <Label htmlFor="storeNameHtml" className={`text-${this.state.storeColor} font-weight-bolder text-right`} style={{ direction: "rtl" }}><FaStoreAlt size="20px" />اسم القسم</Label>
                                    <Input
                                      invalid={errors.storeName && touched.storeName ? "true" : undefined}
                                      id="storeNameHtml"
                                      className="form-control"
                                      placeholder="مثال 'المتحدة للملابس'"
                                      type="text"
                                      name="storeName"
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={(e) => { this.handleChangeFormik(e.target.name, e.target.value) }}
                                      value={values.storeName}
                                    />
                                    {errors.storeName && touched.storeName ? (<Badge className="my-1 text-warning" color="neutral-warning">{errors.storeName}</Badge>) : null}
                                  </Col>
                                  <Col className="form-group" sm="12" md="6">
                                    <label htmlFor="storeDescHtml" className={`text-${this.state.storeColor} font-weight-bolder text-right`} style={{ direction: "rtl" }}><MdDescription size="20px" /> وصف القسم</label>
                                    <input
                                      id="storeDescHtml"
                                      invalid={errors.storeDesc && touched.storeDesc ? "true" : undefined}
                                      className="form-control mb-3 mb-md-0"
                                      placeholder="مثال 'نقوم ببيع الملابس بسعر التكلفة'"
                                      type="text"
                                      name="storeDesc"
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={(e) => { this.handleChangeFormik(e.target.name, e.target.value) }}
                                      value={values.storeDesc}
                                    />
                                    {errors.storeDesc && touched.storeDesc ? (<Badge className="my-1 text-warning" color="neutral-warning">{errors.storeDesc}</Badge>) : null}
                                  </Col>

                                </div>
                              </Col>
                              <Col className="form-group" sm="12" md="6">
                                <Button
                                  color={this.state.storeColor}
                                  className="btn-pill my-1"
                                  size="sm"
                                  disabled={!(isValid && (Object.keys(touched).length !== 0 && touched.constructor === Object))}
                                  onClick={() => {
                                    this.props.addNewStore({
                                      domain: this.state.domain,
                                      domainType: this.state.domainType,
                                      storeColor: this.state.storeColor,
                                      storeName: this.state.storeName,
                                      storeDesc: this.state.storeDesc,
                                    });
                                  }}
                                >
                                  <span className="btn-wrapper--icon font-weight-bolder">
                                    <IoMdDoneAll />
                                    <span className="btn-wrapper--label mr-1">تسجيل</span>
                                  </span>
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        )}
                    </Formik>
                  );
                }
              })()}
            </CardBody>
          </Card>

        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ stores }) => {
  return {

    saveLoadingStore: stores.saveLoadingStore,
    saveSuccessStore: stores.saveSuccessStore,
    saveFailedStore: stores.saveFailedStore,

    checkDomainLoadingStore: stores.checkDomainLoadingStore,
    checkDomainSuccessStore: stores.checkDomainSuccessStore,
    checkDomainFailedStore: stores.checkDomainFailedStore,

  };
};

const AddStoreSection = connect(mapStateToProps, { addNewStore, checkDomainStore, resetStoresDomainChecker, resetStores })(AddStoreSectionComponent);
export default AddStoreSection;
