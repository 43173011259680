import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { BoltLoader } from 'react-awesome-loaders'

import { BounceLoader } from 'react-spinners'
import { flash, bounceOutDown } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import { BsCheckAll } from 'react-icons/bs'
import { VscChromeClose } from 'react-icons/vsc'
// reactstrap components
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import { clearAlert } from '../redux/config'

const HeaderAlert = props => {
  const {
    auth: { headerAlert },
    clearAlert
  } = props
  const [currentStyle, setCurrentStyle] = useState({
    animation: 'x 1s',
    animationName: Radium.keyframes(flash, 'flash')
  })
  const myRefname = useRef(null)

  useEffect(() => {
    if (headerAlert?.msg !== undefined) {
      setTimeout(() => {
        myRefname?.current?.toggle()
      }, 600)
    }

    if (headerAlert?.type !== undefined) {
      setTimeout(() => {
        myRefname?.current?.toggle()
        setCurrentStyle({
          animation: 'x 1s',
          animationName: Radium.keyframes(bounceOutDown, 'bounceOutDown')
        })
      }, 6000)

      setTimeout(() => {
        clearAlert()
      }, 7000)

      setTimeout(() => {
        setCurrentStyle({
          animation: 'x 1s',
          animationName: Radium.keyframes(flash, 'flash')
        })
      }, 8000)
    }
  }, [headerAlert?.type])
  return (
    <>
      {(() => {
        if (headerAlert?.type !== undefined) {
          // if (true) {
          return (
            <>
              <span
                className='photo rounded-circle overflow-visible position-fixed bottom-0 left-0'
                style={{
                  marginBottom: '15px',
                  marginLeft: '30px',
                  position: 'fixed',
                  zIndex: '9999'
                }}
                width='30px'
                height='30px'
                id='type'
              >
                {(() => {
                  if (headerAlert?.type === 'success') {
                    // if (true) {
                    return (
                      <StyleRoot>
                        <div
                          className='d-flex align-items-center justify-content-center h-100 text-success'
                          style={currentStyle}
                        >
                          <BsCheckAll size='30' />
                        </div>
                      </StyleRoot>
                    )
                  } else if (headerAlert?.type === 'failed') {
                    return (
                      <StyleRoot>
                        <div
                          className='d-flex align-items-center justify-content-center h-100 text-danger'
                          style={currentStyle}
                        >
                          <VscChromeClose size='30' />
                        </div>
                      </StyleRoot>
                    )
                  }
                  return (
                    <div
                      className='d-flex align-items-center justify-content-center'
                      style={{ width: '30px', height: '30px' }}
                    >
                      <BounceLoader
                        size={30}
                        color={'var(--info)'}
                        speedMultiplier='1'
                        loading={true}
                      />
                    </div>
                  )
                })()}
              </span>
              {(() => {
                if (headerAlert?.msg !== undefined) {
                  return (
                    <UncontrolledPopover
                      ref={myRefname}
                      trigger='focus'
                      placement='right'
                      target='type'
                    >
                      <PopoverBody
                        className={`bg-white rounded ${
                          headerAlert?.type === 'failed'
                            ? 'text-danger'
                            : 'text-success'
                        }`}
                      >
                        {headerAlert?.msg}
                      </PopoverBody>
                    </UncontrolledPopover>
                  )
                }
              })()}
            </>
          )
        }
      })()}
    </>
  )
}

export default connect(state => state, { clearAlert })(HeaderAlert)
