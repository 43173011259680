import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { FiSettings, FiCheckCircle } from 'react-icons/fi'
import { CgMenuGridR } from 'react-icons/cg'
import { TiThList } from 'react-icons/ti'
import { HiUserGroup } from 'react-icons/hi'
import { GiMoneyStack } from 'react-icons/gi'
import { BiHide } from 'react-icons/bi'
import { AiOutlineLink } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import { HiDuplicate } from 'react-icons/hi'
import { FcShop, FcAdvertising } from 'react-icons/fc'
import { RiCloudLine } from 'react-icons/ri'
import { MdWbIncandescent } from 'react-icons/md'
import { BsFilePlus, BsCheckAll } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import StoreOptionsList from './StoreOptionsList'

const StoreBreadcrumb = ({ store, page }) => {
  return (
    <Breadcrumb className=' bg-transparent rtl'>
      <BreadcrumbItem tag={Link} to={`/stores`} className='px-0'>
        المتاجر
      </BreadcrumbItem>
      <BreadcrumbItem active className='px-0'>
        {store?.storeName}
      </BreadcrumbItem>
      <BreadcrumbItem className='px-0'>
        {(() => {
          if (store !== null) {
            return (
              <StoreOptionsList store={store} page={page} />
            )
          }
        })()}
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
export default StoreBreadcrumb
