import React from "react";
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Table,
  Row,
  Col,
  Label,
  Badge
} from "reactstrap";
import { URLIMG, MAIN_URL } from "../redux/api/api.conf";
import Avatar from 'react-avatar';
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/ar";

const userTyypeFN = (type) => {
  switch (type) {
    case 'owner': return 'حساب مالك';
    case 'vendor': return 'حساب تاجر';
    case 'delivrer': return 'حساب مندوب شحن';
    default: return 'حساب مسوق';
  }
}
const treeMessage = (store) => {
  return `ليك ${(store?.tsPercent === false ? store?.tsValue + " " + store?.settingsStoreCurrency : store?.tsValue + "%")} علي عمولة للمسوقين المنضمين من خلالك بحد أقصي ${store?.tsOrdersCount} طلب`
}

const systemLink = (store) => {
  return `https://${(store?.hostName !== '' && store?.hostName !== null ? store?.hostName : store?.subDomain + "." + MAIN_URL)}`
}

const Systems = (props) => {
  const { auth: { affiliator: { users } } } = props;
  return (
    <>
      <div className="content">
        <Row>
          {users?.map((user) => (
            <Col md="6" key={user._id}>
              <Card className="card-testimonial mb-5">
                <CardHeader className="card-header-avatar">
                  <a target="_blank" href={systemLink(user?.system)}>
                    {(() => {
                      if (user?.system?.settingsStoreImg !== undefined) {
                        return (
                          <img
                            alt="..."
                            className="img img-raised"
                            src={URLIMG + user?.system?.settingsStoreImg}
                          />
                        );
                      } else {
                        return (<Avatar textSizeRatio={3} size="100" name={user?.system?.settingsStoreName} round={true} />);
                      }
                    })()}


                  </a>
                </CardHeader>
                <CardBody className="pt-0">
                  <CardTitle tag="h4">{user?.system?.settingsStoreName}</CardTitle>
                  <p className="category">{user?.system?.settingsStoreDescription}</p>
                  {(() => {
                    if (user?.system?.treeSystem) {
                      return (
                        <>
                          <Button size="sm" tag="a" target="_blank" href={`${systemLink(user?.system)}/register?refId=${user?._id}`} color="primary">سوق للنظام</Button>
                          <CardText>و {treeMessage(user?.system)}</CardText>
                        </>
                      );
                    }
                  })()}
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col className="text-center">
                      <Badge color="default" className="badge-position badge-position--bottom-center badge-circle-inner">
                        أنضميت {" "}<Moment locale="ar" fromNow>{user?.createdAt}</Moment>
                      </Badge>
                    </Col>
                    <Col className="text-center">
                      {(() => {
                        if (user?.userStatus === true) {
                          return (<Badge color="success" className="badge-position badge-position--bottom-center badge-circle-inner">مفعل</Badge>);
                        } else {
                          return (<Badge color="warning" className="badge-position badge-position--bottom-center badge-circle-inner">معلق</Badge>);
                        }
                      })()}
                    </Col>
                    <Col className="text-center">
                      <Badge color="info" className="badge-position badge-position--bottom-center badge-circle-inner">{userTyypeFN(user?.userType)}</Badge>
                    </Col>
                  </Row>

                </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>

      </div>
    </>
  );
};

export default connect(state => state, {})(Systems);
