import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  addNewStore,
  checkDomainStore,
  resetStoresDomainChecker,
  resetStores
} from '../../redux/config'
import { ClimbingBoxLoader } from 'react-spinners'
import GetFailedComponent from '../../components/GetFailed'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { Formik } from 'formik'
import {
  Col,
  Row,
  Badge,
  Card,
  CardHeader,
  Button,
  CardBody,
  Input,
  Label,
  Container,
  CardImg,
  CardImgOverlay,
  FormFeedback
} from 'reactstrap'
import clsx from 'clsx'
import * as Yup from 'yup'
import { IoMdDoneAll } from 'react-icons/io'
import { GiCheckedShield } from 'react-icons/gi'
// import BlockUi from 'react-block-ui';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BarLoader } from 'react-spinners'
import welcome from 'assets/images/welcome.svg'
import { FiRefreshCcw } from 'react-icons/fi'
import { BsCheckAll } from 'react-icons/bs'
import { FcBrokenLink } from 'react-icons/fc'
import { MdDescription } from 'react-icons/md'
import { FaStoreAlt } from 'react-icons/fa'
import createStore from 'assets/images/createStore.svg'
import { BsFilePlus } from 'react-icons/bs'
import { FcCurrencyExchange } from 'react-icons/fc'
import Select from 'react-select'
import { Country, State, City } from 'country-state-city'
import logo from 'assets/images/ator.gif'
import { Img } from 'react-image'

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: 'transparent',
    borderColor: '#1D8CF8'
  }),
  singleValue: (base, state) => ({
    ...base,
    color: '#1D8CF8',
    background: 'transparent'
  })
}

const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

let Schema = Yup.object().shape({
  domain: Yup.string()
    .matches(
      /^[a-z]+$/,
      'يتم قبول الأحرف الصغيرة فقط ، تأكد من عدم وجود مسافات صغيرة أو أرقام'
    )
    .min(6, 'الحد الادني 6 حروف')
    .max(20, 'الحد الاقصي 20 حرف')
    .required('اسم المتجر باللغة الإنجليزية ضروري في عملية التسجيل'),

  storeName: Yup.string()
    .min(4, 'الحد الادني 4 حروف')
    .required('اسم المتجر اساسي في عملية التسجيل'),
  storeDesc: Yup.string()
    .min(4, 'الحد الادني 4 حروف')
    .required('وصف المتجر اساسي في عملية التسجيل'),

})

let domainSchema = Yup.object().shape({
  domain: Yup.string()
    .matches(
      /^[a-z]+$/,
      'يتم قبول الأحرف الصغيرة فقط ، تأكد من عدم وجود مسافات صغيرة أو أرقام'
    )
    .min(6, 'الحد الادني 6 حروف')
    .max(20, 'الحد الاقصي 20 حرف')
    .required('اسم المتجر باللغة الإنجليزية ضروري في عملية التسجيل')
})
const currencyOption = Country.getAllCountries()
  .filter(e => e.currency !== '')
  .map(c => {
    return { label: c.name + ', ' + c.currency, value: c.currency }
  })

const AddStoresComponent = props => {
  const [domain, setDomain] = useState('')
  const [storeName, setStoreName] = useState('')
  const [storeDesc, setStoreDesc] = useState('')

  useEffect(() => {
    resetStoresDomainChecker()
    ;(async function () {
      const delayDebounceFn = setTimeout(async () => {
        let data = await domainSchema.validate({ domain })
        if (data?.domain) {
          checkDomainStore({ domain, domainType: 'sub' })
        }
      }, 800)
      return () => clearTimeout(delayDebounceFn)
    })()
  }, [domain])

  const {
    stores: {
      saveLoadingStore,
      saveSuccessStore,
      saveFailedStore,

      checkDomainLoadingStore,
      checkDomainSuccessStore,
      checkDomainFailedStore
    },
    addNewStore,
    checkDomainStore,
    resetStoresDomainChecker,
    resetStores,

    history
  } = props

  // saveLoadingStore: stores.saveLoadingStore,
  // saveSuccessStore: stores.saveSuccessStore,
  // saveFailedStore: stores.saveFailedStore,

  // checkDomainLoadingStore: stores.checkDomainLoadingStore,
  // checkDomainSuccessStore: stores.checkDomainSuccessStore,
  // checkDomainFailedStore: stores.checkDomainFailedStore,
  return (
    <React.Fragment>
      <div className='content'>
        <Card className='mb-0 w-100'>
          <CardHeader className='p-0'>
            <Card className='bg-dark text-white'>
              <Img
                className='img-banner-custom'
                style={{ maxHeight: '300px', objectFit: 'cover' }}
                alt={`...`}
                src={[createStore, logo]}
                container={children => {
                  return children
                }}
                loader={
                  <SkeletonTheme
                    width='100%'
                    height='100%'
                    color='#344675'
                    highlightColor='#fff'
                  >
                    <Skeleton duration={0.5} width='100%' height={256} />
                  </SkeletonTheme>
                }
              />

              <CardImgOverlay>
                <div className='w-100 h-100 position-relative'>
                  <h2 className=' text-default'>
                    <BsFilePlus size='30px' /> أصنع متجرك مجاناً الأن
                  </h2>
                </div>
              </CardImgOverlay>
            </Card>
          </CardHeader>
          <div className='divider' />

          <CardBody>
            {(() => {
              if (saveLoadingStore) {
                return (
                  <SkeletonTheme color='#f0f0f0' highlightColor='#fff'>
                    <p>
                      <Skeleton height={70} duration={1} />
                    </p>
                    <p>
                      <Skeleton height={70} duration={2} />
                    </p>
                    <p className='mb-0'>
                      <Skeleton height={70} duration={4} />
                    </p>
                  </SkeletonTheme>
                )
              } else {
                return (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      domain: domain,
                      storeName: storeName,
                      storeDesc: storeDesc,
                    }}
                    validationSchema={Schema}
                    onSubmit={() => {
                      resetStoresDomainChecker()
                      addNewStore({
                        domain: domain,
                        domainType: 'sub',
                        storeName: storeName,
                        storeDesc: storeDesc,
                      })
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {/* <BlockUi className="block-loading-overlay-dark rounded p-2" tag="div" blocking={saveLoadingStore} loader={<ClimbingBoxLoader color={'var(--white)'} loading={saveLoadingStore} />}> */}
                        {(() => {
                          if (saveFailedStore !== null) {
                            return (
                              <Container className='text-warning'>
                                <GetFailedComponent failed={saveFailedStore} />
                              </Container>
                            )
                          }
                          if (saveSuccessStore !== null) {
                            openInNewTab(saveSuccessStore?.url)
                            resetStores()
                            history.push('/stores')
                            return (
                              <Card className='card-box-alt card-border-top border-success mb-0 p-4 d-flex'>
                                <h3 className='font-size-lg font-weight-bold px-3 px-xl-4 m-0 text-success'>
                                  <BsCheckAll /> تم أضافة المتجر بنجاح
                                </h3>
                              </Card>
                            )
                          } else {
                            return (
                              <Row className='no-gutters py-4'>
                                <Col className='form-group' sm='12'>
                                  <Label
                                    style={{ fontSize: '1.5em' }}
                                    className={`text-info font-weight-bolder text-right`}
                                    style={{ direction: 'rtl' }}
                                  >
                                    <FcBrokenLink size='20px' /> أكتب اسم فريد
                                    للمتجر{' '}
                                    <b className='text-warning'>
                                      باللغة الإنجليزية
                                    </b>{' '}
                                  </Label>
                                  <Input
                                    onBlur={handleBlur}
                                    className='border-info mb-1'
                                    style={{ direction: 'rtl' }}
                                    valid={
                                      checkDomainSuccessStore !== null &&
                                      !(errors.domain && touched.domain)
                                    }
                                    invalid={
                                      (errors.domain && touched.domain) ||
                                      checkDomainFailedStore !== null
                                    }
                                    placeholder='مثل elmotaheda'
                                    autoComplete='off'
                                    type='text'
                                    name='domain'
                                    onChange={e => {
                                      setDomain(
                                        String(e.target.value)
                                          .replace(/\s+/g, '')
                                          .replace(/[^A-Za-z\s]/g, '')
                                          .toLowerCase()
                                      )
                                    }}
                                    value={values.domain}
                                  />
                                  {(() => {
                                    if (checkDomainLoadingStore) {
                                      return (
                                        <Label className='text-white-50 small'>
                                          جاري التحقق ...
                                        </Label>
                                      )
                                    }
                                  })()}
                                  {(() => {
                                    if (checkDomainSuccessStore !== null) {
                                      return (
                                        <FormFeedback valid>
                                          {checkDomainSuccessStore?.success}
                                        </FormFeedback>
                                      )
                                    }
                                  })()}
                                  {errors.domain && touched.domain ? (
                                    <Badge
                                      className='text-warning'
                                      color='neutral-warning'
                                    >
                                      {errors.domain}
                                    </Badge>
                                  ) : null}
                                  {checkDomainFailedStore !== null ? (
                                    <Badge
                                      className='text-warning'
                                      color='neutral-warning'
                                    >
                                      {checkDomainFailedStore}
                                    </Badge>
                                  ) : null}{' '}
                                </Col>
                                <Col className='form-group' sm='12' md='6'>
                                  <Label
                                    htmlFor='storeNameHtml'
                                    className={`text-info font-weight-bolder text-right`}
                                    style={{ direction: 'rtl' }}
                                  >
                                    <FaStoreAlt size='20px' /> اسم المتجر باللغة
                                    العربية
                                  </Label>
                                  <Input
                                    invalid={
                                      errors.storeName && touched.storeName
                                    }
                                    valid={
                                      !(
                                        errors.storeName && touched.storeName
                                      ) && values.storeName !== ''
                                    }
                                    id='storeNameHtml'
                                    className='border-info mb-1'
                                    placeholder="مثال 'المتحدة للملابس'"
                                    type='text'
                                    name='storeName'
                                    autoComplete='off'
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setStoreName(e.target.value)
                                    }}
                                    value={values.storeName}
                                  />
                                  {errors.storeName && touched.storeName ? (
                                    <Badge
                                      className='text-warning'
                                      color='neutral-warning'
                                    >
                                      {errors.storeName}
                                    </Badge>
                                  ) : null}
                                </Col>
                                <Col className='form-group' sm='12' md='6'>
                                  <Label
                                    htmlFor='storeDescHtml'
                                    className={`text-info font-weight-bolder text-right`}
                                    style={{ direction: 'rtl' }}
                                  >
                                    <MdDescription size='20px' /> وصف المتجر
                                    باللغة العربية
                                  </Label>
                                  <Input
                                    id='storeDescHtml'
                                    invalid={
                                      errors.storeDesc && touched.storeDesc
                                    }
                                    valid={
                                      !(
                                        errors.storeDesc && touched.storeDesc
                                      ) && values.storeDesc !== ''
                                    }
                                    className='border-info mb-1'
                                    placeholder="مثال 'نقوم ببيع الملابس بسعر التكلفة'"
                                    type='text'
                                    name='storeDesc'
                                    autoComplete='off'
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setStoreDesc(e.target.value)
                                    }}
                                    value={values.storeDesc}
                                  />
                                  {errors.storeDesc && touched.storeDesc ? (
                                    <Badge
                                      className='text-warning'
                                      color='neutral-warning'
                                    >
                                      {errors.storeDesc}
                                    </Badge>
                                  ) : null}
                                </Col>

                                <Col className='form-group' sm='12'>
                                  <Button
                                    color='info'
                                    className='btn-pill my-1'
                                    size='sm'
                                    type='submit'
                                    disabled={
                                      !(
                                        isValid &&
                                        Object.keys(touched).length !== 0 &&
                                        touched.constructor === Object
                                      )
                                    }
                                  >
                                    <span className='btn-wrapper--icon font-weight-bolder d-flex flex-row align-items-center'>
                                      <IoMdDoneAll />
                                      <span className='btn-wrapper--label mr-1'>
                                        تسجيل
                                      </span>
                                    </span>
                                  </Button>
                                </Col>
                              </Row>
                            )
                          }
                        })()}
                      </form>
                    )}
                  </Formik>
                )
              }
            })()}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

const AddStores = connect(state => state, {
  addNewStore,
  checkDomainStore,
  resetStoresDomainChecker,
  resetStores
})(AddStoresComponent)
export default AddStores
